import moment from "moment";
import axios from "axios";

const preLoadImages = (imgInfos, dom) => {
    let homeImages = new Map();

    imgInfos.forEach((pic) => {
        const img = new Image();
        img.src = pic.url;
        img.onload = () => {
            homeImages.set(pic.name, img);
            let loadedNum = dom.state.loadedNum + 1;
            dom.setState({
                loadedNum: loadedNum,
            })
        }
    });
    dom.setState({
        homeImages: homeImages,
    })
}

const sendItemRequest = (page, data, url) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", HeaderUrl() + url, true);
    xhr.withCredentials = false;
    xhr.send(data);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            let result = JSON.parse(xhr.responseText)
            let dataMap = parseItems(result);
            page.setState({
                itemData: dataMap,
            })
        }
    }
}

const sendNewsRequest = (page, url) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.withCredentials = false;
    xhr.send();
    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            let result = JSON.parse(xhr.responseText)
            let newsList = parseNews(result);

            console.log(result)
            page.setState({
                newsData: newsList,
            })
        }
    }
}

const parseNews = (results) => {
    let resultNewsList = [];
    if (results && results.length > 0) {
        results.forEach(function (attr) {
            let articles = {
                newsDate: getTagValue(attr, "editDate"),
                newsTitle: getTagValue(attr, "title"),
                newsLabel: getTagValue(attr, "rank"),
                newsImage: getTagValue(attr, "coverFrontName"),
                newsDes: getTagValue(attr, "partDes"),
                newsFile: getTagValue(attr, "fileName"),
                newsClickTarget: getTagValue(attr, "clickTarget"),
            }
            resultNewsList.push(articles);
        });
    }
    return resultNewsList;
}

const parseItems = (results) => {
    let resultMap = new Map();
    let attrs = ["name", "coverFrontName", "coverBackName", "title", "content"]
    if (results.length === undefined) {
        attrs.forEach(function (attr) {
            resultMap.set(attr, getTagValue(results, attr));
        })
        return resultMap;
    }
    results.forEach(function (tag) {
        let tagMap = new Map();
        attrs.forEach(function (attr) {
            tagMap.set(attr, getTagValue(tag, attr));
        })
        resultMap.set(tagMap.get("name"), tagMap);
    })
    return resultMap;
}

const sendContactRequest = (page, data, url) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.withCredentials = false;
    xhr.send(JSON.stringify(data));
}

const sendVisitorRecord = (inTime, page, isMobile) => {
    let stayTime = isMobile ? 1 : parseInt(((Date.now() - inTime) / 1000) + "");
    let data = new FormData();
    data.append('L0CATION_PAGE', page);
    data.append('stay-time', stayTime);
    data.append('is-mobile', isMobile);
    data.append('date', moment().format("YYYY-MM-DD"));

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/doVisitorRecord", true);
    xhr.withCredentials = false;
    xhr.send(data);
}

// const HeaderUrl = () => {
//     return "http://localhost:8080";
// }
//
const BatchItemsUrl = () => {
    return "/getBatchItems";
}

const ContactUrl = () => {
    return "/contactInfo";
}

const newsUrl = () => {
    return "/getNewsInfo";
}

const HeaderUrl = () => {
    return "http://localhost:8080";
}

// const BatchItemsUrl = () => {
//     return "/api/getBatchItems";
// }
//
// const ContactUrl = () => {
//     return "/api/contactInfo";
// }
//
// const newsUrl = () => {
//     return "/api/getNewsInfo";
// }

const getTagValue = (data, itemTag) => {
    return data.hasOwnProperty(itemTag) ? data[itemTag] : "";
}

async function sendPostRequest(pojo) {
    axios.defaults.withCredentials = false;
    return await axios.post(pojo.url, JSON.stringify(pojo.data)).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        // return connectionError;
    })
}

async function sendGetRequest(url, params) {
    axios.defaults.withCredentials = false;
    return await axios.get(
        url,
        // HeaderUrl() + url,
        {
        params: params
    }).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        // return connectionError;
    })
}

export {
    sendItemRequest,
    getTagValue, BatchItemsUrl, ContactUrl, sendContactRequest, sendNewsRequest, newsUrl, sendVisitorRecord, preLoadImages, sendPostRequest, sendGetRequest}