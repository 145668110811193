import "../index.css";

const RecInfoCard = (props) => {

    const { info, switchInfo } = props;

    const title = info.advTitle;

    return (
        <>
            <div className={"rec-info-card"}>
                <li onClick={() => {switchInfo(info)}}>
                    {title}
                </li>
            </div>
        </>
    )
}

export default RecInfoCard;