import React from "react";
import "./index.css";
import {findZoom} from "../../util/WindowHelper";
import {isMobile} from "../../util/MobileMgr";
import {Modal, Spin} from "antd";
import PageHeader from "../../PageHeader";
import {imgHeader} from "../info/constants";

class InfoD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needTopBG: true,
            needSMenu: this.setInitMenu(),
            zoomSize: findZoom(1480),
            isMobile: isMobile(),
            isLoaded: false,
            info: {},
            isModalOpen: false
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    componentDidMount() {
        let params = window.location.href.split("?")[1];
        if (params) {
            let infoStr = params.replace("info=", "");
            let info = JSON.parse(decodeURIComponent(infoStr));
            // console.log(info);
            this.setState({
                isLoaded: true,
                info: info,
            })
        }

    }

    handlePhone = (phone) => {
        if (typeof phone === "string") {
            return phone.slice(0, 8) + '****';
        }
    }

    showModal = () => {
        this.setState({
            isModalOpen: true
        })
    };

    handleOk = () => {
        this.setState({
            isModalOpen: false
        })
    };

    render() {
        let needTopBG = this.state.needTopBG;
        let needSMenu = this.state.needSMenu;
        let isMobileL = this.state.isMobile;
        let zoomL = this.state.zoomSize;

        if (!isMobileL) {
            let isLoaded = this.state.isLoaded;
            let info = this.state.info;
            let isModalOpen = this.state.isModalOpen;
            return (
                <>
                    <div style={{
                        textAlign: "center",
                        background: "rgb(224,224,224)",
                        height: "100vh",
                        position: "fixed",
                        width: "100%",
                        display: isLoaded ? "none" : ""
                    }}>
                        <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                    </div>
                    <div className={"info-d-page"}>
                        <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                        <div className={"info-d-wrap"}
                             style={{zoom: zoomL, display: isLoaded ? "" : "none"}}
                        >
                            <Modal style={{zIndex: 99}} title="联系方式" visible={isModalOpen} closable={false}
                                   cancelText={"关闭"}  okText={"确定"}
                                   onCancel={() => this.handleOk()} onOk={() => this.handleOk()}>
                                <p style={{fontSize: "16px", color: "red"}}>
                                    <strong>
                                        {info.advPersonPhone}
                                    </strong>
                                </p>
                                <p>{"联系时请说是在上海巨罟大数据科技有限公司看到的, 谢谢"}</p>
                            </Modal>
                            <div className={"info-d-left"}>
                                <div className={"info-d-title"}>
                                    {info.advTitle}
                                </div>
                                <div className={"info-d-top"}>
                                    <div className={"info-d-top-left"}>
                                        <img className={"info-d-cover"} src={imgHeader + info.advCover} alt={""}/>
                                    </div>
                                    <div className={"info-d-top-right"}>
                                        <div className={"info-d-contact"}>
                                            <div className={"info-d-contact-left"}>{"联系人："}</div>
                                            <div className={"info-d-contact-right"}>{info.advPerson}</div>
                                        </div>
                                        <div className={"info-d-contact"}>
                                            <div className={"info-d-contact-left"}>
                                                {"电话："}
                                            </div>
                                            <div className={"info-d-contact-right"} style={{color: "red"}}>
                                                <strong>
                                                    {this.handlePhone(info.advPersonPhone)}
                                                </strong>
                                            </div>
                                            <div className={"tel-btn"} onClick={() => {
                                                this.showModal()
                                            }}>
                                                {"点击查看完整号码"}
                                            </div>
                                        </div>
                                        <div className={"info-d-tip"}>
                                            <strong>
                                                {"上海巨罟大数据科技有限公司提醒您："}
                                            </strong>
                                            <span>
                                                {"让你提前汇款，或者价格明显低于市价，均有骗子嫌疑，不要轻易相信。"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"info-d-center"}>
                                    <div className={"info-d-center-title"}>
                                        {"信息详情"}
                                    </div>
                                    <div className={"info-d-center-text"}>
                                        {info.advCt}
                                    </div>
                                    <div className={"info-d-center-text"}>
                                        {"联系我时，请说是在上海巨罟大数据科技有限公司看到的，谢谢！"}
                                    </div>
                                    <div style={{margin: "20px"}}>
                                        <img className={"info-d-center-cover"} src={imgHeader + info.advCover} alt={""}/>
                                    </div>
                                </div>
                                <div className={"info-d-more"}>
                                    <a href={"/services"}>
                                        {"查看更多"}
                                    </a>
                                </div>
                            </div>
                            <div className={"info-d-right"}>
                                <div className={"info-d-user"}>
                                    <img className={"user-img"} src={imgHeader + "user.jpg"} alt={""}/>
                                    <div className={"user-name"}>
                                        {info.advPerson}
                                    </div>
                                    <div className={"user-level"}>
                                        <div className={"user-level-left"}>
                                            {"用户级别："}
                                        </div>
                                        <div className={"user-level-right"}>
                                            {info.userLevel}
                                        </div>
                                    </div>
                                    <div className={"user-level"}>
                                        <div className={"user-level-left"}>
                                            {"信用等级："}
                                        </div>
                                        <div className={"user-level-right"}>
                                            <img src={imgHeader + "credit.gif"} alt={''}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"info-d-type"}>
                                    <div className={"info-d-type-title"}>
                                        <span>{"相关分类"}</span>
                                    </div>
                                    <div className={"info-d-type-txt"}>
                                        <li>{info.advTypTxt}</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default InfoD;