/**
 * 页面向下滚动后，菜单透明化监控
 *
 * @param page 当前页面
 */
const handleMenuScroll = (page) => {
        const {needTopBG} = page.state;
        let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
        if (scrollTop > 0) {
            needTopBG && page.setState({needTopBG: false});
        } else {
            !needTopBG && page.setState({needTopBG: true})
        }
}

/**
 * 窗口过小时，改变菜单式样
 *
 * @param page 当前页面
 * @param e 监控器对象
 */
const handleNarrowMenu = (page, e) => {
    const {needSMenu} = page.state;
    let curWidth = e.target.innerWidth;
    if (curWidth < 1270) {
        !needSMenu && page.setState({needSMenu: true});
    } else {
        needSMenu && page.setState({needSMenu: false});
    }
}

const findZoom = (minSize) => {
    let width = document.documentElement.clientWidth;
    let zoomSwitch = 1;
    if (width < minSize) {
        zoomSwitch = width / minSize;
    }
    return zoomSwitch;
}

export {handleMenuScroll, handleNarrowMenu, findZoom}