import React from 'react';
import '../contact.css'
import Job from "../../picture/JobSign.svg";

class ContactJoinUs extends React.Component {
    render() {
        let isMobileL = this.props.isMobileL;
        let needFixedBottom = this.props.needFixedBottom;
        return (
            <div
                className={isMobileL ? "mobile-join-us" : (needFixedBottom ? "bottom-page-left-fix" : "bottom-page-left")}>
                <div className={isMobileL ? "mobile-join-us-title" : "join-us-title"}>
                    <div className={isMobileL ? "mobile-join-us-cn" : "join-us-cn"}>寻找深藏不露的你</div>
                    <div className={isMobileL ? "mobile-join-us-en" : "join-us-en"}>WE NEED YOU</div>
                </div>
                <div className={isMobileL ? "mobile-join-us-info" : "join-us-info"}>
                    <div className={isMobileL ? "mobile-join-us-job" : "join-us-job"}>
                        <img className={isMobileL ? "mobile-join-us-job-img" : "join-us-job-img"} src={Job} alt={""}/>
                        <div className={isMobileL ? "mobile-join-us-job-title" : "join-us-job-title"}>UI设计师</div>
                        <br/>
                        <div style={isMobileL ? {height: "30px"} : {height: "1vw"}}/>
                        <div className={isMobileL ? "mobile-join-us-job-content" : "join-us-job-content"}>1.
                            对UI设计相关工具有较深的理解和运用水平，可独立完成软件产品前端部分的设计与开发；
                        </div>
                        <div className={isMobileL ? "mobile-join-us-job-content" : "join-us-job-content"}>2.
                            两年以上的相关从业经历；
                        </div>
                    </div>
                    <div className={isMobileL ? "mobile-join-us-job" : "join-us-job"}>
                        <img className={isMobileL ? "mobile-join-us-job-img" : "join-us-job-img"} src={Job} alt={""}/>
                        <div className={isMobileL ? "mobile-join-us-job-title" : "join-us-job-title"}>软件开发工程师</div>
                        <br/>
                        <div style={isMobileL ? {height: "30px"} : {height: "1vw"}}/>
                        <div className={isMobileL ? "mobile-join-us-job-content" : "join-us-job-content"}>1.
                            对Java，Maven工程有着较深的理解与运用水准；
                        </div>
                        <div className={isMobileL ? "mobile-join-us-job-content" : "join-us-job-content"}>2.
                            具备连贯与缜密的思维逻辑；
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactJoinUs;