import React from 'react';
import './static/page.less'

class MobileBKContent extends React.Component {
    render() {
        return (
            <div className={"mobile-bk-content"}>
                {/*<div className="mobile-one"/>*/}
                <div className={"mobile-bk-intro"}>
                    <div className={"mobile-intro-title"}>公司简介</div>
                    <div className={"mobile-intro-content"}>
                        <p>
                            巨罟大数据科技有限公司成立于2021年。专注于网站建设，App开发，数据分析，新媒体运营等网络数字化服务。为您提供个性化定制开发项目，打通前后端壁垒，结合数字化运营，提供一站式服务。我们号召与集结各领域先驱人才，志在打造尖刀产品，为您赢得上甘岭！
                        </p>
                    </div>
                    <div className={"mobile-sub-title"}>- 企业文化 -</div>
                    <div className={"mobile-sub-content"}>
                        <p>
                            通过树立正确的核心价值观，引领公司企业文化建设，积极塑造企业正能量。企业宗旨：“坚持以人为本，诚信务实，团结奋进；开拓创新，致力卓越，勇于挑战；坚持梦想，脚踏实地，精益求精。”我们力争成为怀揣梦想的实干家！
                        </p>
                    </div>
                    <div className={"mobile-sub-title"}>- 目标及理念 -</div>
                    <div className={"mobile-sub-content"}>
                        <p>
                            以用户为根本，以技术为核心。携手并进，创造共赢。用户需求是发展原动力，技术创新是第一生产力。坚持以高效的交付速度、细颗粒度的质量把控，回馈以客户最优的服务品质。提升客户价值，共探行业发展。不做潮流的盲目跟风者，立志于成为行业的领航员。
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileBKContent;