import React, {useEffect, useState} from "react";
import {unstable_batchedUpdates} from "react-dom";
import Test2 from "./Test2";
import Test3 from "./Test3";

const Test = () => {

    const [a, setA] = useState(true)
    const [num, setNum] = useState(666)

    const click = () => {
        console.log("ccc")
        setA(!a)
    }

    const hNum = (num) => {
        setNum(num)
    }

    // const pr = () => {
    //     console.log(a)
    //     return <button onClick={() => {
    //         click()
    //     }}>{"CC"}</button>
    // }
    //
    // const ct = () => {
    //     return (
    //         <div>
    //             {pr()}
    //             <img src={"https://putaotao.jugu-mega.com/resource/pic/good/logo.png?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/good/1665289907962/des/des_1.jpeg?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/pic/good/logo.png?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/good/1665289907962/des/des_2.jpeg?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/pic/good/logo.png?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/good/1665289907962/des/des_3.jpeg?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/pic/good/logo.png?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //             <img src={"https://putaotao.jugu-mega.com/resource/good/1665289907962/des/des_4.jpeg?v=1.0"}
    //                  style={a ? {display: "none"} : {}}/>
    //         </div>
    //     )
    // }
    // const load = () => {
    //     console.log("ldd")
    //     let d = [];
    //     for (let i = 0; i < 99999; i++) {
    //         d.push(
    //                         <img src={"https://putaotao.jugu-mega.com/resource/good/1665289907962/des/des_4.jpeg?v=" + Math.random() + ""}/>
    //             )
    //     }
    //     return (<div>{d}</div>)
    // }
    const scrollC = () => {
        let sc = document.documentElement.scrollTop;
        let touch = sc === 100;
        console.log(sc)
        console.log(touch)
    }


    useEffect(() => {
        window.addEventListener('scroll', scrollC, true)
    }, [])

    return (
        <div style={{height: "20000px"}}>
            {/*<Test2 hname={hNum}/>*/}
            {/*<Test3 num={num}/>*/}
            {/*{load()}*/}
        </div>

    )
}

export default Test