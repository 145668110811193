// 引入库
import React from 'react';
// PropTypes进行类型检查，提供一系列验证器，可用于确保组件接收到的数据类型是有效的
import PropTypes from 'prop-types';
// 滚动动画
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
// ant design v4 icon
import { DownOutlined } from '@ant-design/icons';
// 进出场动画
import QueueAnim from 'rc-queue-anim';

import BannerBK from '../background/picture/BackgroundBanner.jpg';
import {preLoadImages} from "../../util/RequestHelper";

// import './static/style2'

// content line button出现位置设置
function typeFunc(a) {
    if (a.key === 'line') {
        return 'right';
    } else if (a.key === 'button') {
        return 'bottom';
    }
    return 'left';
}

// export default function Banner({ onEnterChange }) {
class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homeImages: "",
            loadedNum: 0,
        }
    }

    imgInfos = [
        {name: "BannerBK", url: BannerBK},
    ]

    componentDidMount() {
        if (!this.props.isMobileL) {
            preLoadImages(this.imgInfos, this);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isMobileL && prevState.loadedNum !== this.state.loadedNum && this.state.loadedNum === 1) {
            this.props.parent.setLoad();
        }
    }

    render() {
        let isMobileL = this.props.isMobileL;

        if (!isMobileL && this.props.parent.state.isLoaded) {
            return (
                <div className="banner">
                    <section className="page banner-wrapper" style={{backgroundImage: `url("${this.state.homeImages.get("BannerBK").src}")`}}>
                        <ScrollElement
                            className="page"
                            id="banner"
                            // onChange={({ mode }) => onEnterChange(mode)}
                            // playScale={0.9}
                        >
                            <QueueAnim className="banner-text-wrapper" type={typeFunc} delay={500} key="banner">
                                <h2 key="h2">
                                    巨罟
                                    {/*<p>大数据科技</p>*/}
                                    {/*// toDo ICP*/}
                                    <p>信息发布平台</p>
                                    {/**/}
                                </h2>
                                <p key="content">数字世界，驱动未来</p>
                                <span className="line" key="line"/>
                                <div className="start-button clearfix" key="button">
                                    <a href="/services">
                                        {/*关于服务*/}
                                        {/*// toDo ICP*/}
                                        发布信息
                                        {/**/}
                                    </a>
                                    <a href="/services">
                                        {/*联系我们*/}
                                        {/*// toDo ICP*/}
                                        查询信息
                                        {/**/}
                                    </a>
                                </div>
                            </QueueAnim>
                            <DownOutlined className="down" type="down"/>
                        </ScrollElement>
                    </section>
                </div>
            );
        }else {
            return (
                <div className="mobile-banner">
                    <div className="page banner-wrapper">
                        {/*<ScrollElement*/}
                        {/*    className="page"*/}
                        {/*    id="banner"*/}
                        {/*>*/}
                            <div className={"mobile-cover"}/>
                            <div className="banner-text-wrapper" type={typeFunc} delay={500} key="banner">
                                <h2 key="h2">巨罟 <p>大数据科技</p></h2>
                                <p key="content">数字世界，驱动未来</p>
                            </div>
                        {/*</ScrollElement>*/}
                    </div>
                </div>
            );
        }
    }
}
Banner.propTypes = {
    onEnterChange: PropTypes.func,
};

export default Banner;
