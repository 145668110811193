import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import './static/page.less'

class Target extends React.Component {
    render() {
        let isMobileL = this.props.isMobileL;

        if (!isMobileL) {
            return (
                <div className="four">
                    {/*<div className="background-fixed-two"/>*/}
                    <div className="Target">
                        <ScrollOverPack playScale={0.2} id="" className="content-wrapper page">
                            <TweenOne
                                key="image"
                                className="image3 image-wrapper"
                                animation={{x: 0, opacity: 1, ease: 'easeOutQuad'}}
                            />
                            <QueueAnim
                                className="text-wrapper"
                                key="text"
                                duration={450}
                                leaveReverse
                            >
                                <h2 key="h2" style={{color: "#4e4e4e"}}>理念及目标</h2>
                                <span className="line" key="text-line"/>
                                <p key="text-p">
                                    <p>
                                        以用户为根本，以技术为核心。携手并进，创造共赢。
                                    </p>
                                    <p>
                                        用户需求是发展原动力，技术创新是第一生产力。
                                    </p>
                                    <p>
                                        坚持以高效的交付速度、细颗粒度的质量把控，回馈以客户最优的服务品质。
                                    </p>
                                    <p>
                                        提升客户价值，共探行业发展。
                                    </p>
                                    <p>
                                        不做潮流的盲目跟风者，立志于成为行业的领航员。
                                    </p>
                                </p>
                            </QueueAnim>
                        </ScrollOverPack>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mobile-four">
                    <div className="mobile-target">
                        <div className="content-wrapper page">
                            <div key="text" className="text-wrapper">
                                <h2 key="h2">理念及目标</h2>
                                <span className="line" key="line"/>
                                <p key="p">
                                    以用户为根本，以技术为核心
                                </p>
                                <p>
                                    用户需求是发展原动力，技术创新是第一生产力
                                </p>
                                <p>
                                    提升客户价值，共探行业发展
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Target;