import React from 'react'
import './service.css'
import "./serviceitem.less"
import PageHeader from "../../PageHeader";
import ServiceCore from "./ServiceCore";
import ServiceItem from "./ServiceItem";
import ServiceBanner from "./ServiceBanner";
import FooterLocal from "../../FooterLocal";
import {findZoom, handleMenuScroll, handleNarrowMenu} from "../../util/WindowHelper";
import {isMobile} from "../../util/MobileMgr"
import DocumentTitle from "react-document-title";
import ContactCard from "../ContactCard";
import {sendVisitorRecord} from "../../util/RequestHelper";
import configName from "../../locales/zh-CN";
import QQContact from "../../util/QQContact";
import {Spin} from "antd";

const serviceBegin = 600 + 1140;

class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needTopBG: window.location.hash === "",
            needSMenu: this.setInitMenu(),
            zoomSize: findZoom(1480),
            showWeb: isMobile() ? this.handleMTagView("service-web-info") : this.handlePCTagView("web"),
            showApp: isMobile() ? this.handleMTagView("service-app-info") : this.handlePCTagView("app"),
            showWechat: isMobile() ? this.handleMTagView("service-wechat-info") : this.handlePCTagView("wechat"),
            showML: isMobile() ? this.handleMTagView("service-machine-info") : this.handlePCTagView("ml"),
            showServiceMenu: this.needServiceMenu(),
            inTime: 0,
            isMobile: isMobile(),
            isLoaded: false,
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    needServiceMenu = () => {
        let curId = this.findReceivedId();
        return curId && curId.startsWith("service")
    }

    findReceivedId() {
        let url = window.location.href;
        let curId;
        if (url.includes("#")) {
            curId = url.split("#")[1];
        }
        return curId;
    }

    handleMTagView(tagName) {
        let id = this.findReceivedId();
        return id && id === tagName;
    }

    handlePCTagView(tagName) {
        let curPos = document.documentElement.scrollTop;
        console.log("1231231231")
        let windowHeight = document.documentElement.clientHeight;
        let zoomL = findZoom(1480);
        let core = 1300;
        let web = 937;
        let app = 2000;
        let wechat = 780;
        let appDis = (windowHeight + core + web) * zoomL - 80;
        let webDis = (windowHeight + core) * zoomL - 80;
        let wechatDis = (windowHeight + core + web + app) * zoomL - 80;
        let mlDis = (windowHeight + core + web + app + wechat) * zoomL - 80;
        switch (tagName) {
            case "app": {
                return curPos >= appDis;
            }
            case "web": {
                return curPos >= webDis;
            }
            case "wechat": {
                return curPos >= wechatDis;
            }
            case "ml": {
                return curPos >= mlDis;
            }
            default: {
                return false;
            }
        }
    }

    defineMobileMenu() {
        let curPos = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight;
        let topMenu = windowHeight * 0.08;
        let restPart = topMenu + serviceBegin
        let menuBar = 120;
        if (curPos >= restPart - menuBar) {
            this.setState({
                showServiceMenu: true,
            })
        } else {
            this.setState({
                showServiceMenu: false,
            })
            return;
        }
        let webHeight = 1350;
        if (curPos <= restPart + webHeight - menuBar) {
            this.setState({
                showWeb: true,
                showApp: false,
                showWechat: false,
                showML: false,
            })
            return;
        }
        let appHeight = 3250;
        if (curPos <= restPart + webHeight + appHeight - menuBar) {
            this.setState({
                showWeb: false,
                showApp: true,
                showWechat: false,
                showML: false,
            })
            return;
        }
        let wechatHeight = 1300;
        if (curPos <= restPart + webHeight + appHeight + wechatHeight - menuBar) {
            this.setState({
                showWeb: false,
                showApp: false,
                showWechat: true,
                showML: false,
            })
            return;
        }
        let mlHeight = 2000;
        if (curPos <= restPart + webHeight + appHeight + wechatHeight + mlHeight) {
            this.setState({
                showWeb: false,
                showApp: false,
                showWechat: false,
                showML: true,
            })
        }
    }

    checkTagView() {
        console.log("44444444444")
        let curPos = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight;
        let zoomL = this.state.zoomSize;
        let core = 1300;
        let web = 937;
        let app = 2000;
        let wechat = 780;

        let appDis = (windowHeight + core + web) * zoomL - 80;
        let webDis = (windowHeight + core) * zoomL - 80;
        let wechatDis = (windowHeight + core + web + app) * zoomL - 80
        let mlDis = (windowHeight + core + web + app + wechat) * zoomL - 80;

        if (curPos < webDis) {
            this.setState({
                showWeb: false,
                showApp: false,
                showWechat: false,
                showML: false,
            })
            return;
        }

        if (curPos < appDis) {
            this.setState({
                showWeb: true,
                showApp: false,
                showWechat: false,
                showML: false,
            })
            return;
        }

        if (curPos < wechatDis) {
            this.setState({
                showWeb: true,
                showApp: true,
                showWechat: false,
                showML: false,
            })
            return;
        }

        if (curPos < mlDis) {
            this.setState({
                showWeb: true,
                showApp: true,
                showWechat: true,
                showML: false,
            })
            return;
        }

        this.setState({
            showWeb: true,
            showApp: true,
            showWechat: true,
            showML: true,
        })
    }

    handleScroll = () => {
        window.onscroll = () => {
            handleMenuScroll(this)
            if (isMobile()) {
                this.defineMobileMenu()
            } else {
                this.checkTagView()
            }
        }
    }

    getWindowWidth = e => {
        handleNarrowMenu(this, e)
    }

    componentDidMount() {
        window.onscroll = this.handleScroll;
        window.addEventListener('resize', this.getWindowWidth)
        if (!this.state.isMobile) {
            let inTime = Date.now();
            this.setState({
                inTime: inTime,
            })
            window.addEventListener('beforeunload', this.recordVisitor);
        }
    }

    recordVisitor = () => {
        sendVisitorRecord(this.state.inTime, configName.SERVICE, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getWindowWidth)
        if (!this.state.isMobile) {
            window.removeEventListener('beforeunload', this.recordVisitor)
        } else {
            sendVisitorRecord(0, configName.SERVICE, true);
        }
    }

    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }

    setLoad = () => {
        this.setState({
            isLoaded: true,
        })
    }

    render() {
        const needSMenu = this.state.needSMenu
        const needTopBG = this.state.needTopBG;
        let isMobileL = this.state.isMobile;
        let zoomL = this.state.zoomSize;
        if (!isMobileL) {
            let isLoaded = this.state.isLoaded;
            return ([
                <div style={{
                    textAlign: "center",
                    background: "rgb(224,224,224)",
                    height: "100vh",
                    position: "fixed",
                    width: "100%",
                    display: isLoaded ? "none" : ""
                }}>
                    <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                </div>,
                <div className="service-page">
                    <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                    <div style={{zoom: zoomL, minWidth: "1450px", display: isLoaded ? "" : "none"}}>
                        <QQContact/>
                        <div className="item-app-general-title-top"
                             style={this.state.showWeb ? {
                                 zIndex: 999,
                                 marginTop: 80 / zoomL + "px",
                                 background: "rgba(64, 106, 142, 0.6)"
                             } : {zIndex: -1, marginTop: "80px", background: "rgba(64, 106, 142, 0.6)"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-web-info")
                             }}>
                            <div className="item-app-general-title-text-top">Web 定制</div>
                        </div>
                        <div className="item-app-general-title-top"
                             style={this.state.showApp ? {
                                 zIndex: 999,
                                 marginTop: 140 / zoomL + "px",
                                 background: "rgba(95, 116, 134, 0.6)"
                             } : {zIndex: -1, marginTop: "140px", background: "rgba(95, 116, 134, 0.6)"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-app-info")
                             }}>
                            <div className="item-app-general-title-text-top">App 开发</div>
                        </div>
                        <div className="item-app-general-title-top"
                             style={this.state.showWechat ? {
                                 zIndex: 999,
                                 marginTop: 200 / zoomL + "px",
                                 background: "rgba(121, 148, 172, 0.6)"
                             } : {zIndex: -1, marginTop: "200px", background: "rgba(121, 148, 172, 0.6)"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-wechat-info")
                             }}>
                            <div className="item-app-general-title-text-top">公众号服务</div>
                        </div>
                        <div className="item-app-general-title-top"
                             style={this.state.showML ? {
                                 zIndex: 999,
                                 marginTop: 260 / zoomL + "px",
                                 background: "rgba(149, 158, 167, 0.6)"
                             } : {zIndex: -1, marginTop: "260px", background: "rgba(149, 158, 167, 0.6)"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-machine-info")
                             }}>
                            <div className="item-app-general-title-text-top">数据建模</div>
                        </div>
                        <ServiceBanner parent={this}/>
                        <ServiceCore/>
                        <ServiceItem/>
                        <div className={"service-contact-card"}>
                            <div className={"service-contact-card-empty"}/>
                            <ContactCard/>
                        </div>
                        <FooterLocal/>
                        <DocumentTitle title="巨罟大数据科技-服务介绍【为您打造数字科技平台，跟随科技的步伐，走在时代的前沿】" key="title"/>
                    </div>
                </div>]
            );
        } else {
            return (
                <div className="mobile-service-page">
                    <PageHeader needTopBG={true} isMobileL={isMobileL}/>
                    <div
                        className={this.state.showServiceMenu ? "mobile-service-items-menu-visible" : "mobile-service-items-menu-hidden"}>
                        <div className="mobile-service-items-menu-item"
                             style={this.state.showWeb ? {borderColor: "#5c90cd"} : {borderColor: "#ffffff"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-web-info")
                             }}>
                            Web定制
                        </div>
                        <div className="mobile-service-items-menu-item"
                             style={this.state.showApp ? {borderColor: "#5c90cd"} : {borderColor: "#ffffff"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-app-info")
                             }}>
                            App开发
                        </div>
                        <div className="mobile-service-items-menu-item"
                             style={this.state.showWechat ? {borderColor: "#5c90cd"} : {borderColor: "#ffffff"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-wechat-info")
                             }}>
                            公众号服务
                        </div>
                        <div className="mobile-service-items-menu-item"
                             style={this.state.showML ? {borderColor: "#5c90cd"} : {borderColor: "#ffffff"}}
                             onClick={() => {
                                 this.scrollToAnchor("service-machine-info")
                             }}>
                            数据建模
                        </div>
                    </div>
                    <ServiceBanner isMobileL={isMobileL}/>
                    <ServiceCore isMobileL={isMobileL}/>
                    <ServiceItem isMobileL={isMobileL}/>
                    <FooterLocal isMobileL={isMobileL}/>
                    <DocumentTitle title="巨罟大数据科技-服务介绍【为您打造数字科技平台，跟随科技的步伐，走在时代的前沿】" key="title"/>
                </div>
            );
        }
    }
}

export default Service;