import React from "react";
import DocumentTitle from "react-document-title";
import Banner from "./Banner";
import About from "./About";
import Culture from "./Culture";
import Target from "./Target";
import Team from "./Team";
import FooterLocal from "../../FooterLocal";
import './static/style'
import PageHeader from "../../PageHeader";
import {isMobile} from "../../util/MobileMgr"
import {findZoom, handleMenuScroll, handleNarrowMenu} from "../../util/WindowHelper";
import MobileBKContent from "./MobileBKContent";
import {sendVisitorRecord} from "../../util/RequestHelper";
import configName from "../../locales/zh-CN";
import BKontent from "./BKontent";
import {Spin} from "antd";

class Bg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needTopBG: true,
            needSMenu: this.setInitMenu(),
            zoomSize: findZoom(1480),
            inTime: 0,
            isMobile: isMobile(),
            isLoaded: false,
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    handleScroll = () => {
        window.onscroll = () => {
            handleMenuScroll(this)
        }
    }

    getWindowWidth = e => {
        handleNarrowMenu(this, e)
    }

    componentDidMount() {
        if (!this.state.isMobile) {
            let inTime = Date.now();
            this.setState({
                inTime: inTime,
            })
            window.addEventListener('beforeunload', this.recordVisitor);
        }
        window.onscroll = this.handleScroll;
        window.addEventListener('resize', this.getWindowWidth)
    }

    recordVisitor = () => {
        sendVisitorRecord(this.state.inTime, configName.COMPANY_INTRO, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getWindowWidth)
        if (!this.state.isMobile) {
            window.removeEventListener('beforeunload', this.recordVisitor)
        } else {
            sendVisitorRecord(0, configName.COMPANY_INTRO, true);
        }
    }

    onEnterChange = (mode) => {
        this.setState({
            isFirstScreen: mode === 'enter',
        });
    }

    setLoad = () => {
        this.setState({
            isLoaded: true,
        })
    }

    render() {
        const needSMenu = this.state.needSMenu;
        const needTopBG = this.state.needTopBG;
        let isMobileL = this.state.isMobile;
        if (!isMobileL) {
            let isLoaded = this.state.isLoaded;
            return ([
                <div style={{
                    textAlign: "center",
                    background: "rgb(224,224,224)",
                    height: "100vh",
                    position: "fixed",
                    width: "100%",
                    display: isLoaded ? "none" : ""
                }}>
                    <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                </div>,
                <div className="background-page">
                    <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                    <div style={{zoom: this.state.zoomSize, display: isLoaded ? "" : "none"}}>
                        <Banner key="banner2" parent={this}/>
                        {/*<About/>*/}
                        {/*<Culture/>*/}
                        {/*<Target/>*/}
                        <BKontent/>
                        <Team/>
                        <FooterLocal/>
                        <DocumentTitle title="巨罟大数据科技【为您打造尖刀产品，赢得上甘岭】" key="title"/>
                    </div>
                </div>]
            );
        } else {
            return (
                <div className="mobile-background-page">
                    <PageHeader needTopBG={true} isMobileL={isMobileL}/>
                    <Banner isMobileL={isMobileL}/>
                    <MobileBKContent/>
                    {/*<Banner isMobileL={isMobileL}/>*/}
                    {/*<About isMobileL={isMobileL}/>*/}
                    {/*<Culture isMobileL={isMobileL}/>*/}
                    {/*<Target isMobileL={isMobileL}/>*/}
                    <Team isMobileL={isMobileL}/>
                    <FooterLocal isMobileL={true}/>
                    <DocumentTitle title="巨罟大数据科技【为您打造尖刀产品，赢得上甘岭】" key="title"/>
                    {/*<FooterLocal/>*/}
                </div>
            );
        }
    }
}

export default Bg;