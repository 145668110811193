import React from 'react';
import "./footer.less"
import JUGULogo from "./picture/JUGULogo.svg";
import JUGUW from "./picture/JUGUWORD.png";
import WeCode from "./picture/WeCode.png";

const copyRight = "Copyright © 2021 Jugu. All Rights Reserved. 巨罟大数据科技有限公司 版权所有 ";

class FooterLocal extends React.Component {
    contactInfo(isMobileL) {
        return (
            <div className={isMobileL ? "mobile-footer-left-text" : "footer-left-text"}>
                <p>
                    公司座机：
                    <span><a href={"tel: 021-61634428"} style={{color: "#12c1ba"}}>021-61634428</a></span>
                </p>
                <p>
                    手机号码：
                    <span><a href={"tel: 13916261628"} style={{color: "#12c1ba"}}>13916261628</a></span>
                </p>
                <p>
                    Q Q 客服：
                    <span>640715376</span>
                </p>
                <p>
                    服务时间：
                    <span>周一至周日 9：00 - 21：00</span>
                </p>
            </div>
        );
    }

    getCodeInfo() {
        return (
            <div className={"mobile-we-code"}>
                <img src={WeCode} style={{width: "200px"}} alt={""}/>
            </div>
        );
    }

    render() {
        let isMobileL = this.props.isMobileL;

        return (
            <div className={isMobileL ? "mobile-footer" : "footer"}>
                <div className={isMobileL ? "mobile-footer-wrapper" : "footer-wrapper"}>
                    <div className={isMobileL ? "mobile-footer-left" : "footer-left"}>
                        <div className={isMobileL ? "mobile-footer-logo" : "footer-logo"}>
                            <img src={JUGULogo} alt={""} style={isMobileL ? {width: "100px", paddingTop: "10px"} : {
                                width: "60px",
                                paddingTop: "10px"
                            }}/>
                            <img src={JUGUW} alt={""}
                                 style={isMobileL ? {width: "140px", paddingTop: "10px", marginLeft: "10px"} : {
                                     width: "80px",
                                     paddingTop: "10px",
                                     marginLeft: "8px"
                                 }}/>
                        </div>
                        {isMobileL ? this.getCodeInfo() : this.contactInfo(isMobileL)}
                    </div>
                    <div className={isMobileL ? "mobile-footer-right" : "footer-right"}>
                        <dl>
                            <dt>
                                <div className={isMobileL ? "mobile-footer-right-title" : "footer-right-title"}>
                                    <h3>关于我们</h3>
                                </div>
                            </dt>
                            <dd className={isMobileL ? "mobile-footer-right-text" : "footer-right-text"}>
                                <a href="/pro/background">公司简介</a>
                                <a href="/contact">联系我们</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className={isMobileL ? "mobile-footer-right-title" : "footer-right-title"}>
                                    <h3>服务介绍</h3>
                                </div>
                            </dt>
                            <dd className={isMobileL ? "mobile-footer-right-text" : "footer-right-text"}>
                                <a href="/services#service-web-info">Web定制</a>
                                <a href="/services#service-app-info">App开发</a>
                                <a href="/services#service-wechat-info">公众号服务</a>
                                <a href="/services#service-machine-info">机器学习</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className={isMobileL ? "mobile-footer-right-title" : "footer-right-title"}>
                                    <h3>加入我们</h3>
                                </div>
                            </dt>
                            <dd className={isMobileL ? "mobile-footer-right-text" : "footer-right-text"}>
                                <a href="/contact">职务招聘</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className={isMobileL ? "mobile-footer-right-title" : "footer-right-title"}>
                                    <h3>关注我们</h3>
                                </div>
                            </dt>
                            <dd className={isMobileL ? "mobile-footer-right-text" : "footer-right-text"}>
                                <a href="/news">新闻动态</a>
                            </dd>
                        </dl>
                    </div>
                    <div className={isMobileL ? "mobile-footer-copyright" : "footer-copyright"}>
                        {isMobileL ? this.contactInfo(isMobileL) : <div/>}
                        {isMobileL ?
                            <div>
                                {copyRight}
                                <br/>
                                <a style={{color: "white"}} href={"https://beian.miit.gov.cn"} rel="noreferrer" target={"_blank"}>沪ICP备2021015161号-1</a>
                            </div>
                            :
                            <div>
                                {copyRight + "—— "}
                                <a style={{color: "white"}} href={"https://beian.miit.gov.cn"} rel="noreferrer" target={"_blank"}>沪ICP备2021015161号-1</a>
                            </div>}
                    </div>
                </div>

            </div>
        )
    }
}

export default FooterLocal;