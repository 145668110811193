import React from "react";
import "./serviceitem.less"
import {CheckSquareOutlined} from "@ant-design/icons";

class ModelingService extends React.Component {
    render() {
        let isMobileL = this.props.isMobile;

        return (
            <div className={isMobileL ? "mobile-ml-content" : "ml-content"}>
                <div className={isMobileL ? "mobile-ml-img" : "ml-img"}/>
                <div className={isMobileL ? "mobile-ml-text" : "ml-text"}>
                    随着人工智能的发展与数字化城市的深入建设，企业的数字化转型与落实成为了提升企业形象 、加速企业发展、扩大企业增收必不可少的核心环节。巨罟科技为您一带一路，保驾护航。
                    <div className={isMobileL ? "mobile-ml-text-points" : "ml-text-points"}>
                        <div className={isMobileL ? "mobile-ml-text-point" : "ml-text-point"}>
                            <CheckSquareOutlined style={{marginRight: "10px"}}/>
                            用户画像分析 —— 挖掘潜在客户，提升顾客忠诚度；</div>
                        <div className={isMobileL ? "mobile-ml-text-point" : "ml-text-point"}>
                            <CheckSquareOutlined style={{marginRight: "10px"}}/>
                            市场投放策划 —— 提高推广转换比，可视化收益预期；</div>
                        <div className={isMobileL ? "mobile-ml-text-point" : "ml-text-point"}>
                            <CheckSquareOutlined style={{marginRight: "10px"}}/>
                            企业健康评估 —— 多维度KPI分析，智能模型风控；</div>
                        <div className={isMobileL ? "mobile-ml-text-point" : "ml-text-point"}>
                            <CheckSquareOutlined style={{marginRight: "10px"}}/>
                            自定义模型 —— 针对业务量身定制，提供独一无二的解决方案；</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModelingService;