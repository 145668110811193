import React from 'react';
import './image.css'
import './cards.css'
import '../index.css'
import FooterLocal from "../FooterLocal";
import HomeCarousel from "./home/HomeCarousel";
import CoreTag from "./home/CoreTag";
import ServiceTag from "./home/ServiceTag";
import Slogan from "../picture/HomeSlogan.jpg"
import {isMobile} from "../util/MobileMgr"
import PageHeader from "../PageHeader";
import {Spin} from "antd";
import ContactCard from "./ContactCard";
import {findZoom} from "../util/WindowHelper";
import {sendVisitorRecord} from "../util/RequestHelper";
import configName from "../locales/zh-CN";
import QQContact from "../util/QQContact";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomSize: findZoom(1550),
            isLoaded: false,
            slogan: "",
            inTime: 0,
            isMobile: isMobile(),
        }
    }

    setLoaded = (slogan) => {
        this.setState({
            isLoaded: true,
            slogan: slogan,
        })
    }

    componentDidMount() {
        if (!this.state.isMobile) {
            let inTime = Date.now();
            this.setState({
                inTime: inTime,
            })
            window.addEventListener('beforeunload', this.recordVisitor);
        }
    }

    recordVisitor = () => {
        sendVisitorRecord(this.state.inTime, configName.HOME_PAGE, false);
    }

    componentWillUnmount() {
        if (!this.state.isMobile) {
            window.removeEventListener('beforeunload', this.recordVisitor)
        } else {
            sendVisitorRecord(0, configName.HOME_PAGE, true);
        }
    }

    render() {
        let isMobileL = this.state.isMobile;
        if (!isMobileL) {
            let isLoaded = this.state.isLoaded;
            return (
                [<div style={{
                    textAlign: "center",
                    background: "rgb(224,224,224)",
                    height: "100vh",
                    display: isLoaded ? "none" : ""
                }}>
                    <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                </div>,
                    <div className="home-page" style={{zoom: this.state.zoomSize, display: isLoaded ? "" : "none"}}>
                        <HomeCarousel parent={this} slogan={Slogan}/>
                        <div className={"home-empty-top"}/>
                        <div className={"bc-tag"}>
                            <img className={"home-slogan-pic"} src={this.state.slogan} alt={""}/>
                            <div className={"slogan-des"}>
                                <div className={"slogan-line-cn"}>
                                    <div className={"slogan-line-cn-word"}>数字世界，驱动未来</div>
                                </div>
                                <div className={"slogan-border"}/>
                                <div className={"slogan-line-en"}>
                                    <div className={"slogan-line-en-word"}>DIGITAL WORLD, LEADING FUTURE</div>
                                </div>
                            </div>
                        </div>
                        <QQContact/>
                        <CoreTag/>
                        {/*<ServiceTag/>*/}


                        <div className="level-three" id={"contact-us"}>
                            <div className={"home-empty-button"}/>
                            <ContactCard/>
                            <div style={{height: "100px"}}/>
                        </div>
                        <div className={"home-footer-setting"}>
                            <FooterLocal/>
                        </div>
                    </div>]
            );
        } else {
            return (
                <div className="mobile-home-page">
                    <PageHeader needTopBG={true} isMobileL={isMobileL}/>
                    <HomeCarousel isMobileL={isMobileL}/>
                    <CoreTag isMobileL={isMobileL}/>
                    {/*<ServiceTag isMobileL={isMobileL}/>*/}
                    <FooterLocal isMobileL={isMobileL}/>
                </div>
            );
        }
    }
}

export default Home;