import React from 'react';
import './cards.css'
import {Input} from "antd";
import {ContactUrl, sendContactRequest} from "../util/RequestHelper";

class ContactCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isNameLegal: false,
            isContactLegal: false,
            isDemandLegal: false,
            isLegal: false,
            contactPage: false,
        }
    }

    contactInfo = {
        submitter: {
            name: '',
            contact: '',
            demand: '',
        }
    }

    genContactInfo(e, props) {
        let submitter = this.contactInfo.submitter;
        let inputValue = e.target.value
        if (this.isInputLegal(props, inputValue)) {
            submitter[props] = inputValue;
        }
        this.setState({
            submitter
        })
    }

    isInputLegal(props, value) {
        switch (props) {
            case 'name': {
                const reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
                if (value && reg.test(value) && value.length < 15) {
                    this.setState({
                        isNameLegal: true
                    })
                    return true;
                } else {
                    this.setState({
                        isNameLegal: false
                    })
                    return false;
                }
            }
            case 'contact': {
                if (value && value.length < 40) {
                    this.setState({
                        isContactLegal: true
                    })
                    return true;
                } else {
                    this.setState({
                        isContactLegal: false
                    })
                    return false;
                }
            }
            case 'demand': {
                if (value && value.length < 200) {
                    this.setState({
                        isDemandLegal: true
                    })
                    return true;
                } else {
                    this.setState({
                        isDemandLegal: false
                    })
                    return false;
                }
            }
            default: {
                return false;
            }
        }

    }

    send() {
        if (this.state.isNameLegal && this.state.isContactLegal && this.state.isDemandLegal) {
            this.setState(
                {
                    isLegal: true,
                }
            )
            sendContactRequest(this, this.contactInfo.submitter, ContactUrl())
        } else {
            this.setState(
                {
                    isLegal: false,
                }
            )
        }
        this.setState(
            {
                isSubmitted: true,
            }
        )
    }

    getResponseText() {
        let text = "";
        if (this.state.isLegal) {
            text += "非常感谢您的提交，我们会尽快给您满意的答复！";
        } else {
            if (!this.state.isNameLegal) {
                text += "输入姓名无效（仅允许字母或数字，长度需小于15） "
            }
            if (!this.state.isContactLegal) {
                text += "输入联系方式无效（长度需小于40） "
            }
            if (!this.state.isDemandLegal) {
                text += "输入需求无效（长度需小于200，不包含除标点以外特殊符号） "
            }
        }
        return text;
    }

    render() {
        let isContactPage = this.props.contactPage;
        return (
            <div className={isContactPage ? "contact-us-card-contact" : "contact-us-card"}>
                <div className={isContactPage ? "contact-us-card-title-contact" : "contact-us-card-title"}>
                    <div id={isContactPage ? "contact-card-cn-contact" : "contact-card-cn"}>联系我们</div>
                    <div id={isContactPage ? "contact-card-en-contact" : "contact-card-en"}>CONTACT US</div>
                    <div className={isContactPage ? "contact-card-des-contact" : "contact-card-des"}>我们很乐意倾听您的声音！</div>
                    <div
                        className={isContactPage ? "contact-card-des-contact" : "contact-card-des"}>即刻与我们取得联络，成为日后肩并肩合作的伙伴。
                    </div>
                </div>


                <form>
                    <ul className={isContactPage ? "contact-card-table-contact" : "contact-card-table"}>
                        <li className={isContactPage ? "contact-us-text-name-contact" : "contact-us-text-name"}>
                            <Input className={isContactPage ? "contact-us-input-contact" : "contact-us-input"}
                                   placeholder={"请输入您的姓名*"} bordered={false}
                                   onChange={(e) => this.genContactInfo(e, 'name')}/>
                        </li>
                        <li className={isContactPage ? "contact-us-text-ct-contact" : "contact-us-text-ct"}>
                            <Input className={isContactPage ? "contact-us-input-contact" : "contact-us-input"}
                                   placeholder={"请输入您的联系方式（手机/邮箱）*"} bordered={false}
                                   onChange={(e) => this.genContactInfo(e, 'contact')}/>
                        </li>
                        <li className={isContactPage ? "contact-us-text-md-contact" : "contact-us-text-md"}>
                            <Input className={isContactPage ? "contact-us-input-contact" : "contact-us-input"}
                                   placeholder={"请输入您的需求*"} bordered={false}
                                   onChange={(e) => this.genContactInfo(e, 'demand')}/>
                        </li>
                    </ul>
                </form>
                <div>
                    <button className={isContactPage ? "contact-submit-contact" : "contact-submit"}
                            onClick={() => this.send()}>点击提交 >
                    </button>
                </div>
                <div style={isContactPage ? {
                    color: this.state.isLegal ? "white" : "red",
                    opacity: this.state.isSubmitted ? "1" : "0",
                    float: "left",
                    width: "24vw",
                    fontSize: "0.5vw",
                    marginLeft: "5vw",
                    lineHeight: "1vw"
                } : {
                    color: this.state.isLegal ? "white" : "red",
                    opacity: this.state.isSubmitted ? "1" : "0",
                    marginTop: "20px"
                }}>{this.getResponseText()}</div>
            </div>
        );
    }
}

export default ContactCard;