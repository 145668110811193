import React from 'react'
import './service.css'
import ServiceCoreSoftware from "../../picture/ServiceCoreSoftware.jpg"
import ServiceCoreDigital from "../../picture/ServiceCoreDigital.jpg"
import ServiceCoreMarketing from "../../picture/ServiceCoreMarketing.jpg"
import ServiceCoreWeChart from "../../picture/ServiceCoreWechart.jpg"
import {CSSTransition} from "react-transition-group";
import {Button} from "antd";
import TabsControl from "./TabsControl";

const softwareTags = [
    {
        tag: 'React',
        location: '/newsInfo/2021-04-21React入门.html'
    },
    {
        tag: 'Maven',
        location: '/newsInfo/2021-04-20Maven入门.html'
    },
    {
        tag: 'Database',
        location: '/newsInfo/2021-04-19Database入门.html'
    },
    {
        tag: '积木式架构',
        location: '/newsInfo/2021-04-19积木式架构入门.html'
    },
    {
        tag: '安全加密',
        location: '/newsInfo/2021-04-19安全加密入门.html'
    },
]
const digitalTags = [
    {
        tag: '机器学习',
        location: '/newsInfo/2021-04-19机器学习入门.html'
    },
    {
        tag: '人工智能',
        location: '/newsInfo/2021-04-19人工智能入门.html'
    },
    {
        tag: '大数据分析',
        location: '/newsInfo/2021-04-19大数据分析入门.html'
    },
    {
        tag: '预测模型',
        location: '/newsInfo/2021-04-19预测模型入门.html'
    },
]
const designTags = [
    {
        tag: '全网营销',
        location: '/newsInfo/2021-04-18全网营销入门.html'
    },
    {
        tag: 'PhotoShop',
        location: '/newsInfo/2021-04-18PS设计入门.html'
    },
    {
        tag: 'AI',
        location: '/newsInfo/2021-04-18AI设计入门.html'
    },
    {
        tag: '视觉设计',
        location: '/newsInfo/2021-04-18视觉设计入门.html'
    },
]
const weChartTags = [
    {
        tag: 'H5开发',
        location: '/newsInfo/2021-04-19H5开发入门.html'
    },
    {
        tag: '文案设计',
        location: '/newsInfo/2021-04-18文案设计入门.html'
    },
    {
        tag: '公众号运营',
        location: '/newsInfo/2021-04-18微信公众号运营入门.html'
    },
]

class ServiceCore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coreLabel: 0,
            index: 0,
        };
    }

    tab(arg1,event) {
        this.setState({index:arg1})

    }

    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                coreLabel: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                coreLabel: label
            })
        }, 100)
    }

    getSelectIdx() {
        let url = window.location.href;
        if (url.search("=") !== -1) {
            return url.split("=")[1];
        }
        return "0";
    }

    findCoreTags(core, isMobileL) {
        let tagList = [];
        switch (core) {
            case "software": {
                softwareTags.forEach(function (element) {
                    tagList.push(<Button className={isMobileL ? "mobile-line-tag" : "line-tag"} shape={isMobileL ? "" : "round"} onClick={() => {
                        window.location.href = element.location
                    }}>{element.tag}</Button>)
                });
                break;
            }
            case "digital": {
                digitalTags.forEach(function (element) {
                    tagList.push(<Button className={isMobileL ? "mobile-line-tag" : "line-tag"} shape={isMobileL ? "" : "round"} onClick={() => {
                        window.location.href = element.location
                    }}>{element.tag}</Button>)
                });
                break;
            }
            case "design": {
                designTags.forEach(function (element) {
                    tagList.push(<Button className={isMobileL ? "mobile-line-tag" : "line-tag"} shape={isMobileL ? "" : "round"} onClick={() => {
                        window.location.href = element.location
                    }}>{element.tag}</Button>)
                });
                break;
            }
            case "we-chart": {
                weChartTags.forEach(function (element) {
                    tagList.push(<Button className={isMobileL ? "mobile-line-tag" : "line-tag"} shape={isMobileL ? "" : "round"} onClick={() => {
                        window.location.href = element.location
                    }}>{element.tag}</Button>)
                });
                break;
            }
            default: {
                break;
            }
        }
        return <div className={isMobileL ? "mobile-line-tags" : "line-tags"}>{tagList}</div>
    }

    render() {
        const coreLabel = this.state.coreLabel
        let isMobileL = this.props.isMobileL;
        if (!isMobileL) {
            return (
                <div className={"service-cores"} id={"cores"}>
                    {/*<div className="service-cores-title">核心竞争力部分</div>*/}
                    <div className={"cores-contents"}>
                        <div className={"core-size-software"}
                             onMouseEnter={this.MouseEnter.bind(this, 1)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            <div className={coreLabel === 1 ? "core-enter-software-e" : "core-size-software-e"}>
                                <div className={"core-title"}>360°软件产品塑造</div>
                                <div className={"core-size-software-angle"}/>
                                <div className={"service-core-content"}>
                                    <div className={"service-core"} id={"left-position"}>
                                        <CSSTransition in={coreLabel === 1}
                                                       classNames="service-core-image"
                                                       timeout={4000}
                                                       appear>
                                            <img className="service-image" src={ServiceCoreSoftware} width="100%"
                                                 height="100%" alt={""}/>
                                        </CSSTransition>
                                    </div>
                                    <div className="service-core-left-bg"/>
                                    <div className={"core-single-content"}>
                                        <div className={"core-statement"}>量身打造尖刀产品</div>
                                        <div className={"core-tag"}>
                                            结合行业调性及品牌战略，前端实现品牌化的视觉表现，后端以积木式架构铺设，业务逻辑算法落地，云服务高效部署，个性化打造软件产品， 让产品发声，与用户共鸣。
                                        </div>
                                    </div>
                                    {this.findCoreTags("software", isMobileL)}
                                </div>

                            </div>
                        </div>
                        <div className={"core-size-digital"}
                             onMouseEnter={this.MouseEnter.bind(this, 2)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            <div className={coreLabel === 2 ? "core-enter-digital-e" : "core-size-digital-e"}>
                                <div className={"core-title"}>数字赋能驱动行业</div>
                                <div className={"core-size-digital-angle"}/>
                                <div className={"service-core-content"}>
                                    <div className={"core-single-content"}>
                                        <div className={"core-statement"}>智能化商业引擎</div>
                                        <div className={"core-tag"}>
                                            基于业务的数字化和数据的处理需求，提供数字化治理以及洞察分析，搭建商业数字化平台，融合建模数模一体化技术，助力品牌数字化升级。
                                        </div>
                                    </div>
                                    <div className="service-core-right-bg"/>
                                    <div className={"service-core"} id={"right-position"}>
                                        <CSSTransition in={coreLabel === 2}
                                                       classNames="service-core-image"
                                                       timeout={4000}
                                                       appear>
                                            <img className="service-image" src={ServiceCoreDigital} width="100%"
                                                 height="100%" alt={""}/>
                                        </CSSTransition>
                                    </div>
                                    {this.findCoreTags("digital", isMobileL)}
                                </div>
                            </div>
                        </div>
                        <div className={"core-size-design"}
                             onMouseEnter={this.MouseEnter.bind(this, 3)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            <div className={coreLabel === 3 ? "core-enter-design-e" : "core-size-design-e"}>
                                <div className={"core-title"}>全网型营销服务</div>
                                <div className={"core-size-design-angle"}/>
                                <div className={"service-core-content"}>
                                    <div className={"service-core"} id={"left-position"}>
                                        <CSSTransition in={coreLabel === 3}
                                                       classNames="service-core-image"
                                                       timeout={4000}
                                                       appear>
                                            <img className="service-image" src={ServiceCoreMarketing} width="100%"
                                                 height="100%" alt={""}/>
                                        </CSSTransition>
                                    </div>
                                    <div className="service-core-left-bg"/>
                                    <div className={"core-single-content"}>
                                        <div className={"core-statement"}>您的品牌营销专家</div>
                                        <div className={"core-tag"}>
                                            打造全网营销模式，提供多渠道内容覆盖、整合营销规划以及专业文案内容策划，塑造品牌形象，提高营销转化率。
                                        </div>
                                    </div>
                                    {this.findCoreTags("design", isMobileL)}
                                </div>
                            </div>
                        </div>
                        <div className={"core-size-chart"}
                             onMouseEnter={this.MouseEnter.bind(this, 4)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            <div className={coreLabel === 4 ? "core-enter-chart-e" : "core-size-chart-e"}>
                                <div className={"core-title"}>一站式新媒体代运营专家</div>
                                <div className={"core-size-we-chart-angle"}/>
                                <div className={"service-core-content"}>
                                    <div className={"core-single-content"}>
                                        <div className={"core-statement"}>为您的社交窗口保驾护航</div>
                                        <div className={"core-tag"}>
                                            基于微信公众平台为企业提供开发、搭建、运营、培训、推广为一体的解决方案。包含文案创作，图片设计，功能开发等。
                                        </div>
                                    </div>
                                    <div className="service-core-right-bg"/>
                                    <div className={"service-core"} id={"right-position"}>
                                        <CSSTransition in={coreLabel === 4}
                                                       classNames="service-core-image"
                                                       timeout={4000}
                                                       appear>
                                            <img className="service-image" src={ServiceCoreWeChart} width="100%"
                                                 height="100%" alt={""}/>
                                        </CSSTransition>
                                    </div>
                                    {this.findCoreTags("we-chart", isMobileL)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            let idx = this.getSelectIdx();
            return (
                <div className="mobile-service-cores"  id={"mobile-core-select-tab"}>
                    {/*<div className="mobile-service-cores-title">*/}
                    {/*    核心竞争力*/}
                    {/*</div>*/}
                    <div className="mobile-service-cores-container clearfix">
                        <TabsControl idx={idx}>
                            <div name = "360°软件产品塑造">
                                <div className="mobile-service-cores-software">
                                    {/*<img className="mobile-service-cores-image" src={ServiceCoreSoftware}  alt={""}/>*/}
                                    <div className="mobile-service-cores-box">
                                        <div className="mobile-service-cores-software-label"/>
                                        <div className="mobile-service-cores-content">
                                            {/*<div className="mobile-service-cores-content-title">*/}
                                            {/*    360°软件产品塑造*/}
                                            {/*</div>*/}
                                            {/*<div className="mobile-service-cores-content-subtitle">*/}
                                            {/*    量身塑造品牌形象*/}
                                            {/*</div>*/}
                                            <div className="mobile-service-cores-content-txt">
                                                结合行业调性及品牌战略，前端实现品牌化的视觉表现、动态网页编辑，后端以积木式架构铺设确保业务连续性，业务逻辑算法落地，云服务高效部署，个性化打造软件产品。 让产品发声，与用户共鸣。
                                            </div>
                                        </div>
                                        <div className="mobile-service-cores-tags">
                                            {this.findCoreTags("software", isMobileL)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name = "数字赋能驱动行业">
                                <div className="mobile-service-cores-digital">
                                    {/*<img className="mobile-service-cores-image" src={ServiceCoreDigital} alt={""}/>*/}
                                    <div className="mobile-service-cores-box">
                                        <div className="mobile-service-cores-digital-label"/>
                                        <div className="mobile-service-cores-content">
                                            {/*<div className="mobile-service-cores-content-title">*/}
                                            {/*    数字赋能驱动行业*/}
                                            {/*</div>*/}
                                            {/*<div className="mobile-service-cores-content-subtitle">*/}
                                            {/*    智能化商业引擎*/}
                                            {/*</div>*/}
                                            <div className="mobile-service-cores-content-txt">
                                                基于业务的数字化和数据的处理需求，提供数字化治理、洞察分析以及结果预测，结合实际业务搭建商业数字化平台，融合建模数模一体化技术，助力品牌数字化升级。
                                            </div>
                                        </div>
                                        <div className="mobile-service-cores-tags">
                                            {this.findCoreTags("digital", isMobileL)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name = "全网型营销服务">
                                <div className="mobile-service-cores-marketing">
                                    {/*<img className="mobile-service-cores-image" src={ServiceCoreMarketing} alt={""}/>*/}
                                    <div className="mobile-service-cores-box">
                                        <div className="mobile-service-cores-marketing-label"/>
                                        <div className="mobile-service-cores-content">
                                            {/*<div className="mobile-service-cores-content-title">*/}
                                            {/*    全网型营销服务*/}
                                            {/*</div>*/}
                                            {/*<div className="mobile-service-cores-content-subtitle">*/}
                                            {/*    您的品牌营销专家*/}
                                            {/*</div>*/}
                                            <div className="mobile-service-cores-content-txt">
                                                为您打造全网营销模式，提供多渠道内容覆盖、整合营销规划以及专业文案内容策划：LOGO、海报画册设计，文案排版编辑。塑造品牌形象，提高营销转化率。
                                            </div>
                                        </div>
                                        <div className="mobile-service-cores-tags">
                                            {this.findCoreTags("design", isMobileL)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div name = "一站式新媒体专家">
                                <div className="mobile-service-cores-wechart">
                                    {/*<img className="mobile-service-cores-image" src={ServiceCoreWeChart} alt={""}/>*/}
                                    <div className="mobile-service-cores-box">
                                        <div className="mobile-service-cores-wechart-label"/>
                                        <div className="mobile-service-cores-content">
                                            {/*<div className="mobile-service-cores-content-title">*/}
                                            {/*    一站式新媒体专家*/}
                                            {/*</div>*/}
                                            {/*<div className="mobile-service-cores-content-subtitle">*/}
                                            {/*    为您的社交窗口保驾护航*/}
                                            {/*</div>*/}
                                            <div className="mobile-service-cores-content-txt">
                                                基于微信公众号平台为企业提供功能开发、平台搭建、后台运营、功能培训、推广为一体的解决方案。包含文案创作，图片设计，功能开发等。
                                            </div>
                                        </div>
                                        <div className="mobile-service-cores-tags">
                                            {this.findCoreTags("we-chart", isMobileL)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabsControl>
                        <div className="mobile-service-cores-empty"/>
                    </div>
                    {/*<div className="mobile-service-cores-nav clearfix">*/}
                    {/*    <div className="mobile-service-cores-nav-box">*/}
                    {/*        <div className="mobile-service-cores-nav-list" onClick={this.tab.bind(this,0)}>*/}
                    {/*            360°软件产品塑造*/}
                    {/*        </div>*/}
                    {/*        <div className="mobile-service-cores-nav-list" onClick={this.tab.bind(this,1)}>*/}
                    {/*            数字赋能驱动行业*/}
                    {/*        </div>*/}
                    {/*        <div className="mobile-service-cores-nav-list" onClick={this.tab.bind(this,2)}>*/}
                    {/*            全网型营销服务*/}
                    {/*        </div>*/}
                    {/*        <div className="mobile-service-cores-nav-list" onClick={this.tab.bind(this,3)}>*/}
                    {/*            一站式新媒体专家*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="mobile-service-cores-box">*/}
                    {/*    <div className="mobile-service-cores-software">*/}
                    {/*        /!*<img className="mobile-service-cores-image" src={ServiceCoreSoftware}  alt={""}/>*!/*/}
                    {/*        <div className="mobile-service-cores-box">*/}
                    {/*            <div className="mobile-service-cores-content">*/}
                    {/*                <div className="mobile-service-cores-content-title">*/}
                    {/*                    360°软件产品塑造*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-subtitle">*/}
                    {/*                    量身塑造品牌形象*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-txt">*/}
                    {/*                    结合行业调性及品牌战略，前端实现品牌化的视觉表现，后端以积木式架构铺设，业务逻辑算法落地，云服务高效部署，个性化打造软件产品， 让产品发声，与用户共鸣。*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="mobile-service-cores-tags">*/}
                    {/*                {this.findCoreTags("software", isMobileL)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="mobile-service-cores-digital" style={this.state.style}>*/}
                    {/*        /!*<img className="mobile-service-cores-image" src={ServiceCoreDigital} alt={""}/>*!/*/}
                    {/*        <div className="mobile-service-cores-box">*/}
                    {/*            <div className="mobile-service-cores-content">*/}
                    {/*                <div className="mobile-service-cores-content-title">*/}
                    {/*                    数字赋能驱动行业*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-subtitle">*/}
                    {/*                    智能化商业引擎*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-txt">*/}
                    {/*                    基于业务的数字化和数据的处理需求，提供数字化治理以及洞察分析，搭建商业数字化平台，融合建模数模一体化技术，助力品牌数字化升级。*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="mobile-service-cores-tags">*/}
                    {/*                {this.findCoreTags("digital", isMobileL)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="mobile-service-cores-marketing" style={this.state.style}>*/}
                    {/*        /!*<img className="mobile-service-cores-image" src={ServiceCoreMarketing} alt={""}/>*!/*/}
                    {/*        <div className="mobile-service-cores-box">*/}
                    {/*            <div className="mobile-service-cores-content">*/}
                    {/*                <div className="mobile-service-cores-content-title">*/}
                    {/*                    全网型营销服务*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-subtitle">*/}
                    {/*                    您的品牌营销专家*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-txt">*/}
                    {/*                    打造全网营销模式，提供多渠道内容覆盖、整合营销规划以及专业文案内容策划，塑造品牌形象，提高营销转化率。*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="mobile-service-cores-tags">*/}
                    {/*                {this.findCoreTags("design", isMobileL)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="mobile-service-cores-wechart" style={this.state.style}>*/}
                    {/*        /!*<img className="mobile-service-cores-image" src={ServiceCoreWeChart} alt={""}/>*!/*/}
                    {/*        <div className="mobile-service-cores-box">*/}
                    {/*            <div className="mobile-service-cores-content">*/}
                    {/*                <div className="mobile-service-cores-content-title">*/}
                    {/*                    一站式新媒体代运营专家*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-subtitle">*/}
                    {/*                    为您的社交窗口保驾护航*/}
                    {/*                </div>*/}
                    {/*                <div className="mobile-service-cores-content-txt">*/}
                    {/*                    基于微信公众平台为企业提供开发、搭建、运营、培训、推广为一体的解决方案。包含文案创作，图片设计，功能开发等。*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="mobile-service-cores-tags">*/}
                    {/*                {this.findCoreTags("we-chart", isMobileL)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            );
        }
    }
}

export default ServiceCore