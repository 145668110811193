import React from 'react';
import './contact.css'
import LMap from './LMap';
import './image.css'
import PageHeader from "../PageHeader";
import {isMobile} from "../util/MobileMgr";
import {findZoom, handleNarrowMenu} from "../util/WindowHelper";
import ContactFooter from "./contact/ContactFooter";
import ContactCard from "./ContactCard";
import ContactTopCover from "./contact/ContactTopCover";
import ContactJoinUs from "./contact/ContactJoinUs";
import FooterLocal from "../FooterLocal";
import DocumentTitle from "react-document-title";
import {sendVisitorRecord} from "../util/RequestHelper";
import configName from "../locales/zh-CN";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needFixed: false,
            needFixedBottom: false,
            needTopBG: true,
            needSMenu: this.setInitMenu(),
            needMobileL: isMobile(),
            inTime: 0,
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    handleScroll = () => {
        if (!this.state.needMobileL) {
            const size = document.getElementsByClassName('bottom-page-right')[0].offsetHeight;
            const footer = document.getElementsByClassName('contact-footer')[0].offsetHeight;

            window.onscroll = () => {
                const {needFixed} = this.state;
                const {needFixedBottom} = this.state;
                const {needTopBG} = this.state;

                let remainHeight = window.innerHeight - size - footer;

                let fixedTop = document.getElementsByClassName('empty-layer')[0].offsetTop - remainHeight;
                let fixBottom = document.getElementsByClassName('empty-layer-bottom')[0].offsetTop - remainHeight;
                let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

                //控制元素块A随鼠标滚动固定在顶部
                if (scrollTop > 0 && scrollTop < fixBottom) {
                    needTopBG && this.setState({needTopBG: false});
                } else {
                    !needTopBG && this.setState({needTopBG: true});
                }
                if (scrollTop >= fixedTop) {
                    !needFixed && this.setState({needFixed: true});
                } else {
                    needFixed && this.setState({needFixed: false});
                }
                if (scrollTop >= fixBottom) {
                    !needFixedBottom && this.setState({needFixedBottom: true});
                } else {
                    needFixedBottom && this.setState({needFixedBottom: false});
                }
            }
        }
    }

    getWindowWidth = e => {
        handleNarrowMenu(this, e)
    }

    componentDidMount() {
        window.onscroll = this.handleScroll;
        window.addEventListener('resize', this.getWindowWidth)
        if (!this.state.needMobileL) {
            let inTime = Date.now();
            this.setState({
                inTime: inTime,
            })
            window.addEventListener('beforeunload', this.recordVisitor);
        }
    }

    recordVisitor = () => {
        sendVisitorRecord(this.state.inTime, configName.CONTACT, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getWindowWidth)
        if (!this.state.needMobileL) {
            window.removeEventListener('beforeunload', this.recordVisitor)
        } else {
            sendVisitorRecord(0, configName.CONTACT, true);
        }
    }

    render() {
        const needFixed = this.state.needFixed;
        const needFixedBottom = this.state.needFixedBottom;
        const needTopBG = this.state.needTopBG;
        const needSMenu = this.state.needSMenu;
        let isMobileL = this.state.needMobileL;

        if (isMobileL) {
            return (
                <div className={"mobile-contact-us-page"}>
                    <PageHeader needTopBG={true} isMobileL={isMobileL}/>
                    <ContactTopCover isMobileL={true}/>
                    <ContactJoinUs isMobileL={true}/>
                    <LMap isMobileL={true}/>
                    <FooterLocal isMobileL={true}/>
                    <DocumentTitle title="巨罟大数据科技-联系我们【合作创造共赢，携手共筑未来】" key="title"/>
                </div>
            );
        } else {
            return (
                <div className={"contact-us-page"}>
                    <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                    <LMap/>
                    <ContactTopCover/>
                    <div className={"empty-layer-top"}/>
                    <div className={needFixed ? "bottom-page-right-fix" : "bottom-page-right"}>
                        <ContactCard contactPage={true}/>
                    </div>
                    <div className={"empty-layer"}/>
                    <ContactJoinUs needFixedBottom={needFixedBottom}/>
                    <div className={"empty-layer-bottom"}/>
                    <ContactFooter/>
                    <DocumentTitle title="巨罟大数据科技-联系我们【合作创造共赢，携手共筑未来】" key="title"/>
                </div>
            );
        }
    }
}

export default Contact;