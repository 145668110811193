import React from 'react';
import './news.less';
import {findZoom, handleMenuScroll, handleNarrowMenu} from "../../util/WindowHelper";
import PageHeader from "../../PageHeader";
import FooterLocal from "../../FooterLocal";
import {Pagination} from "antd";
import {isMobile} from "../../util/MobileMgr";
import {newsUrl, sendNewsRequest, sendVisitorRecord} from "../../util/RequestHelper";
import DocumentTitle from "react-document-title";
import configName from "../../locales/zh-CN";

const getNews = (element, bkStyle, isMobileL, windowWidth, page) => {
    let contentWidth = 1200;
    if (isMobileL) {
        contentWidth = windowWidth * 0.98 - 360;
    }

    if (!isMobileL) {
        let labelLocal = page.state.label;
        return (
            <div className="news-card"
                 style={{boxShadow: labelLocal === element.newsLabel ? "0px 0px 10px #888888" : ""}}
                 onClick={() => {
                     window.location.href = "/newsInfo" + element.newsClickTarget
                 }}>
                {/*<a href={"/news/" + "newsPageReact.html" }>*/}
                <img className="news-cover" src={"/picture/" + element.newsImage}
                     alt={""}/>
                {/*</a>*/}
                <div className="news-content"
                     style={{
                         background: labelLocal === element.newsLabel ? "#b8d9ca" : "#ffffff",
                         width: contentWidth
                     }}
                     onMouseEnter={page.MouseEnter.bind(page, element.newsLabel)}
                     onMouseLeave={page.MouseLeave.bind(page, 0)}
                >
                    <div className="news-card-title">{element.newsTitle}</div>
                    <div className="news-card-date">{element.newsDate}</div>
                    <div className="news-card-brief">{element.newsDes}</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={"mobile-news-card"} onClick={() => {
                window.location.href = "/newsInfo" + element.newsClickTarget
            }}>
                <img className={"mobile-news-cover"} src={"/picture/" + element.newsImage}
                     alt={""}/>
                <div className={"mobile-news-content"}
                     style={{background: bkStyle, width: contentWidth}}>
                    <div className={"mobile-news-card-title"}>{element.newsTitle}</div>
                    <div className={"mobile-news-card-brief"}>
                        <div className={"mobile-news-card-brief-content"}>{page.handleNewsBrief(element.newsDes)}</div>
                    </div>
                    <div className={"mobile-news-card-date"}>{"发布日期：" + element.newsDate}</div>
                    <div className={"mobile-news-card-brief-more"}>【查看详情】</div>
                </div>
            </div>
        );
    }
}

class News extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zoomSize: findZoom(1480),
            needSMenu: this.setInitMenu(),
            newsData: "",
            newsTitle: "",
            needTopBG: true,
            minValue: 0,
            maxValue: 10,
            label: 0,
            curWidth: document.documentElement.clientWidth,
            inTime: 0,
            isMobile: isMobile(),
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    // 鼠标事件
    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                label: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                label: label
            })
        }, 100)
    }


    handleNewsBrief(content) {
        if (!content) {
            return "";
        }
        if (content.length <= 45) {
            return content;
        }
        return content.substring(0, 45) + "......";
    }

    handleScroll = () => {
        window.onscroll = () => {
            handleMenuScroll(this)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setCurWidth)
        window.removeEventListener('resize', this.getWindowWidth)
        if (!this.state.isMobile) {
            window.removeEventListener('beforeunload', this.recordVisitor)
        } else {
            sendVisitorRecord(0, configName.NEWS, true);
        }
    }

    componentDidMount() {
        window.onscroll = this.handleScroll;
        window.addEventListener('resize', this.getWindowWidth)
        window.addEventListener('resize', this.setCurWidth)
        sendNewsRequest(this, newsUrl());

        if (!this.state.isMobile) {
            let inTime = Date.now();
            this.setState({
                inTime: inTime,
            })
            window.addEventListener('beforeunload', this.recordVisitor);
        }
    }

    recordVisitor = () => {
        sendVisitorRecord(this.state.inTime, configName.NEWS, false);
    }

    handleNewsShow = value => {
        if (value <= 1) {
            this.setState({
                minValue: 0,
                maxValue: 10
            });
        } else {
            this.setState({
                minValue: (value - 1) * 10,
                maxValue: (value - 1) * 10 + 10
            });
        }
    }

    loadingNews(newsDataL, isMobileL, windowWidth, page) {
        let newsList = [];
        let i = 0;
        let partitionNews = newsDataL;
        if (!isMobileL) {
            partitionNews = newsDataL.slice(page.state.minValue, page.state.maxValue);
        }
        partitionNews.forEach(function (element) {
                let bkStyle = (i % 2) === 0 ? "white" : "white";
                newsList.push(getNews(element, bkStyle, isMobileL, windowWidth, page));
                i++;
            }
        )
        return newsList;
    }

    getWindowWidth = e => {
        handleNarrowMenu(this, e)
    }

    setCurWidth = e => {
        let curWidth = e.target.innerWidth;
        this.setState({
            curWidth: curWidth
        })
    }

    getNewsContainerMinHeight() {
        let windowHeight = document.documentElement.clientHeight;
        const titleHeight = 300;
        const footerHeight = 20;
        return windowHeight - titleHeight - footerHeight;
    }

    render() {
        let isMobileL = this.state.isMobile;
        let windowWidth = document.documentElement.clientWidth;
        let newsDataL = this.state.newsData;

        console.log(newsDataL)

        const getPagination = () => {
            return (
                <Pagination
                    style={{textAlign: "center", marginTop: "50px"}}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    defaultPageSize={10}
                    onChange={this.handleNewsShow}
                    total={newsDataL.length}/>
            );
        }

        let needTopBG = this.state.needTopBG;
        const needSMenu = this.state.needSMenu;
        let cWidth = this.state.curWidth / this.state.zoomSize;
        let cHeight = (cWidth / 2.8) + "px";

        if (!isMobileL) {
            return (
                <div className="news-page">
                    <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                    <div style={{zoom: this.state.zoomSize, minWidth: "1450px"}}>
                        <div className="news-banner" style={{width: cWidth, height: cHeight}}/>
                        <div className="news-box">
                            <div className="news-title">最新动态</div>
                            <div className={"news-container"} style={{minHeight: this.getNewsContainerMinHeight()}}>
                                {newsDataL && newsDataL.length > 0 && this.loadingNews(newsDataL, isMobileL, windowWidth, this)}
                                {!isMobileL && getPagination()}
                            </div>
                        </div>
                        <FooterLocal/>
                        <DocumentTitle title="巨罟大数据科技-新闻【行业最新资讯，前沿技术分享】" key="title"/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mobile-news-page">
                    <PageHeader needTopBG={needTopBG} isMobileL={isMobileL}/>
                    <div className="mobile-news-banner">{this.state.newsTitle}</div>
                    <div className="news-title">最新动态</div>
                    <div className={"news-container"} style={{minHeight: this.getNewsContainerMinHeight()}}>
                        {newsDataL && newsDataL.length > 0 && this.loadingNews(newsDataL, isMobileL, windowWidth, this)}
                        {!isMobileL && getPagination()}
                    </div>
                    <FooterLocal isMobileL={true}/>
                    <DocumentTitle title="巨罟大数据科技-新闻【行业最新资讯，前沿技术分享】" key="title"/>
                </div>
            );
        }
    }
}

export default News;