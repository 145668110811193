import React from 'react';
import './putao.css';
import {isMobile} from "../util/MobileMgr";
import {sendGetRequest} from "../util/RequestHelper";

class PutaoHost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acts: [],
            loaded: false,
            pass: "",
            page: 1,
            orderId: "",
        }
    }

    componentDidMount() {

    }

    findData = () => {
        let pass = this.state.pass;
        if (pass) {
            sendGetRequest("https://putaotao.jugu-mega.com/host/checkActOrder", {pass: pass}).then((res) => {
                if (res.success) {
                    this.setState({
                        acts: res["responseData"],
                        page: 2,
                    })
                }
            })
        }
    }

    findPList = (pname) => {
        let ps = []
            pname.forEach((p) => {
                ps.push(
                    <div>
                        <div className={"putao-order-m-name"}>
                            {p["name"]}
                        </div>
                        <div className={"putao-order-m-stat"}>
                            <div className={"putao-order-m-stat-price"}>
                                {"¥" + p["price"]}
                            </div>
                            <div className={"putao-order-m-stat-price-name"}>
                                {"数量：" + p["num"]}
                            </div>
                        </div>
                    </div>
                )
            })
        return ps
    }

    genDataPage = () => {
        let acts = this.state.acts;
        let items = []
        if (acts.length === 0) {
            return <div className={"putao-host-empty"}>
                {"暂无订单"}
            </div>
        }
        // let data = res["responseData"];
        // let orderInfo = JSON.parse(data["pinfo"]);
        // let orderPrice = parseInt(data["totalP"])
        // this.setState({
        //     pageNo: 3,
        //     orderInfo: orderInfo,
        //     orderPrice: orderPrice,
        //     expInfo: data["oaddr"],
        //     orderStatus: data["orderStatus"],
        // })
        acts.forEach((a) => {
            let pname = JSON.parse(a["pinfo"]);
            // let valid = a["validH"];
            // let ok = <div style={{marginTop: "50px"}}>{"此订单已确认"}</div>;
            // let cm = <button style={{marginTop: "50px"}} onClick={() => {
            //     this.passCommit(a["orderId"]);
            // }}>{"点击核实订单"}</button>;
            let status = a["orderStatus"] === 1 ? "待支付" : "已支付";
            items.push(
                <div className={"putao-host-item"}>
                    <div>
                        {"订单号：" + a["orderId"]}
                    </div>
                    <div>
                        {"订单状态: " + status}
                    </div>
                    <div>
                        {"下单时间: " + a["orderTime"]}
                    </div>
                    {this.findPList(pname)}
                    <div>
                        {"总价：" + a["totalP"]}
                    </div>
                    <div>
                        {"收货信息：" + a["oaddr"]}
                    </div>

                    {/*{valid ? ok : cm}*/}
                </div>
            )
        })

        return items
    }

    passCommit = (orderId) => {
        this.setState({
            orderId: orderId,
            pass: "",
            page: 3,
        })
    }

    // commitOrder = () => {
    //     let orderId = this.state.orderId;
    //     let pass = this.state.pass;
    //     if (orderId && pass) {
    //         sendGetRequest("/api/validAct", {orderId: orderId, pass: pass}).then((re) => {
    //             if(re.success) {
    //                 this.findData();
    //             }
    //         })
    //     }
    // }

    genPassPage = () => {
        return (
            <div className={"putao-host-pass"}>
                <div>{"请输入通行码"}</div>
                <input className={"putao-host-pass-input"} onChange={(e) => {
                    this.setState({pass: e.target.value})
                }}/>
                <button onClick={() => {
                    this.findData();
                }}>{"确定"}</button>
            </div>
        )
    }

    loadPage = () => {
        let page = this.state.page;
        if (page === 1) {
            return this.genPassPage();
        } else {
            return this.genDataPage();
        }
    }

    render() {
        return (
            <div className={"putao-host-out"}>
                {this.loadPage()}
            </div>
        )
    }
}

export default PutaoHost