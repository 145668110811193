import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import './static/page.less'

class Culture extends React.Component {
    render() {
        let isMobileL = this.props.isMobileL;

        if (!isMobileL) {
            return (
                <div className="three">
                    {/*<div className="background-test-one"></div>*/}
                    <div className="Culture">
                        <ScrollOverPack playScale={0.2} id="" className="content-wrapper page">
                            <TweenOne
                                key="image"
                                className="image2 image-wrapper"
                                animation={{x: 0, opacity: 1, ease: 'easeOutQuad'}}
                            />
                            <QueueAnim
                                className="text-wrapper"
                                key="text"
                                // duration={450}
                                leaveReverse
                            >
                                <h2 key="text-h2">企业文化</h2>
                                <span className="line" key="text-line"/>
                                <p key="text-p">
                                    <p>
                                        树立正确的核心价值观，引领公司企业文化建设
                                    </p>
                                    <p>
                                        坚持以人为本，诚信务实，团结奋进
                                    </p>
                                    <p>
                                        开拓创新，致力卓越，勇于挑战
                                    </p>
                                    <p>
                                        坚持梦想，脚踏实地，精益求精
                                    </p>
                                    <p>
                                        我们是怀揣梦想的实干家
                                    </p>
                                </p>
                            </QueueAnim>
                        </ScrollOverPack>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mobile-three">
                    <div className="mobile-culture">
                        <div className="content-wrapper page">
                            <div key="text" className="text-wrapper">
                                <h2 key="h2">企业文化</h2>
                                <span className="line" key="line"/>
                                <p key="p">
                                    树立正确的核心价值观，引领公司企业文化建设
                                </p>
                                <p>
                                    坚持以人为本，诚信务实，团结奋进
                                </p>
                                <p>
                                    开拓创新，致力卓越，精益求精
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Culture;