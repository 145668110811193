import React, {Component} from 'react';
import {Button, Menu, Popover} from 'antd';
import 'antd/dist/antd.css';
import {useRouteMatch} from "react-router-dom";
import {Header} from "antd/lib/layout/layout";
import "./index.css"
import PageRoutes from "./PageRoutes";
import intl from "react-intl-universal";
import EN from "./locales/en-US";
import CN from "./locales/zh-CN";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    WechatOutlined
} from "@ant-design/icons";
import WeChart from './picture/WeCode.png'
import MenuSelector from "./MenuSelector";
import {isMobile} from "./util/MobileMgr";
import Trans from "./picture/transIcon.svg"
import IconConvertor from "./config/IconConvertor";
import JUGULogo from "./picture/JUGULogo.svg";
import JUGUWORD from "./picture/JUGUWORD.png";

const locales = {
    "en_US": EN,
    "zh_CN": CN,
};

export default class MenuMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        }
    };

    langType = (type) => {
        localStorage.setItem('lang_type', type);
        window.location.reload()
    };

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }


    loadLocales = () => {
        intl.init({
            currentLocale: localStorage.getItem('lang_type') || 'zh_CN',
            locales,
        })
            .then(() => {
                this.setState({initDone: true});
            });
    };

    componentDidMount() {
        this.loadLocales();
    };

    findWeChartInfo(isCollapsed) {
        if (!isCollapsed) {
            return (
                <div className="we-chart">
                    <img className="we-logo" src={WeChart} alt={"微信公众号"}/>
                    <div className="we-content">微信扫码咨询</div>
                </div>
            );
        } else {
            return <div>
                <Popover className={"we-chart-bottom"} placement="rightBottom"
                         content={<img className="we-logo-inside" src={WeChart} alt={"微信公众号"}/>}>
                    <Button className={"slider-button"} type={"primary"}>{<WechatOutlined
                        style={{fontSize: "35px"}}/>}</Button>
                </Popover>
            </div>
        }
    }

    findSideLanguageInfo(isCollapsed, languages) {
        if (isCollapsed) {
            return <div>
                <Popover className={"side-language-bottom"} placement="rightBottom" content={languages}
                         overlayClassName={"side-language-content"}>
                    <Button className={"slider-button"} type={"primary"}>{<IconConvertor svg={Trans}
                                                                                         size={"35px"}/>}</Button>
                </Popover>
            </div>
        } else {
            return <div/>
        }
    }


    render() {
        let isCollapsed = this.state.collapsed;

        const languageMenu = (
            <Menu style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                border: "0px",
                backgroundColor: "rgba(30, 67, 130, 0)"
            }}>
                <Menu.Item className={"language-item"} key="1" onClick={() => this.langType("zh_CN")}>
                    {intl.get("CHINESE")}
                </Menu.Item>
                <Menu.Item className={"language-item"} key="2" onClick={() => this.langType("en_US")}>
                    {intl.get("ENGLISH")}
                </Menu.Item>
            </Menu>
        );

        let Menus = () => {
            let useSliderMenu = false;

            let isMobileL = isMobile();

            let matchPath = useRouteMatch();
            if (matchPath.path === "/" && matchPath.isExact) {
                useSliderMenu = true;
            }

            if (useSliderMenu && !isMobileL) {

                return (
                    <div className={"home-side-layout"}>
                        <Menu className="side-menu" mode="inline" defaultSelectedKeys={['1']}
                              inlineCollapsed={isCollapsed} style={{background: "#545961"}}>
                            <div className={"ju-gu-logo"}
                                 style={isCollapsed ? {textAlign: "center", marginTop: "25px", marginBottom: "20px"} : {marginBottom: "15px" , marginTop: "20px", textAlign: "center"}}
                            >
                                <img className={isCollapsed ? "App-logo" : ""}
                                     src={isCollapsed ? JUGULogo : JUGUWORD}
                                     style={isCollapsed ?{width: "50px"} : {width: "35%"}}
                                     alt={""}
                                />
                            </div>
                            <Header className="side-menu-header">
                                <Button className={"slider-button"} type="primary" onClick={this.toggleCollapsed}>
                                    {isCollapsed ? <MenuUnfoldOutlined style={{fontSize: "25px"}}/> :
                                        <MenuFoldOutlined style={{fontSize: "25px"}}/>}
                                </Button>
                            </Header>

                            <MenuSelector lTheme={"dark"} lMode={"inline"} isCollapsed={isCollapsed}
                                          useSliderMenu={useSliderMenu} lDSKey={['1']}/>
                            {this.findSideLanguageInfo(isCollapsed, languageMenu)}
                            {this.findWeChartInfo(isCollapsed)}
                        </Menu>
                        <div className={isCollapsed ? "home-page-collapsed" : "home-page-not-collapsed"}>
                            <PageRoutes/>
                        </div>
                    </div>
                );
            } else {
                return <div className={"page-layout"}>
                    <PageRoutes/>
                </div>
            }
        }


        return <Menus/>
    }
}