import React from 'react';
import "./index.css";
import {isMobile} from "../../util/MobileMgr";
import BG from "./picture/login_bg.png";
import LOGO from "../../picture/JUGUWORD.png";
import {message} from "antd";
import {BatchItemsUrl, sendItemRequest, sendPostRequest, sendUserLogin} from "../../util/RequestHelper";
import configName from "../../locales/zh-CN";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            userPojo: {
                userName: null,
                // userPhone: null,
                userPwd: null,
            }
        }
    }

    fillValue = (event, tag, userPojo) => {
        let val = event.target.value;
        if (val) {
            userPojo[tag] = val;
            this.setState({
                userPojo: userPojo,
            })
        }
    }

    blurValueByName = (event, userPojo) => {
        let val = event.target.value;
        let len = val.length;
        if (len < 0) {
            message.error("请正确输入用户名");
        } else {
            userPojo["userName"] = val;
            this.setState({
                userPojo: userPojo,
            })
        }
    }

    blurValueByPhone = (event, userPojo) => {
        let val = event.target.value;
        let reg = /^1\d{10}$/;
        let isValid = reg.test(val);
        if (isValid) {
            userPojo["userPhone"] = val;
            this.setState({
                userPojo: userPojo,
            })
        } else {
            message.error("请正确输入手机号");
        }
    }

    blurValueByPwd = (event, userPojo) => {
        let val = event.target.value;
        let len = val.length;
        if (len < 6) {
            message.error("请正确输入密码");
        } else {
            userPojo["userPwd"] = val;
            this.setState({
                userPojo: userPojo,
            })
        }
    }

    clickLogin = (userPojo) => {
        if (userPojo.userName && userPojo.userPwd) {
            this.loginUser(userPojo);
        } else if (!userPojo.userName) {
            message.error("请正确输入用户名");
        } else {
            message.error("请正确输入密码");
        }
    }

    loginUser = (userPojo) => {
        sendPostRequest({url: '/api/loginUser', data: {userName: userPojo.userName, pwd: userPojo.userPwd}})
            .then((res) => {
                // console.log(res);
                if (res.success) {
                    message.success("登陆成功");
                    window.localStorage.setItem('userInfo', userPojo.userName);
                    window.location.href = '/services';
                } else {
                    message.error("用户名或密码错误，请重新输入");
                }
            })
    }

    render() {
        let isMobile = this.state.isMobile;

        // if (isMobile) {
        //
        // } else {
            let userPojo = this.state.userPojo;
            return (
                <>
                    <div className={"login-page-wrap"}>
                        <div className={"login-page"}>
                            <div className={"login-left"}>
                                <img className={"login-left-bg"} src={BG} alt={""}/>
                                <div className={"login-left-ct"}>
                                    <div className={"logo-wrap"}>
                                        <div className={"logo"}>
                                            <img className={"logo-img"} src={LOGO} alt={"logo"}/>
                                        </div>
                                    </div>
                                    <div className={"title-wrap"}>
                                        <span>{"巨罟信息发布平台"}</span>
                                    </div>
                                    <div className={"des-wrap"}>
                                        <span>{"数字世界，驱动未来"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"login-right"}>
                                <div className={"login-right-wrap"}>
                                    <div className={"login-fun-title"}>
                                        <div className={"login-fun-title-txt"}>{"登录"}</div>
                                        <div className={"login-fun-corner"}>
                                            <span>{"没有账号?"}</span>
                                            <span style={{paddingLeft: "8px",color: "rgb(33,115,199)",cursor: "pointer"}}
                                                  onClick={() => {window.location.href = "/register"}}
                                            >
                                                {"立即注册"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={"login-fun-input-wrap"}>
                                        <input className={"login-input"}
                                               key={"userName"}
                                               type={"text"}
                                               placeholder={"请输入用户名"}
                                               maxLength={20}
                                            // onChange={(event => {this.fillValue(event, "userPhone", userPojo)})}
                                               onBlur={(event => {this.blurValueByName(event, userPojo)})}
                                        />
                                        {/*<input className={"login-input"}*/}
                                        {/*       key={"userPhone"}*/}
                                        {/*       type={"text"}*/}
                                        {/*       placeholder={"请输入手机号"}*/}
                                        {/*       maxLength={40}*/}
                                        {/*       // onChange={(event => {this.fillValue(event, "userPhone", userPojo)})}*/}
                                        {/*       onBlur={(event => {this.blurValueByPhone(event, userPojo)})}*/}
                                        {/*/>*/}
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <input className={"login-input"}
                                                   key={"userPwd"}
                                                   type={"password"}
                                                   placeholder={"请输入密码"}
                                                   minLength={6}
                                                   maxLength={40}
                                                   // onChange={(event => {this.fillValue(event, "userPwd", userPojo)})}
                                                   onBlur={(event => {this.blurValueByPwd(event, userPojo)})}
                                            />
                                        </div>
                                        <div className={"login-btn"}
                                             onClick={() => {this.clickLogin(userPojo)}}
                                        >
                                            {"登录"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        // }
    }
}

export default Login;