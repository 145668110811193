// eslint-disable-next-line import/no-anonymous-default-export
export default {
    WELCOME_TO_UMI_WORLD: '{name}，welcome to umi world',
    HOME_PAGE: 'Homepage',
    COMPANY_INTRO: 'Company Introduction',
    ENGLISH: 'EN',
    LANGUAGE: 'Language',
    CHINESE: 'CN',
    SERVICE: 'Services',
    NEWS: 'News',
    CONTACT: 'Contact us',
    COMPANY_BACKGROUND: 'Company background',
    PEOPLE: 'Employees',
    WEB_DESIGN: 'Web Design',
    APP_DESIGN: 'App Design',
    WE_CHART: 'Official Account Maintenance',
    MODELING: 'Data Modeling and Analysis',
    SERVICE_OVERVIEW: 'Service Overview',

    // toDo ICP
    INFO: 'Info',
    UPLOAD: 'Upload',
    //
}