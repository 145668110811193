import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import MenuMgr from "./MenuMgr";
import 'antd/dist/antd.css';
import './index.css';

class App extends React.Component {
    render() {
        return (
            <div>
                <Router>
                    <div className="layout">
                        <MenuMgr/>
                    </div>
                </Router>
            </div>
        )
    }
}

export default App;
