import React from 'react';
import {CloseOutlined, QqOutlined} from "@ant-design/icons";

class QQContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showQQ: true,
        }
    };

    handleQQ = () => {
        const w = window.open('about:blank');
        w.location.href = "http://wpa.qq.com/msgrd?v=3&uin=640715376&site=qq&menu=yes";
    }

    render() {
        return (
            <div style={{
                position: "fixed",
                right: "20px",
                top: "80vh",
                zIndex: 999,
                height: "106px",
                width: "80px",
                background: "rgba(95,163,222,0.6)",
                display: this.state.showQQ ? "" : "none",
            }}>
                <div style={{height: "16%"}}>
                    <CloseOutlined style={{position: "absolute", right: "4px", top: "4px", color: "white"}} onClick={() => {
                        this.setState({
                            showQQ: false,
                        })
                    }}/>
                </div>
                <div style={{height: "80%", cursor: "pointer"}} onClick={() => {
                    this.handleQQ()
                }}>
                    <QqOutlined style={{fontSize: "60px", marginLeft: "10px", color: "rgba(255,255,255,1)"}}/>
                    <div style={{width: "100%", textAlign: "center", color: "white"}}>
                        <span>QQ客服</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default QQContact