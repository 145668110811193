import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import BOWEN from "./picture/BOWENSHI.jpg";
import LU from "./picture/LUHUANG.jpg";
import RUOCHENG from "./picture/RUOCHENGWANG.jpg";
import LIU from "./picture/ZIMOLIU.png";

class Team extends React.Component {
    constructor(props) {
        super(props);
        // 鼠标事件属性
        this.state = {label: 0}
    }

    // 鼠标事件
    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                label: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                label: label
            })
        }, 100)
    }

    // pc端团队
    loadingProfileTags(ProfileContent, dom) {
        let curLabel = this.state.label;
        let Profiles = [];
        ProfileContent.forEach(function (Profile) {
            let eleLabel = Profile.listLabel;
            if (eleLabel % 2 === 1) {
                let element = (
                    <div className={curLabel === eleLabel ? "team-profile-back" : "team-profile-front"}
                         onMouseEnter={dom.MouseEnter.bind(dom, eleLabel)} onMouseLeave={dom.MouseLeave.bind(dom, 0)}
                    >
                        <div className={curLabel === eleLabel ? "team-profile-back-image" : "team-profile-front-image"}>
                            <img src={Profile.image} alt={""} style={{width: "250px", height: "250px"}}/>
                        </div>
                        <div
                            className={curLabel === eleLabel ? "team-profile-back-content" : "team-profile-front-content"}>
                            <div
                                className={curLabel === eleLabel ? "team-profile-back-content-heading" : "team-profile-front-content-heading"}>
                                {Profile.heading}
                            </div>
                            <div
                                className={curLabel === eleLabel ? "team-profile-back-content-title" : "team-profile-front-content-title"}>
                                {Profile.title}
                            </div>
                            <div
                                className={curLabel === eleLabel ? "team-profile-back-content-description" : "team-profile-front-content-description"}>
                                {Profile.description}
                            </div>
                        </div>
                    </div>
                );
                Profiles.push(element);
            } else {
                let element = (
                    <div>
                        <div style={{width: 250, height: 250, background: "rgba(71,105,142,0.2)"}}>
                        </div>
                    </div>
                );
                Profiles.push(element);
            }
        })
        return Profiles;
    };

    // mobile团队
    loadingMobileProfileTags(profileContent) {
        let profiles = [];
        profileContent.forEach(function (profile) {
            let element = (
                <div className="mobile-background-team-profile">
                    <div className="mobile-background-team-profile-title">
                        <div className="mobile-background-team-profile-title-sign">
                            <img src={profile.bCoin} alt={""} style={{borderRadius: "50%", height: "260px", width: "260px"}}/>
                        </div>
                    </div>
                    <div className="mobile-background-team-profile-content">
                        <div className={"mobile-background-team-profile-title-content"}>
                            {profile.profileTitle}
                        </div>
                        <div className={"mobile-background-team-profile-position"}>
                            {profile.profilePosition}
                        </div>
                        <div className={"mobile-background-team-profile-description"}>
                            {profile.profileDes}
                        </div>
                    </div>
                </div>
            );
            profiles.push(element);
        })
        return profiles;
    };

    render() {
        let profileContent = [
            {
                listLabel: 1,
                image: BOWEN,
                heading: "施博文",
                title: "总经理",
                description: "前后台全栈技术专家，具有多个海内外技术项目研发经历，亚利桑那州立大学商业数据分析硕士。",
            },
            {
                listLabel: 2,
            },
            {
                listLabel: 3,
                image: RUOCHENG,
                heading: "王若成",
                title: "副总经理、产品总监",
                description: "数字化运营权威，具备多年企业数字化推广运营以及大规模自媒投放分析经验。",
            },
            {
                listLabel: 4,
            },
            {
                listLabel: 4,
            },
            {
                listLabel: 5,
                image: LU,
                heading: "黄璐",
                title: "首席设计师",
                description: "视觉设计、企业自媒体运营资深专家，具备多年企业形象塑造及文化推广经历。",
            },
            {
                listLabel: 6,
            },
            {
                listLabel: 7,
                image: LIU,
                heading: "刘子墨",
                title: "数字经济资深顾问",
                description: "任美国亚利桑那州立政府经济学家，专攻数字经济，提供大数据科学解决方案。",
            },
        ]


        let isMobileL = this.props.isMobileL;
        let profileContentMobile = [
            {
                profileTitle: "施博文",
                profilePosition: "总经理",
                profileDes: "前后台全栈技术专家，具有多个海内外技术项目研发经历，亚利桑那州立大学商业数据分析硕士。",
                profileLabel: 1,
                bCoin: BOWEN,
            },
            {
                profileTitle: "王若成",
                profilePosition: "副总经理、产品总监",
                profileDes: "数字化运营权威，具备多年企业数字化推广运营以及大规模自媒投放分析经验。",
                profileLabel: 2,
                bCoin: RUOCHENG,
            },
            {
                profileTitle: "黄璐",
                profilePosition: "首席设计师",
                profileDes: "视觉设计、企业自媒体运营资深专家，具备多年企业形象塑造及文化推广经历。",
                profileLabel: 3,
                bCoin: LU,
            },
            {
                profileTitle: "刘子墨",
                profilePosition: "数字经济资深顾问",
                profileDes: "任美国亚利桑那州立政府经济学家，专攻数字经济，提供大数据科学解决方案。",
                profileLabel: 4,
                bCoin: LIU,
            },
        ]

        if (!isMobileL) {
            return (
                <div className="five">
                    <div className="Team">
                        <ScrollOverPack playScale={0.2} id="" className="content-wrapper page">
                            <TweenOne
                                key="image"
                                className="image4 image-wrapper"
                                animation={{y: 0, ease: 'easeOutQuad',}}
                            />
                            {/*<div style={{height: "100%", width: "100%", background: "rgba(0, 0, 0)", position: "absolute", top: 0}}/>*/}
                            <QueueAnim
                                className="text-wrapper"
                                key="text"
                                type="bottom"
                                leaveReverse
                            >
                                <h2 key="h2">管理团队</h2>
                            </QueueAnim>
                            <div className="brand-item-team">
                                <QueueAnim type="bottom" duration={1000} delay={100}>
                                    <div key={"item-anim"} className="brand-item-team-container">
                                        {this.loadingProfileTags(profileContent, this)}
                                    </div>
                                </QueueAnim>
                            </div>
                        </ScrollOverPack>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mobile-five">
                    <div className="mobile-team">
                        <div className="content-wrapper page">
                            <div className="text-wrapper">
                                <div className="text-wrapper-cover">
                                    <h2 key="h2">团队成员介绍</h2>
                                    <p key="p">
                                        精英团队为您呈现尖端产品
                                    </p>
                                </div>
                            </div>
                            <div className={"mobile-team-info"}>
                                <div>
                                    {this.loadingMobileProfileTags(profileContentMobile)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Team;
