import React, {Component} from 'react';
import {Header} from "antd/lib/layout/layout";
import {Button, Dropdown, Menu} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";
import MenuSelector from "./MenuSelector";
import "./index.css"
import 'antd/dist/antd.css';
import Trans from './picture/transIcon.svg'
import IconConvertor from "./config/IconConvertor";
import JUGULogo from "./picture/JUGULogo.svg";
import JUGUWORD from "./picture/JUGUWORD.png";

export default class PageHeader extends Component {
    langType = (type) => {
        localStorage.setItem('lang_type', type);
        window.location.reload()
    };

    handleLogin = () => {
        let login = window.localStorage.getItem("userInfo");
        if (login) {
            return (
                <>
                    <div className={"login-box"}
                         onClick={() => {
                             window.localStorage.removeItem("userInfo");
                             window.location.href = '/login';
                         }}
                    >
                        {login + " 退出登录"}
                    </div>
                    {/*<div className={"login-box"}*/}
                    {/*     onClick={() => {window.location.href = '/upload/info'}}*/}
                    {/*>*/}
                    {/*    {"发布信息"}*/}
                    {/*</div>*/}
                </>
            )
        } else {
            return (
                <div className={"login-box"}
                     onClick={() => {window.location.href = '/login'}}
                >
                    {"注册 / 登录"}
                </div>
            )
        }
    }

    render() {
        let needTopBG = this.props.needTopBG;
        let isMobileL = this.props.isMobileL;

        const languageMenu = (
            <Menu style={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "rgba(30, 67, 130, 0)"}}>
                <Menu.Item className={"language-item"} key="1" onClick={() => this.langType("zh_CN")}>
                    {intl.get("CHINESE")}
                </Menu.Item>
                <Menu.Item className={"language-item"} key="2" onClick={() => this.langType("en_US")}>
                    {intl.get("ENGLISH")}
                </Menu.Item>
            </Menu>
        );

        let needSMenu = this.props.needSMenu;

        if (isMobileL) {
            return (
                <div className={"mobile-top-header"}>
                    <div className={"ju-gu-logo-mobile"} style={{marginTop: "1.8vh", marginLeft: "20px"}}>
                        <img src={JUGULogo} alt={""} style={{height: "5vh"}}/>
                        <img src={JUGUWORD} alt={""} style={{height: "4.8vh", marginLeft: "10px"}}/>
                    </div>
                    <Dropdown className={"mobile-menu-top"} trigger={['click']}
                              overlay={<MenuSelector MenuName={needTopBG ? "page-top-menu" : "page-top-menu-clean"}
                                                     isMobileL={true}
                                                     lDSKey={['1']}/>}>
                        <Button icon={<MenuOutlined
                            style={{
                                float: "right",
                                fontSize: "4.2vh",
                                marginRight: "34px",
                                marginTop: "0.4vh",
                                color: "#555755"
                            }}/>}/>
                    </Dropdown>
                </div>
            );
        } else {
            if (needSMenu) {
                return (
                    <div className={"narrow-top-header"}>
                        <Dropdown className={"language-box"} overlay={languageMenu}>
                            <Button icon={<IconConvertor svg={Trans} size={"25px"} style={{fontSize: "20px"}}/>}/>
                        </Dropdown>
                        <Dropdown className={"narrow-menu-top"} trigger={['click']}
                                  overlay={<MenuSelector MenuName={needTopBG ? "page-top-menu" : "page-top-menu-clean"}
                                                         lMode={"horizontal"}
                                                         lTheme={"dark"}
                                                         isCollapsed={true}
                                                         useSliderMenu={false} lDSKey={['1']} needSMenu={needSMenu}/>}>
                            <Button icon={<MenuOutlined/>}>
                                菜单
                            </Button>
                        </Dropdown>
                    </div>
                );
            } else {
                return (
                    <Header className={needTopBG ? "top-header" : "top-header-clean"}>
                        <div className="logo-header"
                            // toDo ICP
                            onClick={() => {window.location.href = "/"}}
                            //
                        >
                            <img className={"App-logo"} src={JUGULogo} alt={""} style={{width: "50px", marginTop: "15px", marginLeft: "15px"}}/>
                            <img src={JUGUWORD} alt={""} style={{width: "70px", marginTop: "18px", marginLeft: "4PX"}}/>
                        </div>
                        <div className={"top-header-right"}>
                            {this.handleLogin()}
                            <Dropdown className={needTopBG ? "language-box" : "language-box-clean"} overlay={languageMenu}>
                                <Button icon={<IconConvertor svg={Trans} size={"25px"} style={{fontSize: "20px"}}/>}/>
                            </Dropdown>
                        </div>
                        <MenuSelector MenuName={needTopBG ? "page-top-menu" : "page-top-menu-clean"}
                                      lMode={"horizontal"}
                                      lTheme={"dark"}
                                      isCollapsed={true}
                                      useSliderMenu={false} lDSKey={['1']} needSMenu={needSMenu}/>
                    </Header>
                );
            }
        }
    }
}