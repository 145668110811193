import React from 'react';
import '../contact.css'
import {MailOutlined, MobileOutlined, QqOutlined, WechatOutlined} from "@ant-design/icons";
import {Popover} from "antd";

class ContactTopCover extends React.Component {
    loadingContactTag(isMobileL, color, colorBK, colorCover, content, Tag) {
        if (!isMobileL) {
            return (
                <div className={"co-op-contact-tag"}>
                    <div className={"co-op-contact-tag-bk"}
                         style={{background: colorBK}}>
                        <Tag style={{fontSize: '2vw', color: 'white', lineHeight: "3.3vw"}}/>
                    </div>
                    <div style={{
                        color: color,
                        fontSize: "1vw",
                        marginTop: "0.5vw",
                        fontWeight: "bold"
                    }}>{content}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"mobile-co-op-contact-tag"}>
                    <Popover placement={"bottom"}
                             content={<div className={"mobile-contact-card-click"}
                                           style={{background: colorCover}}>{content}</div>}
                             trigger={"click"}
                    >
                        <div className={"mobile-co-op-contact-tag-bk"}
                             style={{background: colorBK}}>
                            <Tag style={{
                                fontSize: '80px',
                                color: 'white',
                                lineHeight: "150px"
                            }}/>
                        </div>
                    </Popover>
                </div>
            );
        }
    }

    render() {
        let isMobileL = this.props.isMobileL;
        return (
            <div className={isMobileL ? "mobile-top-page" : "top-page"}>
                <div className={isMobileL ? "mobile-top-page-des" : "top-page-des"}>
                    <div className={isMobileL ? "mobile-co-op" : "co-op"}
                         style={isMobileL ? {width: "51%"} : {width: "41vw"}}>
                        <div className={isMobileL ? "mobile-co-op-line" : "co-op-line"}
                             style={{float: "right", textAlign: "right", color: "#154597"}}>合作
                        </div>
                        <div className={isMobileL ? "mobile-co-op-line" : "co-op-line"}
                             style={{float: "right", textAlign: "right", color: "#154597"}}>创造共赢
                        </div>
                        <div className={isMobileL ? "mobile-line-border" : "line-border"}
                             style={{float: "right", textAlign: "right", color: "#154597"}}>
                            <div className={isMobileL ? "mobile-co-op-border" : "co-op-border"}
                                 style={isMobileL ? {
                                     float: "right",
                                     textAlign: "right",
                                     borderBottom: "5px solid #154597"
                                 } : {float: "right", textAlign: "right", borderBottom: "0.4vw solid #154597"}}/>
                        </div>
                    </div>
                    <div className={isMobileL ? "mobile-co-op" : "co-op"}
                         style={isMobileL ? {width: "49%", paddingLeft: "22px"} : {
                             width: "39vw",
                             paddingLeft: "1.4vw"
                         }}>
                        <div className={isMobileL ? "mobile-co-op-line" : "co-op-line"}
                             style={{float: "left", textAlign: "left", color: "#5c5c5c"}}>携手
                        </div>
                        <div className={isMobileL ? "mobile-co-op-line" : "co-op-line"}
                             style={{float: "left", textAlign: "left", color: "#5c5c5c"}}>共筑未来
                        </div>
                        <div className={isMobileL ? "mobile-line-border" : "line-border"}
                             style={{float: "left", textAlign: "left", color: "#5c5c5c"}}>
                            <div className={isMobileL ? "mobile-co-op-border" : "co-op-border"}
                                 style={isMobileL ? {
                                     float: "left",
                                     textAlign: "left",
                                     borderBottom: "5px solid #5c5c5c"
                                 } : {float: "left", textAlign: "left", borderBottom: "0.4vw solid #5c5c5c"}}/>
                        </div>
                    </div>
                </div>
                <div className={isMobileL ? "mobile-co-op-contact" : "co-op-contact"}>
                    {this.loadingContactTag(isMobileL, "#0e275c", "#154597", "rgba(21,69,151,0.5)", "13916261628", MobileOutlined)}
                    {this.loadingContactTag(isMobileL, "#0e275c", "#154597", "rgba(21,69,151,0.5)", "jugu-mega", WechatOutlined)}
                    {this.loadingContactTag(isMobileL, "white", "#5c5c5c", "rgba(92, 92, 92, 0.5)", "640715376@qq.com", MailOutlined)}
                    {this.loadingContactTag(isMobileL, "white", "#5c5c5c", "rgba(92, 92, 92, 0.5)", "640715376", QqOutlined)}
                </div>
            </div>
        );
    }
}

export default ContactTopCover;