import React, {Component} from "react";
import './static/page.less'
import bgImage from './picture/bkgrd.svg';
import bgShadow from './picture/bgShadow.png';
import QueueAnim from "rc-queue-anim";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";

class BKContent extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="bg-wrapper">
                <div className="bg-wrapper-bg">
                    <div className="bg-wrapper-area">
                        <div className="bg-wrapper-img-area">
                            <img className="bg-wrapper-img" src={bgImage}/>
                            <img className="bg-wrapper-imgS" src={bgShadow}/>

                        </div>
                        <ScrollOverPack playScale={0.1} className="bg-wrapper-text-area-about">
                            <QueueAnim animConfig={{translateX: [0, 200]}} duration={1000} delay={100}>
                                <div key="text" className="bg-wrapper-text-area-about-content">
                                    <h2 key="h2" style={{fontWeight: "bolder"}}>关于巨罟</h2>
                                    <p>
                                        {/*上海巨罟大数据科技有限公司是一家勇于创新，敢于挑战，追求完美，致力于互联网技术服务、开发及应用，提供一站式互联网解决方案的公司，创立于2021年4月。*/}
                                        {/*// toDo ICP*/}
                                        上海巨罟大数据科技有限公司是一家勇于创新，敢于挑战，追求完美，致力打造为企业和用户提供一个发布互联网技术服务、开发及应用等信息合作平台的公司，创立于2021年4月。
                                        {/**/}
                                    </p>
                                    <p>
                                        {/*我们秉承着从客户的行业特性与市场定位出发，从客户需求切入，融入专业的策划设计，提供针对性的技术支持，为客户打造尖刀产品，提升客户价值。*/}
                                        {/*我们的业务包括网站建设，App开发，数据分析，新媒体运营等服务，满足个性化的需求与定制功能，同时为客户打通前后端壁垒，构造有生命力的一体化产品。我们坚持以高效的交付速度、细颗粒度的质量把控，回馈以客户最优的服务品质。*/}
                                        {/*// toDo ICP*/}
                                        平台服务秉承着从客户的行业特性与市场定位出发，从客户需求切入，融入专业的策划设计，提供针对性的技术支持，为客户打造尖刀产品，提升客户价值。
                                        平台信息包括网站建设，App开发，数据分析，新媒体运营等服务，满足个性化的需求与定制功能。我们秉承让信息更流畅、合作更顺利的意愿，为所有上海巨罟大数据科技有限公司的用户提供信息便利！
                                        {/**/}
                                    </p>
                                </div>
                        </QueueAnim>
                        </ScrollOverPack>

                        <ScrollOverPack playScale={0.15} className="bg-wrapper-text-area-culture">
                            <QueueAnim animConfig={{translateX: [0, -200]}} duration={1000} delay={100} leaveReverse>
                                <div key="text" className="bg-wrapper-text-area-culture-content">
                                    <h2 key="h2" style={{fontWeight: "bolder"}}>我们的文化</h2>
                                    <p>
                                        我们坚持以人为本，诚信务实，团结奋进，共筑正确的价值观。
                                    </p>
                                    <p>
                                        我们坚持以用户为根本，以技术为核心，用户需求是发展原动力，技术创新是第一生产力，对于细节与技术的追求，持之以恒地做好每一件事情，是我们的承诺与执著。
                                    </p>
                                </div>
                            </QueueAnim>
                        </ScrollOverPack>

                        <ScrollOverPack playScale={0.2} className="bg-wrapper-text-area-target">
                            <QueueAnim animConfig={{translateX: [0, 200]}} duration={1000} delay={100}>
                                <div key="text" className="bg-wrapper-text-area-target-content">
                                    <h2 key="h2" style={{fontWeight: "bolder"}}>我们的愿景</h2>
                                    <p>
                                        我们不断提升自己的境界和技术，深入千行百业，聚焦客户核心业务，希望用技术赋能业务，推动数智化升级，构建智能化的生态体系。
                                    </p>
                                    <p>
                                        我们是怀揣梦想的生力军，也是脚踏实地的实干家，相信客户可以通过互联网产品提升竞争力以及拓展更多业务，也坚信我们可以满足客户的需求，提供对应的解决方案，创造共赢。
                                    </p>
                                </div>
                            </QueueAnim>
                        </ScrollOverPack>
                    </div>
                </div>
            </div>
        )
    }

}

export default BKContent;
