import React from 'react';
import {Card} from "antd";
import QueueAnim from "rc-queue-anim";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import {BatchItemsUrl, sendItemRequest} from "../../util/RequestHelper";
import configName from "../../locales/zh-CN";

const requestTags = {
    ai: 'ai',
    app: 'app',
    service: 'service',
    wechat: 'wechat',
    software: 'software',
}

class ServiceTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: 0,
            newsTitle: '/picture/demo1.jpg',
            itemData: "",
        }
    }

    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                label: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                label: label
            })
        }, 100)
    }

    componentDidMount() {
        sendItemRequest(this, this.loadHomeRequestData(), BatchItemsUrl());
    }

    loadHomeRequestData() {
        let data = new FormData();
        data.append('ITEM_NAME', requestTags.ai);
        data.append('ITEM_NAME', requestTags.app);
        data.append('ITEM_NAME', requestTags.service);
        data.append('ITEM_NAME', requestTags.wechat);
        data.append('ITEM_NAME', requestTags.software);
        data.append("L0CATION_PAGE", configName.HOME_PAGE);
        return data;
    }

    loadingServiceTags(cardContent, dom) {
        let curLabel = this.state.label;
        let services = [];
        cardContent.forEach(function (service) {
            let eleLabel = service.cardLabel;
            let element = (<Card
                className={curLabel === eleLabel ? "card-service-enter" : "card-service"}
                title={<img className={curLabel === eleLabel ? "title-sign-after" : "title-sign-before"}
                            src={curLabel === eleLabel ? service.aCoin : service.bCoin} alt={""}/>}
                onClick={() => {window.location.href = service.targetUrl}}
                onMouseEnter={dom.MouseEnter.bind(dom, eleLabel)} onMouseLeave={dom.MouseLeave.bind(dom, 0)}>
                <Card.Meta title={<div
                    style={curLabel === eleLabel ? {color: "#2280c4"} : {color: "black"}}>{service.cardTitle}</div>}
                           description={<div
                               style={curLabel === eleLabel ? {color: "black"} : {color: "gray"}}>{service.cardDes}</div>}/>
            </Card>);
            services.push(element);

        })
        return services;
    }

    loadingMobileServiceTags(cardContent) {
        let services = [];
        cardContent.forEach(function (service) {
            let element = (
                <div className={"mobile-card-service"} onClick={() => {window.location.href = service.targetUrl}}>

                    <div className={"mobile-card-service-title"}>
                        <div className={"mobile-title-sign"}>
                            <img src={service.bCoin} alt={""}/>
                        </div>
                    </div>
                    <div className={"mobile-card-description"}>
                        <div className={"mobile-card-title-content"}>{service.cardTitle}</div>
                        <div className={"mobile-card-des-content"}>
                            {service.cardDes}
                        </div>
                    </div>
                </div>
            );
            services.push(element);
        })
        return services;
    }

    render() {
        let isMobileL = this.props.isMobileL;
        let requestData = this.state.itemData;
        let serviceInfo = requestData ? requestData.get(requestTags.service) : new Map();
        let appInfo = requestData ? requestData.get(requestTags.app) : new Map();
        let aiInfo = requestData ? requestData.get(requestTags.ai) : new Map();
        let softInfo = requestData ? requestData.get(requestTags.software) : new Map();
        let wechatInfo = requestData ? requestData.get(requestTags.wechat) : new Map();

        let cardContent = [
            {
                cardTitle: softInfo.get("title"),
                cardDes: softInfo.get("content"),
                cardLabel: 1,
                bCoin: "/picture/" + softInfo.get("coverFrontName"),
                aCoin: "/picture/" + softInfo.get("coverBackName"),
                targetUrl: "/services#service-web-info"
            },
            {
                cardTitle: appInfo.get("title"),
                cardDes: appInfo.get("content"),
                cardLabel: 2,
                bCoin: "/picture/" + appInfo.get("coverFrontName"),
                aCoin: "/picture/" + appInfo.get("coverBackName"),
                targetUrl: "/services#service-app-info"
            },
            {
                cardTitle: wechatInfo.get("title"),
                cardDes: wechatInfo.get("content"),
                cardLabel: 3,
                bCoin: "/picture/" + wechatInfo.get("coverFrontName"),
                aCoin: "/picture/" + wechatInfo.get("coverBackName"),
                targetUrl: "/services#service-wechat-info"
            },
            {
                cardTitle: aiInfo.get("title"),
                cardDes: aiInfo.get("content"),
                cardLabel: 4,
                bCoin: "/picture/" + aiInfo.get("coverFrontName"),
                aCoin: "/picture/" + aiInfo.get("coverBackName"),
                targetUrl: "/services#service-machine-info"
            }
        ]

        if (isMobileL) {
            return (
                <div className={"mobile-service-layer"}>
                    <div className="mobile-level-title">
                        <img className="mobile-title-logo-service" src={serviceInfo.get("coverFrontName")} alt=""/>
                        <div className="mobile-title-content" id={"home-core-title"}>{serviceInfo.get("content")}</div>
                    </div>
                    <div style={{height: "1450px"}}>
                        {this.loadingMobileServiceTags(cardContent)}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="level-two">
                    <div className={"service-empty-layer"}/>
                    <ScrollOverPack playScale={0.1}>
                        <QueueAnim type={"bottom"} duration={1000} delay={100}>
                            <div key={"service-card-anim"} className={"home-cards-layer"}>
                                <div className="level-title">
                                    <img className="title-logo-service" src={"/picture/" + serviceInfo.get("coverFrontName")} alt=""/>
                                    <div className="title-content" id={"home-core-title"}>{serviceInfo.get("content")}</div>
                                    {/*<span className="title-content" id={"home-service-title"}>服务领域</span>*/}
                                </div>
                                <div className={"service-collections"}>
                                    {this.loadingServiceTags(cardContent, this)}
                                </div>
                            </div>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            )
        }
    }
}

export default ServiceTag;
