import React from "react";
import "./serviceitem.less"
import {CaretRightFilled} from '@ant-design/icons';
import {Carousel, WingBlank} from 'antd-mobile';

class ServiceFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {flowLabel: 0}
    }


    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                flowLabel: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                flowLabel: label
            })
        }, 100)
    }


    render() {
        const flowLabel = this.state.flowLabel
        let isMobileL = this.props.isMobileL;
        if (isMobileL) {
            return (
                <div className={"mobile-service-flow"}>
                    <div className="mobile-service-flow-title">服务流程</div>
                    <div className="mobile-service-flow-title-sub">全方位质量保障 传承匠人精神</div>
                    <div className={"mobile-service-flow-show"}>
                        <div className={"mobile-service-flow-show-inside"}>
                            <div className={"mobile-service-flow-step"}>
                                需求沟通
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                项目确立
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                签订合同
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                项目迭代
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                功能测试
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                客户验收
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                需求交付
                            </div>
                            <div className="mobile-service-flow-sign">
                                <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                            </div>
                            <div className={"mobile-service-flow-step"}>
                                运维管理
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="service-flow">
                    <div className="service-flow-title">服务流程</div>
                    <div className="service-flow-title-sub">全方位质量保障 传承匠人精神</div>
                    <div className="service-flow-content">
                        <div className={flowLabel === 1 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 1)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            需求沟通
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 2 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 2)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            项目确立
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 3 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 3)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            签订合同
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 4 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 4)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            项目迭代
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 5 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 5)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            功能测试
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 6 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 6)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            客户验收
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 7 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 7)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            需求交付
                        </div>
                        <div className="service-flow-sign">
                            <CaretRightFilled style={{fontSize: '50px', color: '#3f6ba0'}}/>
                        </div>
                        <div className={flowLabel === 8 ? "service-flow-step-back" : "service-flow-step"}
                             onMouseEnter={this.MouseEnter.bind(this, 8)}
                             onMouseLeave={this.MouseLeave.bind(this, 0)}>
                            运维管理
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ServiceFlow;