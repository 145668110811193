import React from 'react';
import "./index.css";
import {isMobile} from "../../util/MobileMgr";
import {findZoom} from "../../util/WindowHelper";
import {Modal, Spin} from "antd";
import PageHeader from "../../PageHeader";
import {getRandomArr, imgHeader, InfoTypeE} from "./constants";
import InfoCard from "./components/InfoCard";
import TopInfoCard from "./components/TopInfoCard";
import RecInfoCard from "./components/RecInfoCard";
import {sendGetRequest} from "../../util/RequestHelper";

class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needTopBG: true,
            needSMenu: this.setInitMenu(),
            zoomSize: findZoom(1480),
            isMobile: isMobile(),
            isLoaded: false,
            info: [],
            topInfo: [],
            recOneL: [],
            recTwoL: [],
            infoD: null,
            isModalOpen: false,
            isInfoD: false,
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    componentDidMount() {
        this.findAdv();
    }

    findAdv = () => {
        sendGetRequest('/api/findAdv')
        // sendGetRequest('/findAdv')
            .then((res) => {
                if (res.success) {
                    let data = res.responseData;
                    // console.log(data)
                    if (data.length > 0) {
                        let TopInfoList = data.filter((it) => {return it.advTop});
                        let RecOneInfoList = data.filter((it) => {return it.advType === InfoTypeE.WEB});
                        let RecTwoInfoList = data.filter((it) => {return it.advType === InfoTypeE.OM});
                        this.setState({
                            info: data,
                            topInfo: TopInfoList,
                            recOneL: RecOneInfoList,
                            recTwoL: RecTwoInfoList,
                            isLoaded: true,
                        })
                    }
                }
            })
    }

    switchInfo = (info) => {
        window.scrollTo(0, 0);
        this.setState({
            isInfoD: true,
            infoD: info,
        })
    }

    backInfo = () => {
        window.scrollTo(0, 0);
        this.setState({
            isInfoD: false,
            infoD: null,
        })
    }

    //
    loadInfoList = (list) => {
        let ui = [];
        let len = list.length;
        if (len > 0) {
            list.forEach((info, index) => {
                ui.push(
                    <InfoCard info={info} switchInfo={this.switchInfo}/>
                )
            })
        }
        return ui;
    }

    //
    loadTopInfoList = (list) => {
        let ui = [];
        let len = list.length;
        if (len > 0) {
            list.forEach((info, index) => {
                ui.push(
                    <TopInfoCard info={info} index={index} switchInfo={this.switchInfo}/>
                )
            })
        }
        return ui;
    }

    //
    loadRecList = (title, list) => {
        // console.log(title, list)
        let ui = [];
        let uii = [];
        let len = list.length;
        if (len > 0) {
            list.forEach((info, index) => {
                uii.push(
                    <RecInfoCard info={info} switchInfo={this.switchInfo}/>
                )
            })
        }
        ui.push(
            <>
                <div className={"side-title"}>
                    {title}
                </div>
                <div className={"side-info"}>
                    {uii}
                </div>
            </>
        )
        return ui;
    }

    loadInfo = () => {
        let info = this.state.info;
        let topInfo = this.state.topInfo;
        let recOneL = this.state.recOneL;
        let recTwoL = this.state.recTwoL;
        return (
            <>
                <div className={"info-comb-left"}>
                    <div className={"info-title"}>
                        <div className={"info-title-text"}>
                            {"置顶信息可使成交率提高5倍！"}
                        </div>
                    </div>
                    <div className={"info-list"}>
                        {this.loadInfoList(info)}
                    </div>
                </div>
                <div className={"info-comb-right"}>
                    <div className={"top-info"}>
                        <div className={"side-title"}>
                            {"置顶信息"}
                        </div>
                        <div className={"side-info"}>
                            {this.loadTopInfoList(topInfo)}
                        </div>
                    </div>
                    <div className={"top-info"}>
                        {this.loadRecList("软件开发", getRandomArr(recOneL, 8))}
                    </div>
                    <div className={"top-info"}>
                        {this.loadRecList("运维安全", getRandomArr(recTwoL, 8))}
                    </div>
                </div>
            </>
        )
    }

    handlePhone = (phone) => {
        if (typeof phone === "string") {
            return phone.slice(0, 8) + '****';
        }
    }

    showModal = () => {
        this.setState({
            isModalOpen: true
        })
    };

    handleOk = () => {
        this.setState({
            isModalOpen: false
        })
    };

    loadInfoD = () => {
        let info = this.state.infoD;
        let isModalOpen = this.state.isModalOpen;
        return (
            <>
                <Modal style={{zIndex: 99}} title="联系方式" visible={isModalOpen} closable={false}
                       cancelText={"关闭"}  okText={"确定"}
                       onCancel={() => this.handleOk()} onOk={() => this.handleOk()}>
                    <p style={{fontSize: "16px", color: "red"}}>
                        <strong>
                            {info.advPersonPhone}
                        </strong>
                    </p>
                    <p>{"联系时请说是在上海巨罟大数据科技有限公司看到的, 谢谢"}</p>
                </Modal>
                <div className={"info-d-left"}>
                    <div className={"info-d-back"}
                         onClick={() => {this.backInfo()}}
                    >
                        {"< 返回上级"}
                    </div>
                    <div className={"info-d-title"}>
                        {info.advTitle}
                    </div>
                    <div className={"info-d-top"}>
                        <div className={"info-d-top-left"}>
                            <img className={"info-d-cover"} src={imgHeader + info.advCover} alt={""}/>
                        </div>
                        <div className={"info-d-top-right"}>
                            <div className={"info-d-contact"}>
                                <div className={"info-d-contact-left"}>{"联系人："}</div>
                                <div className={"info-d-contact-right"}>{info.advPerson}</div>
                            </div>
                            <div className={"info-d-contact"}>
                                <div className={"info-d-contact-left"}>
                                    {"电话："}
                                </div>
                                <div className={"info-d-contact-right"} style={{color: "red"}}>
                                    <strong>
                                        {this.handlePhone(info.advPersonPhone)}
                                    </strong>
                                </div>
                                <div className={"tel-btn"} onClick={() => {
                                    this.showModal()
                                }}>
                                    {"点击查看完整号码"}
                                </div>
                            </div>
                            <div className={"info-d-tip"}>
                                <strong>
                                    {"上海巨罟大数据科技有限公司提醒您："}
                                </strong>
                                <span>
                                    {"让你提前汇款，或者价格明显低于市价，均有骗子嫌疑，不要轻易相信。"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={"info-d-center"}>
                        <div className={"info-d-center-title"}>
                            {"信息详情"}
                        </div>
                        <div className={"info-d-center-text"}>
                            {info.advCt}
                        </div>
                        <div className={"info-d-center-text"}>
                            {"联系我时，请说是在上海巨罟大数据科技有限公司看到的，谢谢！"}
                        </div>
                        <div style={{margin: "20px"}}>
                            <img className={"info-d-center-cover"} src={imgHeader + info.advCover} alt={""}/>
                        </div>
                    </div>
                    <div className={"info-d-more"}>
                        <a href={"/services"}>
                            {"查看更多"}
                        </a>
                    </div>
                </div>
                <div className={"info-d-right"}>
                    <div className={"info-d-user"}>
                        <img className={"user-img"} src={imgHeader + "user.jpg"} alt={""}/>
                        <div className={"user-name"}>
                            {info.advPerson}
                        </div>
                        <div className={"user-level"}>
                            <div className={"user-level-left"}>
                                {"用户级别："}
                            </div>
                            <div className={"user-level-right"}>
                                {info.userLevel}
                            </div>
                        </div>
                        <div className={"user-level"}>
                            <div className={"user-level-left"}>
                                {"信用等级："}
                            </div>
                            <div className={"user-level-right"}>
                                <img src={imgHeader + "credit.gif"} alt={''}/>
                            </div>
                        </div>
                    </div>
                    <div className={"info-d-type"}>
                        <div className={"info-d-type-title"}>
                            <span>{"相关分类"}</span>
                        </div>
                        <div className={"info-d-type-txt"}>
                            <li>{info.advTypTxt}</li>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        let needTopBG = this.state.needTopBG;
        let needSMenu = this.state.needSMenu;
        let isMobileL = this.state.isMobile;
        let zoomL = this.state.zoomSize;

        if (!isMobileL) {
            let isLoaded = this.state.isLoaded;
            let isInfoD = this.state.isInfoD;
            return (
                <>
                    <div style={{
                        textAlign: "center",
                        background: "rgb(224,224,224)",
                        height: "100vh",
                        position: "fixed",
                        width: "100%",
                        display: isLoaded ? "none" : ""
                    }}>
                        <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                    </div>
                    <div className={"info-page"}>
                        <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                        {
                            isLoaded &&
                            <div className={"info-comb-wrap"}
                                 style={{zoom: zoomL, display: isLoaded ? "" : "none"}}
                            >
                                {isInfoD ? this.loadInfoD() : this.loadInfo()}
                            </div>
                        }
                    </div>
                </>
            )
        } else {
            let isLoaded = this.state.isLoaded;
            let isInfoD = this.state.isInfoD;
            return (
                <>
                    <div style={{
                        textAlign: "center",
                        background: "rgb(224,224,224)",
                        height: "100vh",
                        position: "fixed",
                        width: "100%",
                        display: isLoaded ? "none" : ""
                    }}>
                        <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                    </div>
                    <div className={"info-page"}>
                        <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                        {
                            isLoaded &&
                            <div className={"info-comb-wrap"}
                                 style={{zoom: zoomL, display: isLoaded ? "" : "none"}}
                            >
                                {isInfoD ? this.loadInfoD() : this.loadInfo()}
                            </div>
                        }
                    </div>
                </>
            )
        }
    }
}

export default Info;