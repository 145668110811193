import React from "react";
import "./serviceitem.less"
import {Swiper, Pagination} from "swiper";
import SwiperCore, {Autoplay, Controller} from 'swiper/core';
import "swiper/swiper-bundle.css";
import ServiceFlow from "./serviceFlow";

import {
    FileImageOutlined,
    RocketOutlined,
    ReconciliationOutlined,
    CrownOutlined,
    WechatFilled,
    EditFilled,
    AppstoreFilled
} from '@ant-design/icons';
import ModelingService from "./ModelingService";

SwiperCore.use([Autoplay]);
SwiperCore.use([Controller]);


class ServiceItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0
        };
        this.setCurrentIndex = this.setCurrentIndex.bind(this);

        this.state = {
            dataList: [
                {name: '策略定位', checked: false,},
                {name: '视觉设计', checked: false,},
                {name: '系统开发', checked: false,},
                {name: '算法实现', checked: false,},
                {name: '后台管理', checked: false,}
            ]
        };
    }

    setCurrentIndex(event) {
        this.setState({
            currentIndex: parseInt(event.currentTarget.getAttribute('index'), 10)
        })
    }

    itemClick(item, index) {
        let list = this.state.dataList;
        list.forEach(item => {
            item.checked = false;
        })
        list[index].checked = true;
        this.setState({
            dataList: list
        })
    }

    getServiceListElement = () => {
        let curValue = document.getElementsByClassName("swiper-slide-active")[0].innerHTML;
    }

    componentDidMount() {
        // 导航栏
        let swiper1 = new Swiper(".item-web-general-nav", {
            // observer: true,
            // observeParents: true,
            slidesPerView: "6",// 每页显示几个
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            loop: true,
            loopedSlides: 1,
            virtualTranslate: true,
            on: {
                tap: function () {
                    swiper2.slideTo(swiper1.clickedIndex);
                },
            },
        });
        // 内容
        let swiper2 = new Swiper('.item-web-general-list', {
            observer: true,
            observeParents: true,
            paginationClickable: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            speed: 1500,
            loop: true,
            loopedSlides: 1,
            // initialSlide: 0,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
            },
            thumbs: {
                swiper: swiper1,
            },
        });
        swiper1.controller.control = swiper2
        swiper2.controller.control = swiper1

        Swiper.use([Pagination]);
        new Swiper('.mobile-item-web-general-list', {
                observer: true,
                observeParents: true,
                paginationClickable: true,
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                speed: 1500,
                loop: true,
                loopedSlides: 1,
                // initialSlide: 0,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination', // 分页器
                },
            }
        )

        window.addEventListener('list', this.getServiceListElement)
    }

    componentWillUnmount() {
        window.removeEventListener('list', this.getServiceListElement)
    }

    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }

    render() {
        let isMobileL = this.props.isMobileL;
        if (!isMobileL) {
            return (
                <div className="service-items">
                    <div className="item-web-general" id="service-web-info">
                        <div className="item-web-general-layout">
                            <div className="item-web-general-title">五位一体 全局定制</div>
                            <div className="item-web-general-subtitle">以品牌价值和用户体验为导向</div>
                            <div className="swiper-container item-web-general-nav">
                                <div className="swiper-wrapper">
                                    {
                                        this.state.dataList.map((item, index) => {
                                            return (
                                                <div
                                                    className={item.checked ? 'swiper-slide swiper-slide-active' : 'swiper-slide'}
                                                    key={index}
                                                    // onClick={() => this.itemClick(item,index)}
                                                >
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="swiper-container item-web-general-list">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="strategy-image"/>
                                        <div className="item-web-general-content">
                                            <div className="content-title">Strategy-Positioning</div>
                                            <div className="content-title">策略定位</div>
                                            <div className="content-point">——品牌策略分析</div>
                                            <div className="content-point-text">结合行业及企业调性综合战略定位，洞察品牌竞争力</div>
                                            <div className="content-point">——框架规划</div>
                                            <div className="content-point-text">根据需求定制，构建构架及区域布局</div>
                                            <div className="content-point">——响应式布局设计</div>
                                            <div className="content-point-text">多终端适配设计，提供更好的用户体验</div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="visual-image"/>
                                        <div className="item-web-general-content">
                                            <div className="content-title">Visual-Design</div>
                                            <div className="content-title">视觉设计</div>
                                            <div className="content-point">——品牌格局</div>
                                            <div className="content-point-text">视觉设计要与品牌格局相统一，传递品牌的核心价值和文化</div>
                                            <div className="content-point">——风格设计</div>
                                            <div className="content-point-text">整体页面风格设计，实现品牌的视觉定位</div>
                                            <div className="content-point">——用户体验</div>
                                            <div className="content-point-text">以用户为中心的设计理念</div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="system-image"/>
                                        <div className="item-web-general-content">
                                            <div className="content-title">System-Development</div>
                                            <div className="content-title">系统开发</div>
                                            <div className="content-point">——HTML5+React搭建</div>
                                            <div className="content-point-text">更强兼容性，更好的框架搭建</div>
                                            <div className="content-point">——JS+JAVA双语言开发</div>
                                            <div className="content-point-text">拓展性更强，效率更高</div>
                                            <div className="content-point">——积木式模块</div>
                                            <div className="content-point-text">更灵活，更易管理</div>
                                            <div className="content-point">——云服务存储</div>
                                            <div className="content-point-text">更稳定，更快速</div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="algorithm-image"/>
                                        <div className="item-web-general-content">
                                            <div className="content-title">Algorithm-Implementation</div>
                                            <div className="content-title">算法实现</div>
                                            <div className="content-point">——业务算法实现</div>
                                            <div className="content-point-text">结合实际业务算法需求，实现业务算法</div>
                                            <div className="content-point">——AI/机器学习</div>
                                            <div className="content-point-text">处理由用户产生的、数量不断增长的数据</div>
                                            <div className="content-point">——大数据分析</div>
                                            <div className="content-point-text">数据可视化、数据挖掘析</div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="backstage-image"/>
                                        <div className="item-web-general-content">
                                            <div className="content-title">Backstage-Management</div>
                                            <div className="content-title">后台管理</div>
                                            <div className="content-point">——定制管理平台</div>
                                            <div className="content-point-text">后台管理平台进行页面基础修改</div>
                                            <div className="content-point">——便捷操作</div>
                                            <div className="content-point-text">易上手，操作简单</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item-app-general" id="service-app-info">
                        <div className={"app-general-paper"}>
                            <div className="item-app-title-des">引领技术 突破桎梏</div>
                            <div className="item-app-title-des-sub">构建移动双核，引领客户潮流</div>
                            <div className="item-app-general-content-one">
                                <div className="app-image-one"/>
                                <div className="app-content-one-sd"/>
                                <div className="app-content-one">
                                    <div className="app-content-one-title">聚焦核心需求</div>
                                    <div className="app-content-one-subtitle">始终从用户本质的需求出发</div>
                                    <div className="app-content-one-text">
                                        赋予每款APP生命力，创造全新移动端生态平台；<br/>
                                        全视角解析品牌所处行业，站在客户视角思考问题；<br/>
                                        落实差异化特征，拓展多样化应用场景；<br/>
                                        提升用户体验，打造尖端性能。<br/>
                                        {/*为产品发声，形成用户习惯。*/}
                                    </div>
                                </div>
                            </div>
                            <div className="item-app-general-content-two">
                                <div className="app-image-two"/>
                                <div className="app-content-two">
                                    <div className="app-content-two-title">直击痛点 唯快不破</div>
                                    <div className="app-content-two-subtitle">打磨产品，快速迭代</div>
                                    <div className="app-content-two-text">
                                        与时俱进，紧跟技术浪潮；<br/>
                                        使用全新积木化架构，保障项目高效迭代；<br/>
                                        支持前端H5混合开发模式，使用原生编译；<br/>
                                        为您与时间的赛跑赢得先机。
                                    </div>
                                </div>
                            </div>
                            <div className="item-app-general-content-three">
                                <div className="app-image-three"/>
                                <div className="app-content-three">
                                    <div className="app-content-three-title">UI设计</div>
                                    <div className="app-content-three-detail">
                                        <div className="app-content-three-detail-one">
                                            <FileImageOutlined style={{fontSize: '100px', color: '#7f97b4'}}/>
                                            <div className="app-content-three-detail-slogan">视觉设计</div>
                                            <div className="app-content-three-detail-text">把控视觉及图形细节，体现产品的品质感</div>
                                        </div>
                                        <div className="app-content-three-detail-two">
                                            <RocketOutlined style={{fontSize: '100px', color: '#7f97b4'}}/>
                                            <div className="app-content-three-detail-slogan">创新力</div>
                                            <div className="app-content-three-detail-text">以创意为驱动力，为产品赋予新鲜且有新意的体现</div>
                                        </div>
                                        <div className="app-content-three-detail-three">
                                            <ReconciliationOutlined style={{fontSize: '100px', color: '#7f97b4'}}/>
                                            <div className="app-content-three-detail-slogan">风格打造</div>
                                            <div className="app-content-three-detail-text">风格保持整体一致，忠于产品目标和产品方向</div>
                                        </div>
                                        <div className="app-content-three-detail-four">
                                            <CrownOutlined style={{fontSize: '100px', color: '#7f97b4'}}/>
                                            <div className="app-content-three-detail-slogan">用户体验</div>
                                            <div className="app-content-three-detail-text">围绕业务逻辑，切入产品需求，提升用户体验</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item-wechat-general" id="service-wechat-info">
                        <div className="item-wechat-title-des">多维视点 自媒直航</div>
                        <div className="item-wechat-title-des-sub">为您呈现独一无二的感官体验</div>
                        <div className="item-wechat-general-content">
                            <div className="wechat-image-one"/>
                            <div className="wechat-content">
                                <div className="wechat-content-one">
                                    <WechatFilled style={{fontSize: '80px', color: 'white'}}/>
                                    <div className="wechat-content-one-title">微信公众号代运营</div>
                                    <div className="wechat-content-one-text">提供公众号搭建、内容策划、营销运营等一体化微信代运营服务，助力品牌流量闭环</div>
                                </div>
                                <div className="wechat-content-two">
                                    <EditFilled style={{fontSize: '80px', color: 'white'}}/>
                                    <div className="wechat-content-two-title">专业文案设计</div>
                                    <div className="wechat-content-two-text">基于品牌调性和目标客群，统筹整体风格和内容，专业文案设计，传递品牌文化和产品价值
                                    </div>
                                </div>
                                <div className="wechat-content-three">
                                    <AppstoreFilled style={{fontSize: '80px', color: 'white'}}/>
                                    <div className="wechat-content-three-title">微信小程序开发</div>
                                    <div className="wechat-content-three-text">为品牌打造行业垂直深度小程序，支持小程序个性化定制等</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item-machine-general" id="service-machine-info">
                        {/*<div className="item-machine-general-title">*/}
                        {/*    <div className="item-machine-general-title-text">数据建模</div>*/}
                        {/*</div>*/}
                        <div className={"item-machine-general-sum"}>数字科技 放眼未来</div>
                        <div className={"item-machine-general-sum-sub"}>精确制导，统筹全局</div>
                        <div className="item-machine-general-content">
                            <ModelingService/>
                        </div>
                    </div>
                    <ServiceFlow/>
                </div>
            );
        } else {
            return (
                <div className="mobile-service-items">
                    <div className="mobile-item-web-general" id="service-web-info">
                        {/*<div className="mobile-item-web-general-title">*/}
                        {/*    <div className="mobile-item-web-general-title-text">WEB 定制</div>*/}
                        {/*</div>*/}
                        <div className="mobile-item-web-title-des">五位一体 全局定制</div>
                        <div className="mobile-item-web-title-des-sub">以品牌价值和用户体验为导向</div>
                        <div className="swiper-container mobile-item-web-general-list">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="mobile-item-web-general-content">
                                        <div className="mobile-content-title">Strategy-Positioning</div>
                                        <div className="mobile-content-title">策略定位</div>
                                        <div style={{height: "30px", width: "100%"}}/>
                                        <div className="mobile-content-point">——品牌策略分析</div>
                                        <div className="mobile-content-point-text">结合行业及企业调性综合战略定位，洞察品牌竞争力</div>
                                        <div className="mobile-content-point">——框架规划</div>
                                        <div className="mobile-content-point-text">根据需求定制，构建构架及区域布局</div>
                                        <div className="mobile-content-point">——响应式布局设计</div>
                                        <div className="mobile-content-point-text">多终端适配设计，提供更好的用户体验</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="mobile-item-web-general-content">
                                        <div className="mobile-content-title">Visual-Design</div>
                                        <div className="mobile-content-title">视觉设计</div>
                                        <div style={{height: "30px", width: "100%"}}/>
                                        <div className="mobile-content-point">——品牌格局</div>
                                        <div className="mobile-content-point-text">视觉设计要与品牌格局相统一，传递品牌的核心价值和文化</div>
                                        <div className="mobile-content-point">——风格设计</div>
                                        <div className="mobile-content-point-text">整体页面风格设计，实现品牌的视觉定位</div>
                                        <div className="mobile-content-point">——用户体验</div>
                                        <div className="mobile-content-point-text">以用户为中心的设计理念</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="mobile-item-web-general-content">
                                        <div className="mobile-content-title">System-Development</div>
                                        <div className="mobile-content-title">系统开发</div>
                                        <div style={{height: "30px", width: "100%"}}/>
                                        <div className="mobile-content-point">——HTML5+React搭建</div>
                                        <div className="mobile-content-point-text">更强兼容性，更好的框架搭建</div>
                                        <div className="mobile-content-point">——JS+JAVA双语言开发</div>
                                        <div className="mobile-content-point-text">拓展性更强，效率更高</div>
                                        <div className="mobile-content-point">——积木式模块</div>
                                        <div className="mobile-content-point-text">更灵活，更易管理</div>
                                        <div className="mobile-content-point">——云服务存储</div>
                                        <div className="mobile-content-point-text">更稳定，更快速</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="mobile-item-web-general-content">
                                        <div className="mobile-content-title">Algorithm-Implementation</div>
                                        <div className="mobile-content-title">算法实现</div>
                                        <div style={{height: "30px", width: "100%"}}/>
                                        <div className="mobile-content-point">——业务算法实现</div>
                                        <div className="mobile-content-point-text">结合实际业务算法需求，实现业务算法</div>
                                        <div className="mobile-content-point">——AI/机器学习</div>
                                        <div className="mobile-content-point-text">处理由用户产生的、数量不断增长的数据</div>
                                        <div className="mobile-content-point">——大数据分析</div>
                                        <div className="mobile-content-point-text">数据可视化、数据挖掘分析</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="mobile-item-web-general-content">
                                        <div className="mobile-content-title">Backstage-Management</div>
                                        <div className="mobile-content-title">后台管理</div>
                                        <div style={{height: "30px", width: "100%"}}/>
                                        <div className="mobile-content-point">——定制管理平台</div>
                                        <div className="mobile-content-point-text">后台管理平台进行页面基础修改</div>
                                        <div className="mobile-content-point">——智能编辑工具</div>
                                        <div className="mobile-content-point-text">云助手，自动同步更新</div>
                                        <div className="mobile-content-point">——便捷操作</div>
                                        <div className="mobile-content-point-text">易上手，操作简单</div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination" ></div>
                        </div>
                    </div>

                    <div className="mobile-item-app-general" id="service-app-info">
                        <div className={"mobile-app-general-paper"}>
                            {/*<div className="mobile-item-app-general-title">*/}
                            {/*    <div className="mobile-item-app-general-title-text">APP 开发</div>*/}
                            {/*</div>*/}
                            <div className="mobile-item-app-title-des">引领技术 突破桎梏</div>
                            <div className="mobile-item-app-title-des-sub">构建移动双核，引领客户潮流</div>
                            <div className="mobile-item-app-general-content-one">
                                <div className="mobile-app-image-one"/>
                                <div className="mobile-app-content-one-sd"/>
                                <div className="mobile-app-content-one">
                                    <div className="mobile-app-content-one-title">聚焦核心需求</div>
                                    <div className="mobile-app-content-one-subtitle">始终从用户本质的需求出发</div>
                                    <div className="mobile-app-content-one-text">
                                        赋予每款APP生命力，创造全新移动端生态平台；<br/>
                                        全面解析品牌所处行业，站在客户视角思考问题；<br/>
                                        落实差异化特征，拓展多样化应用场景；<br/>
                                        提升用户体验，打造尖端性能。<br/>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-item-app-general-content-two">
                                <div className="mobile-app-image-two"/>
                                <div className="mobile-app-content-two">
                                    <div className="mobile-app-content-two-title">直击痛点 唯快不破</div>
                                    <div className="mobile-app-content-two-subtitle">打磨产品，快速迭代</div>
                                    <div className="mobile-app-content-two-text">
                                        与时俱进，紧跟技术浪潮；<br/>
                                        使用全新积木化架构， <br/>
                                        保障项目高效迭代；<br/>
                                        支持前端H5混合开发模式，<br/>
                                        使用原生编译；<br/>
                                        为您与时间的赛跑赢得先机。
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-item-app-general-content-three">
                                <div className="mobile-app-image-three"/>
                                <div className="mobile-app-content-three">
                                    <div className="mobile-app-content-three-title">UI设计</div>
                                    <div className="mobile-app-content-three-detail">
                                        <div className="mobile-app-content-three-detail-one">
                                            <FileImageOutlined style={{fontSize: '130px', color: '#7f97b4'}}/>
                                            <div className="mobile-app-content-three-detail-slogan">视觉设计</div>
                                            <div className="mobile-app-content-three-detail-text">把控视觉及图形细节，体现产品的品质感
                                            </div>
                                        </div>
                                        <div className="mobile-app-content-three-detail-two">
                                            <RocketOutlined style={{fontSize: '130px', color: '#7f97b4'}}/>
                                            <div className="mobile-app-content-three-detail-slogan">创新力</div>
                                            <div
                                                className="mobile-app-content-three-detail-text">以创意为驱动力，为产品赋予新鲜且有新意的体现
                                            </div>
                                        </div>
                                        <div className="mobile-app-content-three-detail-three">
                                            <ReconciliationOutlined style={{fontSize: '130px', color: '#7f97b4'}}/>
                                            <div className="mobile-app-content-three-detail-slogan">风格打造</div>
                                            <div className="mobile-app-content-three-detail-text">风格保持整体一致，忠于产品目标和产品方向
                                            </div>
                                        </div>
                                        <div className="mobile-app-content-three-detail-four">
                                            <CrownOutlined style={{fontSize: '130px', color: '#7f97b4'}}/>
                                            <div className="mobile-app-content-three-detail-slogan">用户体验</div>
                                            <div className="mobile-app-content-three-detail-text">围绕业务逻辑，切入产品需求，提升用户体验
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-item-wechat-general" id="service-wechat-info">
                        {/*<div className="mobile-service-items-wechat-title">*/}
                        {/*    <div className="mobile-service-items-wechat-title-text">公众号服务</div>*/}
                        {/*</div>*/}
                        <div className="mobile-item-wechat-title-des">多维视点 自媒直航</div>
                        <div className="mobile-item-wechat-title-des-sub">为您呈现独一无二的感官体验</div>
                        <div className="mobile-item-wechat-general-content">
                            <div className="mobile-wechat-content">
                                <div className="mobile-wechat-content-one">
                                    <WechatFilled style={{fontSize: '170px', color: '#ffffff'}}/>
                                    <div className="mobile-wechat-content-one-title">微信公众号代运营</div>
                                    <div
                                        className="mobile-wechat-content-one-text">提供公众号搭建、内容策划、营销运营等一体化微信代运营服务，助力品牌流量闭环
                                    </div>
                                </div>
                                <div className="mobile-wechat-content-two">
                                    <EditFilled style={{fontSize: '170px', color: '#ffffff'}}/>
                                    <div className="mobile-wechat-content-two-title">专业文案设计</div>
                                    <div
                                        className="mobile-wechat-content-two-text">基于品牌调性和目标客群，统筹整体风格和内容，专业文案设计，传递品牌文化和产品价值
                                    </div>
                                </div>
                                <div className="mobile-wechat-content-three">
                                    <AppstoreFilled style={{fontSize: '170px', color: '#ffffff'}}/>
                                    <div className="mobile-wechat-content-three-title">微信小程序开发</div>
                                    <div className="mobile-wechat-content-three-text">为品牌打造行业垂直深度小程序，支持小程序个性化定制等</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-item-machine-general" id="service-machine-info">
                        <div className={"mobile-item-machine-general-sum"}>数字科技 放眼未来</div>
                        <div className={"mobile-item-machine-general-sum-sub"}>精确制导，统筹全局</div>
                        <div className="mobile-item-machine-general-content">
                            <ModelingService isMobile={true}/>
                        </div>
                    </div>
                    <ServiceFlow isMobileL={true}/>
                </div>
            );
        }
    }
}

export default ServiceItem;