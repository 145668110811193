// eslint-disable-next-line import/no-anonymous-default-export
export default {
    HOME_PAGE: '首页',
    COMPANY_INTRO: '公司简介',
    SERVICE: '服务介绍',
    NEWS: '新闻',
    CONTACT: '联系我们',
    LANGUAGE: '语言',
    ENGLISH: '英',
    CHINESE: '中',
    COMPANY_BACKGROUND: '公司背景',
    PEOPLE: '人员列表',
    WEB_DESIGN: '网页定制',
    APP_DESIGN: 'App制作',
    WE_CHART: '公众号打造与维护',
    MODELING: '数据建模与分析',
    SERVICE_OVERVIEW: '业务总览',

    // toDo ICP
    INFO: '信息发布',
    UPLOAD: '发布信息',
    //

}