import React from 'react';
import JUGU from "../../picture/JUGUWORD.png"

class ContactFooter extends React.Component {
    render() {
        const legalInfoCN = "巨罟大数据科技有限公司 版权所有 ";
        const legalInfoEN = " | Copyright © 2021. All Rights Reserved.";
        const email = "邮箱：640715376@qq.com";
        const phone = "联系电话：021-61634428";
        return (
            <div className="contact-footer">
                <div className={"contact-footer-logo"}>
                    <img src={JUGU} alt={""} style={{height: "50px", marginTop: "6px"}}/>
                </div>
                <div className={"contact-footer-contact"}>
                    {email}
                    <br/>
                    <a href={"tel: 021-61634428"} style={{color: "#dbdbdb"}}>{phone}</a>
                </div>
                <div className={"contact-footer-legal"}>
                    {legalInfoCN}
                    {legalInfoEN}
                </div>

            </div>
        )
    }
}

export default ContactFooter;