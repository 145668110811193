import {
    AppstoreAddOutlined,
    BarChartOutlined,
    BoldOutlined, CloudUploadOutlined,
    EyeOutlined,
    HomeOutlined,
    LayoutOutlined,
    PictureOutlined,
    ShoppingOutlined,
    SnippetsOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import Tran from './IntTranslation';
import '../index.css';

const menus = [
    {
        path: '/',
        title: <Tran intlKey="HOME_PAGE"/>,
        breadcrumbName: 'home',
        icon: <HomeOutlined/>,
        role: 1 // 所有人都可见
    },
    {
        path: '/pro/background',
        title: <Tran intlKey="COMPANY_INTRO"/>,
        breadcrumbName: 'pro',
        icon: <BoldOutlined/>,
        role: 1,
        // children: [
        //     {
        //         path: '/pro/background',
        //         title: <Tran intlKey="COMPANY_BACKGROUND"/>,
        //         breadcrumbName: 'pro/background',
        //         icon: HomeOutlined,
        //         role: 1,
        //     },
        //     {
        //         path: '/pro/employee',
        //         title:  <Tran intlKey="PEOPLE"/>,
        //         breadcrumbName: 'pro/employee',
        //         icon: HomeOutlined,
        //         role: 2,
        //     }
        // ]
    },
    // {
    //     path: '/services',
    //     title: <Tran intlKey="SERVICE"/>,
    //     breadcrumbName: 'users',
    //     icon: <ShoppingOutlined/>,
    //     role: 1,
    //     children: [
    //         {
    //             path: '/services',
    //             title: <Tran intlKey="SERVICE_OVERVIEW"/>,
    //             breadcrumbName: 'services',
    //             icon: <EyeOutlined/>,
    //             role: 1,
    //         },
    //         {
    //             path: '/services#service-web-info',
    //             title: <Tran intlKey="WEB_DESIGN"/>,
    //             breadcrumbName: 'services/web',
    //             icon: <LayoutOutlined/>,
    //             role: 1,
    //         },
    //         {
    //             path: '/services#service-app-info',
    //             title: <Tran intlKey="APP_DESIGN"/>,
    //             breadcrumbName: 'services/apps',
    //             icon: <AppstoreAddOutlined/>,
    //             role: 2,
    //         },
    //         {
    //             path: '/services#service-wechat-info',
    //             title: <Tran intlKey="WE_CHART"/>,
    //             breadcrumbName: 'services/pub',
    //             icon: <PictureOutlined/>,
    //             role: 3,
    //         },
    //         {
    //             path: '/services#service-machine-info',
    //             title: <Tran intlKey="MODELING"/>,
    //             breadcrumbName: 'services/statistics',
    //             icon: <BarChartOutlined/>,
    //             role: 4,
    //         }
    //     ]
    // },
    // {
    //     path: '/news',
    //     title: <Tran intlKey="NEWS"/>,
    //     breadcrumbName: 'cart',
    //     icon: <SnippetsOutlined/>,
    //     role: 1,
    // },

    // toDo ICP
    {
        path: '/services',
        title: <Tran intlKey="INFO"/>,
        breadcrumbName: 'info',
        icon: <ShoppingOutlined/>,
        role: 1 // 所有人都可见
    },
    {
        path: '/upload/info',
        title: <Tran intlKey="UPLOAD"/>,
        breadcrumbName: 'upload',
        icon: <CloudUploadOutlined />,
        role: 1 // 所有人都可见
    },
    //

    {
        path: '/contact',
        title: <Tran intlKey="CONTACT"/>,
        breadcrumbName: 'order',
        icon: <WhatsAppOutlined/>,
        role: 1,
    },
]

export default menus