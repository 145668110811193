import React from 'react'

class UserDevice extends React.Component {
    render() {
        let ua = navigator.userAgent;
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        return <div style={{fontSize: "40px"}}>
            {ua}
            <br/>
            {"width: " + width}
            <br/>
            {"height: " + height}
        </div>
    }
}

export default UserDevice;