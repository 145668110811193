import React from 'react';
import './putao.css'
import PUTAO from "../picture/putao.png";
import clock from  "../picture/clock.svg";
import {sendGetRequest, sendPostRequest} from "../util/RequestHelper";

const rootSrc = "https://putaotao.jugu-mega.com/resource/";

class Putaotao extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            itemInfo: new Map(),
            pageNo: 1,
            orderInfo: [],
            expInfo: "",
            orderId: "",
            orderPrice: 0,
            orderStatus: 0,
            productInfo: [],
        }
        this.timmer = null;
    }

    componentDidMount() {
        let pageNo = 1;
        let res = new URLSearchParams(this.props.location.search);
        let id = res.get("id");
        if (id) {
            pageNo = 4;
        }

        sendGetRequest("https://putaotao.jugu-mega.com/user/findActGoods", {}).then((res) => {
            if (res.success) {
                let data = res["responseData"];
                let itemInfo = new Map();
                data.forEach((p) => {
                    itemInfo.set(p["goodId"], {num: 0, price: p["goodPrice"], name: p["goodName"]});
                })
                this.setState({
                    loaded: true,
                    itemInfo: itemInfo,
                    pageNo: pageNo,
                    orderId: id ? id : "",
                    productInfo: data,
                }, () => {
                    if (pageNo === 4) {
                        this.checkRes(1, id);
                    }
                })
            } else {
                let errorType = res["errorType"];
            }
        })

    }

    checkRes = (num, orderId) => {
        this.timmer = null;
        if (num < 30) {
            this.timmer = setTimeout(() => {
                console.log("num" + num)
                sendGetRequest("https://putaotao.jugu-mega.com/user/checkActOrder", {id: orderId}).then((res) => {
                    console.log(res)
                    if (res.success) {
                        this.timmer = null;
                        let data = res["responseData"];
                        let orderInfo = JSON.parse(data["pinfo"]);
                        let orderPrice = parseInt(data["totalP"])
                        this.setState({
                            pageNo: 3,
                            orderInfo: orderInfo,
                            orderPrice: orderPrice,
                            expInfo: data["oaddr"],
                            orderStatus: data["orderStatus"],
                        })
                    } else {
                        let errorType = res["errorType"];
                        if (errorType === "NOT_FINISH") {
                            num++;
                            this.checkRes(num, orderId);
                        }
                    }
                })
            }, 1000)
        }
    }

    submitOrder = (expressInfo, orderInfo, info) => {
        if (expressInfo) {
            let actPojo = {pInfo: JSON.stringify(orderInfo), oAddr: expressInfo, totalP: this.calcP(info)}
            let pojo = {url: "https://putaotao.jugu-mega.com/user/findH5PrePareLink", data: actPojo}
            let newWin = window.open('', '_blank')
            sendPostRequest(pojo).then((res) => {
                if(res.success) {
                    let data = res["responseData"];
                    let link = data["urlLink"];
                    let orderId = data["orderId"];
                    let red = encodeURI("https://www.jugu-mega.com/putao?id=" + orderId);
                    // window.open(link + "&redirect_url=" + red, "_blank")
                    newWin.location = link + "&redirect_url=" + red;
                    // window.location.href = link + "&redirect_url=" + red;
                    // return link + "&redirect_url=" + red;
                    // this.setState({
                    //     pageNo: 3,
                    // })
                }
            })
            //success
        }
    }

    pClick = (id, isAdd, info, num, price, name) => {
        if ((!isAdd && num < 1) || (isAdd && num > 49)) {
            return;
        }
        if (isAdd) {
            num++;
        } else {
            num--;
        }
        info.set(id, {num: num, price: price, name: name});
        this.setState({
            itemInfo: info,
        })
    }

    loadPds = (itemInfo) => {
        let pds = [];
        pds.push(<div style={{width: "100%", height: "140px"}}/>)
        let productInfo = this.state.productInfo;
        productInfo.forEach((p) => {
            let id = p["goodId"];
            let info = itemInfo.get(id)
            let num = info.num;
            let price = p["goodPrice"];
            let name = p["goodName"];
            pds.push(
                <div className={"putao-out-m-item"}>
                    <img className={"putao-out-m-item-img"} src={rootSrc + p["goodSrc"]} alt={""}/>
                    <div className={"putao-out-m-item-des"}>
                        <div className={"putao-out-m-item-des-name"}>
                            {name}
                        </div>
                        <div className={"putao-out-m-item-des-des"}>
                            {p["goodDes"]}
                        </div>
                        <div className={"putao-out-m-item-des-bt"}>
                            {/*<div className={"putao-out-pc-item-des-bt-comm"}>*/}
                            {/*    {"平台指导价： ¥2400"}*/}
                            {/*</div>*/}
                            <div className={"putao-out-m-item-des-bt-act"}>
                                {"活动专享价： ¥" + price}
                            </div>
                            <div className={"putao-out-m-item-des-bt-clk"} style={{marginLeft: "20px"}}
                                 onClick={() => {
                                     this.pClick(id, false, itemInfo, num, price, name)
                                 }}>
                                {"-"}
                            </div>
                            <div className={"putao-out-m-item-des-bt-num"}>
                                {num}
                            </div>
                            <div className={"putao-out-m-item-des-bt-clk"} style={{marginRight: "40px"}}
                                 onClick={() => {
                                     this.pClick(id, true, itemInfo, num, price, name)
                                 }}>
                                {"+"}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        pds.push(<div style={{width: "100%", height: "260px"}}/>)
        return pds;
    }

    calcP = (itemInfo) => {
        let t = 0;
        itemInfo.forEach((info) => {
            t += info.num * info.price;
        })
        return t;
    }

    switchPage = (priceT, itemInfo) => {
        if (priceT > 0) {
            let orderInfo = [];
            itemInfo.forEach((v) => {
                if (v.num > 0) {
                    orderInfo.push(v)
                }
            })
            this.setState({
                pageNo: 2,
                orderInfo: orderInfo,
            })
        }
    }

    loadSummary = (orderInfo) => {
        let orders = [];
        orders.push(<div style={{width: "100%", height: "140px"}}/>)
        orderInfo.forEach((o) => {
            orders.push(
                <div className={"putao-order-m-out"}>
                    <div className={"putao-order-m-name"}>
                        {o.name}
                    </div>
                    <div className={"putao-order-m-stat"}>
                        <div className={"putao-order-m-stat-price"}>
                            {"¥" + o.price}
                        </div>
                        <div className={"putao-order-m-stat-price-name"}>
                            {"数量：" + o.num}
                        </div>
                    </div>
                </div>
            )
        })

        return orders
    }

    saveExpInfo = (e) => {
        let val = e.target.value;
        this.setState({
            expInfo: val,
        })
    }

    genHomePage = (itemInfo, priceT) => {
        return (
            <div className={"putao-out-m"}>
                <div className={"putao-out-pc-m"}>
                    <div className={"putao-out-m-header"}>
                        <img className={"putao-out-m-logo"} src={PUTAO} alt={""}/>
                        <div className={"putao-out-m-title"}>
                            {"葡萄淘 活动VIP专场"}
                        </div>
                    </div>
                    <div>
                        {this.loadPds(itemInfo)}
                    </div>
                </div>
                <div className={"putao-out-bt-su"}>
                    <div className={"putao-out-bt-su-price"}>
                        {"总金额：¥" + priceT}
                    </div>
                    <div className={"putao-out-bt-su-next"}
                         style={{background: priceT > 0 ? "#08e558" : "#bbbbbb"}}
                         onClick={() => {
                             this.switchPage(priceT, itemInfo)
                         }}>
                        {"下一步"}
                    </div>
                </div>
            </div>
        )
    }

    genPayResWaitPage = () => {
        return (
            <div className={"putao-out-m"}>
                <div className={"putao-out-pc-m"} style={{background: "white", boxShadow: "none"}}>
                    <div className={"putao-out-m-header"} style={{justifyContent: "space-between"}}>
                        <div className={"putao-out-m-header-des"}>
                            <img className={"putao-out-m-logo"} src={PUTAO} alt={""}/>
                            <div className={"putao-out-m-title"}>
                                {"葡萄淘 活动VIP专场"}
                            </div>
                        </div>
                    </div>
                    <div className={"putao-out-m-wait"}>
                        <div style={{width: "100%", height: "300px"}}/>
                        <img src={clock} alt={""} className={"putao-out-m-wait-logo"}/>
                        <div className={"putao-out-m-wait-notice"}>{"订单信息同步中，30秒内请勿关闭页面"}</div>
                    </div>
                </div>
            </div>
            )
    }

    render() {
        let loaded = this.state.loaded;
        let pageNo = this.state.pageNo;
        if (loaded) {
            let itemInfo = this.state.itemInfo;
            if (pageNo === 1) {
                let priceT = this.calcP(itemInfo);
                return this.genHomePage(itemInfo, priceT);
            } else {
                let orderInfo = this.state.orderInfo;
                let expInfo = this.state.expInfo;
                if (pageNo === 4) {
                    return this.genPayResWaitPage();
                } else {
                    let orderPrice = this.state.orderPrice;
                    let priceT = orderPrice !== 0 ? orderPrice : this.calcP(itemInfo);
                    let orderStatus = this.state.orderStatus;
                    let orderId = this.state.orderId;
                    return (
                        <div className={"putao-out-m"}>
                            <div className={"putao-out-pc-m"} style={{background: "white", boxShadow: "none"}}>
                                <div className={"putao-out-m-header"} style={{justifyContent: "space-between"}}>
                                    <div className={"putao-out-m-header-des"}>
                                        <img className={"putao-out-m-logo"} src={PUTAO} alt={""}/>
                                        <div className={"putao-out-m-title"}>
                                            {"葡萄淘 活动VIP专场"}
                                        </div>
                                    </div>

                                    {pageNo === 3
                                        ?
                                        <div/>
                                        :
                                        <div className={"putao-out-m-title-bk"} onClick={() => {
                                            this.setState({pageNo: 1})
                                        }}>
                                            {"返回"}
                                        </div>}
                                </div>
                                <div>
                                    {this.loadSummary(orderInfo)}
                                </div>
                                <div className={"putao-out-bt-su-price-sum"}>
                                    {"总金额：¥" + priceT}
                                </div>
                                <div className={"putao-out-bt-su-express"}>
                                    {pageNo === 3 ? "" : "填写快递信息（输入详细地址、收件人、收件人手机）"}
                                </div>
                                {pageNo === 3
                                    ?
                                    <div className={"putao-out-bt-su-ct"}>
                                        <div style={{fontWeight: "bold", marginBottom: "20px"}}>{"订单编号"}</div>
                                        <div style={{marginBottom: "60px"}}>{orderId}</div>
                                        <div style={{fontWeight: "bold", marginBottom: "20px"}}>{"收货地址详情"}</div>
                                        {expInfo}
                                        <div style={{marginTop: "60px", color: orderStatus === 2 ? "rgb(22,189,45)" : "rgb(231,152,62)"}}>
                                            {orderStatus === 2 ? "订单信息已生成，请截图保存" : "订单未完成支付，如已支付请刷新页面, 如有问题请联系工作人员"}
                                        </div>
                                    </div>
                                    :
                                    [<textarea id={"exp-input"} className={"putao-out-bt-su-input"}
                                               onChange={(e) => {
                                                   this.saveExpInfo(e)
                                               }}
                                    />,
                                        <div className={"putao-out-bt-su-submit"}
                                             style={{background: expInfo ? "#08e558" : "#bbbbbb"}}
                                             onClick={() => {
                                                 this.submitOrder(expInfo, orderInfo, itemInfo)
                                             }}>
                                            {"立即支付"}
                                        </div>
                                        // <a
                                        //     className={"putao-out-bt-su-submit"}
                                        //     style={{background: expInfo ? "#08e558" : "#bbbbbb"}}
                                        //     href={() => this.submitOrder(expInfo, orderInfo, itemInfo)}
                                        // >{"立即支付"}</a>
                                    ]

                                }


                            </div>
                        </div>
                    )
                }

            }
        } else {
            return <div/>
        }
    }
}

export default Putaotao;