import "../index.css";
import {imgHeader} from "../constants";

const InfoCard = (props) => {

    const { info, switchInfo } = props;

    const title = info.advTitle;
    const des = info.advDes;
    const cover = imgHeader + info.advCover;
    // const url = "/info?info=" + encodeURIComponent(JSON.stringify(info));

    return (
        <>
            <div className={"info-card"}>
                <div className={"info-card-left"}>
                    <img className={"info-card-cover"} src={cover} alt={""}
                        onClick={() => {switchInfo(info)}}
                    />
                </div>
                <div className={"info-card-right"}>
                    <h2 style={{fontWeight: "bolder", cursor: "pointer"}} onClick={() => {switchInfo(info)}}>
                        {/*<a href={url} title={title}>*/}
                            {title}
                        {/*</a>*/}
                    </h2>
                    <p>{des}</p>
                    <span className={"info-more"} onClick={() => {switchInfo(info)}}>
                        {/*< href={url} title={title}>*/}
                            {"查看更多"}
                        {/*</>*/}
                    </span>
                </div>
            </div>
        </>
    )
}

export default InfoCard;