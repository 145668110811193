import React from "react";
import "./servicebanner.less"
import {Swiper} from "swiper";
import {handleNarrowMenu} from "../../util/WindowHelper";
import SOne from "./picture/service-banner-one.jpg";
import STwo from "./picture/service-banner-two.jpg";
import {preLoadImages} from "../../util/RequestHelper";

class ServiceBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxLabel: 0,
            needSMenu: this.setInitMenu(),
            homeImages: "",
            loadedNum: 0,
            swiper: "",
            loaded: false,
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    getWindowWidth = e => {
        handleNarrowMenu(this, e)
    }

    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                boxLabel: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                boxLabel: label
            })
        }, 100)
    }

    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }

    componentDidMount() {
        if (!this.props.isMobileL) {
            preLoadImages(this.imgInfos, this);
        }
        window.addEventListener('resize', this.getWindowWidth)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getWindowWidth)
    }

    genSwp = () => {
        new Swiper('.service-banner-list', {
            observer: true,
            observeParents: true,
            paginationClickable: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            speed: 1000,
            loop: true,
            current: this.state,
            loopedSlides: 1,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
            },
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isMobileL && prevState.loadedNum !== this.state.loadedNum && this.state.loadedNum === 2) {
            this.props.parent.setLoad();
            this.setState({loaded: true}, this.genSwp);
        }
    }

    imgInfos = [
        {name: "SOne", url: SOne},
        {name: "STwo", url: STwo}
    ]

    render() {
        const boxLabel = this.state.boxLabel
        let isMobileL = this.props.isMobileL;
        const needSMenu = this.state.needSMenu;

        if (!isMobileL && this.state.loaded) {
            let images = this.state.homeImages;
            return (
                <div className="service-banner" id="banner">
                    <div className={needSMenu ? "service-banner-narrow-empty" : "service-banner-empty"}/>
                    <div
                        className={needSMenu ? "swiper-container-narrow service-banner-list" : "swiper-container service-banner-list"}>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="service-banner-one" style={{backgroundImage: `url("${images.get("SOne").src}")`}}/>
                            </div>
                            <div className="swiper-slide">
                                <div className="service-banner-two" style={{backgroundImage: `url("${images.get("STwo").src}")`}}/>
                            </div>
                        </div>
                    </div>

                    <div className="service-anchor">
                        <div className="service-anchor-relative">
                            <div className="service-anchor-title">
                                <h2>服务内容</h2>
                                <span>Our Services</span>
                            </div>
                            <div className="service-anchor-box">
                                <div className="service-anchor-box-one">
                                </div>
                                <div
                                    className={boxLabel === 1 ? "service-anchor-box-two-back" : "service-anchor-box-two"}
                                    onMouseEnter={this.MouseEnter.bind(this, 1)}
                                    onMouseLeave={this.MouseLeave.bind(this, 0)}>
                                    <div className="service-anchor-box-content" onClick={() => {
                                        this.scrollToAnchor("service-web-info")
                                    }}>
                                        <div className="service-anchor-box-content-title">Web定制</div>
                                        <div className="service-anchor-box-content-text">
                                            <li>策略定位</li>
                                            <li>视觉设计</li>
                                            <li>系统开发</li>
                                            <li>算法实现</li>
                                            <li>后台管理</li>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={boxLabel === 2 ? "service-anchor-box-three-back" : "service-anchor-box-three"}
                                    onMouseEnter={this.MouseEnter.bind(this, 2)}
                                    onMouseLeave={this.MouseLeave.bind(this, 0)}>
                                    <div className="service-anchor-box-content" onClick={() => {
                                        this.scrollToAnchor("service-app-info")
                                    }}>
                                        <div className="service-anchor-box-content-title">App开发</div>
                                        <div className="service-anchor-box-content-text">
                                            <li>聚焦核心需求</li>
                                            <li>直击痛点 唯快不破</li>
                                            <li>UI设计</li>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={boxLabel === 3 ? "service-anchor-box-four-back" : "service-anchor-box-four"}
                                    onMouseEnter={this.MouseEnter.bind(this, 3)}
                                    onMouseLeave={this.MouseLeave.bind(this, 0)}>
                                    <div className="service-anchor-box-content" onClick={() => {
                                        this.scrollToAnchor("service-wechat-info")
                                    }}>
                                        <div className="service-anchor-box-content-title">公众号服务</div>
                                        <div className="service-anchor-box-content-text">
                                            <li>微信公众号代运营</li>
                                            <li>专业文案设计</li>
                                            <li>微信小程序开发</li>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={boxLabel === 4 ? "service-anchor-box-five-back" : "service-anchor-box-five"}
                                    onMouseEnter={this.MouseEnter.bind(this, 4)}
                                    onMouseLeave={this.MouseLeave.bind(this, 0)}>
                                    <div className="service-anchor-box-content" onClick={() => {
                                        this.scrollToAnchor("service-machine-info")
                                    }}>
                                        <div className="service-anchor-box-content-title">机器学习</div>
                                        <div className="service-anchor-box-content-text">
                                            <li>机器学习</li>
                                            <li>数据挖掘</li>
                                            <li>神经网络</li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mobile-service-page-banner">
                    <div className="mobile-service-banner"/>
                </div>
            );
        }
    }
}

export default ServiceBanner;