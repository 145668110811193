import React, {Component} from 'react'
import {Map, Marker} from 'react-amap'
import './contact.css'
import {AimOutlined} from "@ant-design/icons";

const mapKey = 'a8beb2314f65e17c56a7b6871526977c'
const mapUs = 'amap://styles/e672484b1fbe479fb78eaae844bee41d'
const posLink = 'https://ditu.amap.com/place/B00156Z04B'


class LMap extends Component {
    render() {
        const position = {longitude: 121.434314, latitude: 31.252425}
        const showPoint = {longitude: 121.434390, latitude: 31.250480}
        const showPointM = {longitude: 121.434314, latitude: 31.252225}
        let isMobileL = this.props.isMobileL;

        return (
            <div className={isMobileL ? "mobile-map-tools" : "map-tools"}>
                <a href={posLink}>
                    <Map amapkey={mapKey} zoom={16.5}
                         center={isMobileL ? showPointM : showPoint}
                         mapStyle={mapUs} zoomEnable={false}
                         dragEnable={false}
                         keyboardEnable={false} touchZoom={false}>
                        <Marker position={position}>
                            <div className={isMobileL ? "mobile-map-target" : "map-target"}>
                                <AimOutlined/>
                            </div>
                        </Marker>
                    </Map>
                </a>
            </div>
        )
    }
}

export default LMap