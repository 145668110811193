import React, {Component} from 'react';
import {Menu} from 'antd';
import 'antd/dist/antd.css';
import menus from './config/menuConfig'
import "./index.css"
import {CaretRightOutlined} from "@ant-design/icons";

const {SubMenu} = Menu

export default class MenuSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        }
    }

    renderMenuItem(menus, isCollapsed, useSliderMenu, isSubMenuItem, needSMenu, isMobileL, page) {
        return menus.map(item => {

            if (item.children) {
                return (
                    <SubMenu
                        className={isMobileL ? ("mobile-sub-menu-item") : (useSliderMenu ? (isCollapsed ? "" : "home-sub-menu-item") : (needSMenu ? "narrow-sub-menu-item " : "page-sub-menu-item"))}
                        key={item.path} icon={isMobileL ? "" : item.icon}
                        onTitleClick={() => {
                            page.setState(
                                {
                                    isActive: !page.state.isActive,
                                }
                            )
                        }}
                        expandIcon={isMobileL ? <CaretRightOutlined rotate={page.state.isActive ? 90 : 0} style={{
                            float: "right",
                            marginRight: "2vh",
                            fontSize: "1.4vh",
                            lineHeight: "6vh"
                        }}/> : ""}
                        title={item.title}>
                        {this.renderMenuItem(item.children, isCollapsed, useSliderMenu, true, needSMenu, isMobileL, page)}
                        {() => {
                            window.location.href = item.children.path
                        }}
                    </SubMenu>
                )
            } else {
                return (
                    <Menu.Item
                        className={isMobileL ? ("mobile-sub-menu-sub-item") : (useSliderMenu ? (isCollapsed ? "" : "home-menu-item") : (isSubMenuItem ? "page-sub-menu-sub-item" : "page-menu-item"))}
                        key={item.path}
                        icon={isMobileL ? "" : item.icon}
                        onClick={() => {
                            if (isMobileL) {
                                window.location.reload();
                            }
                            window.location.href = item.path;
                        }}>
                        {item.title}
                    </Menu.Item>
                )
            }
        })
    }

    render() {
        let MenuName = this.props.MenuName;
        let isCollapsed = this.props.isCollapsed;
        let useSliderMenu = this.props.useSliderMenu;
        let lTheme = this.props.lTheme;
        let lMode = this.props.lMode;
        let lDSKey = this.props.lDSKey;
        let needSMenu = this.props.needSMenu;
        let isMobileL = this.props.isMobileL;

        if (isMobileL) {
            return (
                <Menu className={"mobile-top-menu"} mode={"inline"} defaultSelectedKeys={lDSKey}
                      inlineCollapsed={useSliderMenu ? isCollapsed : false}>
                    {this.renderMenuItem(menus, isCollapsed, useSliderMenu, false, needSMenu, isMobileL, this)}
                </Menu>
            )
        }


        if (needSMenu) {
            return (
                <Menu className={"narrow-top-menu"} theme={lTheme} mode={"inline"} defaultSelectedKeys={lDSKey}
                      inlineCollapsed={useSliderMenu ? isCollapsed : false}>
                    {this.renderMenuItem(menus, isCollapsed, useSliderMenu, false, needSMenu, isMobileL, this)}
                </Menu>
            )
        } else {
            return (
                <Menu className={MenuName} theme={lTheme} mode={lMode} defaultSelectedKeys={lDSKey}
                      inlineCollapsed={useSliderMenu ? isCollapsed : false} style={{background: "#545961"}}>
                    {this.renderMenuItem(menus, isCollapsed, useSliderMenu, false, needSMenu, isMobileL, this)}
                </Menu>
            )
        }
    }
}

MenuSelector.defaultProps = {
    needSMenu: false
}