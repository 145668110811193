import React from 'react';

class Try extends React.Component {
    downloadUerInfo = (url) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.withCredentials = true;
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status === 200) {
                let blob = this.response;
                console.log(blob)
                let a = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "userInfo.zip";
                a.click();
                window.URL.revokeObjectURL(url);
            }
        };
        xhr.send();
        console.log("sss")
    }

    sendRequest = (url) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.withCredentials = true;
        xhr.send();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                // let result = JSON.parse(xhr.responseText)
                console.log(xhr.responseText)

            }
        }
    }

    render() {
        let url1 = "http://localhost:8080/web/downloadAllUserInfo?audit=shibowen&time=" + Math.random();
        let url2 = "http://localhost:8080/web/downloadScopeUserInfo?audit=shibowen&scope=20211020-20211020&time=" + Math.random();
        let url3 = "http://localhost:8080/web/downloadAwardUserInfo?audit=shibowen&time=" + Math.random();

        let urlp = "http://localhost:8080/web/sendPhoneSec?phone=13774209239&code=86&tempId=SMS_151768797&time=" + Math.random();
        let urlpC = "http://localhost:8080/web/checkSecImpCode?secCode=920385&type=phCode&host=1&time=" + Math.random();
        let urle = "http://localhost:8080/web/sendEmailSec?email=634819383@qq.com&tempId=20049&subject=checkit&host=1&time=" + Math.random();
        let urleC = "http://localhost:8080/web/checkSecImpCode?secCode=459280&host=1&type=emCode&time=" + Math.random();

        let urlSET = "http://localhost:8080/web/resetHostParam?host=1&pwd=123456&time=" + Math.random();

        let tianshui = "https://service.jugu-mega.com/service/tianshui/api/open/getVerify"
        return (
            <div>
                <div style={{width: "100px", height: "100px", background: "blue"}}
                     onClick={() => this.downloadUerInfo(url1)}>
                    downloadall
                </div>
                <div style={{width: "100px", height: "100px", background: "green"}}
                     onClick={() => this.downloadUerInfo(url2)}>
                    downloadscope
                </div>
                <div style={{width: "100px", height: "100px", background: "grey"}}
                     onClick={() => this.downloadUerInfo(url3)}>
                    downloadaward
                </div>

                <div style={{width: "100px", height: "100px", background: "white"}}
                     onClick={() => this.sendRequest(urlp)}>
                    手机验证
                </div>
                <div style={{width: "100px", height: "100px", background: "white"}}
                     onClick={() => this.sendRequest(urlpC)}>
                    手机校验
                </div>
                <div style={{width: "100px", height: "100px", background: "white"}}
                     onClick={() => this.sendRequest(urle)}>
                    邮箱验证
                </div>
                <div style={{width: "100px", height: "100px", background: "white"}}
                     onClick={() => this.sendRequest(urleC)}>
                    邮箱校验
                </div>
                <div style={{width: "100px", height: "100px", background: "white"}}
                     onClick={() => this.sendRequest(urlSET)}>
                    重置密码
                </div>

                <div style={{width: "100px", height: "100px", background: "white"}}
                     onClick={() => this.sendRequest(tianshui)}>
                    天水验证
                </div>
            </div>
        )
    }
}

export default Try;