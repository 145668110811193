import React from "react";
import "./index.css";
import {findZoom} from "../../util/WindowHelper";
import {isMobile} from "../../util/MobileMgr";
import {message, Spin} from "antd";
import PageHeader from "../../PageHeader";
import {imgHeader} from "../info/constants";
import {sendPostRequest} from "../../util/RequestHelper";

class UploadInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needTopBG: true,
            needSMenu: this.setInitMenu(),
            zoomSize: findZoom(1480),
            isMobile: isMobile(),
            isLoaded: true,
            info: {
                title: null,
                type: null,
                des: null,
                detail: null,
                cover: null,
                contacts: null,
                contactsPhone: null,
                coverStr: null,
            },
        }
    }

    setInitMenu = () => {
        return document.documentElement.clientWidth < 1270;
    }

    componentDidMount() {
        let login = window.localStorage.getItem("userInfo");
        if (!login) {
            message.info("请先登录账号", 1)
                .then(() => {
                    window.location.href = "/login";
                })
        }
    }

    genInput = (title, tag, tag2, isRequire, info, minLen, maxLen) => {
        return (
            <div className={"form-input-wrap"}>
                <div className={"form-input-title"}>
                    {isRequire && <span className={"form-input-title-require"}>*</span>}
                    {title}
                </div>
                <input className={"form-input"}
                       minLength={minLen}
                       maxLength={maxLen}
                       onBlur={(event => {this.onblur(event, tag, tag2, info, minLen)})}
                />
            </div>
        )
    }

    genTextarea = (title, tag, isRequire, info, hgt) => {
        return (
            <div className={"form-input-wrap"}>
                <div className={"form-input-title"}>
                    {isRequire && <span className={"form-input-title-require"}>*</span>}
                    {title}
                </div>
                <textarea className={"form-textarea"}
                          style={{height: hgt + "px"}}
                          minLength={1}
                          maxLength={200}
                          onBlur={(event => {this.onblur(event, tag, null, info, 1)})}
                />
            </div>
        )
    }

    onblur = (event, tag, tag2, info, minLen) => {
        let val = event.target.value;
        let len = val.length;
        if (len < minLen) {
            message.error("请正确输入");
        } else {
            info[tag] = val;
            if (tag2) {
                info[tag2] = val;
            }
            this.setState({
                info: info,
            })
        }
    }

    genImage = (title, tag, isRequire, info) => {
        return (
            <div className={"form-input-wrap"}>
                <div className={"form-input-title"}>
                    {isRequire && <span className={"form-input-title-require"}>*</span>}
                    {title}
                </div>
                {
                    info.cover ?
                        <img className={"form-cover"} src={info.cover} alt={""}/>
                        :
                        <div className={"form-cover"}>
                            <label htmlFor={"imgUpload"} className={"upload-label"}>
                                <img className={"form-cover-img"} src={imgHeader + "upload.jpeg"} alt={""}/>
                            </label>
                            <input className={"input-file"} type={"file"} id={"imgUpload"}
                                   onChange={(event) => {this.handleUpload(event, info)}}
                            />
                        </div>
                }
            </div>
        )
    }

    handleUpload = (event, info) => {
        let file = event.target.files[0];
        if (file) {
            this.imgUpload(file)
                .then((res) => {
                    let img = res.link;  // 包含（data:image/png;base64,）
                    let imgToUrl = img.split(',')[1];
                    info.cover = img;
                    // info.coverStr = imgToUrl;
                    // console.log(info);
                    this.setState({
                        info: info,
                    })
                })
        }
    }

    imgUpload = (file) => {
        return new Promise((resolve) => {
            let name = file.name.split(".");
            let type = name.length > 1 ? name[1] : "jpeg";
            if (type === "jpg") {
                type = "jpeg";
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            reader.onload = function () {
                img.src = this.result.toString();
            }
            img.onload = function () {
                let newUrl = "";
                if (type === "svg") {
                    newUrl = "data:image/svg+xml;" + img.src.split(";")[1];
                } else {
                    newUrl = "data:image/" + type + ";" + img.src.split(";")[1];
                }

                resolve({
                    type: type,
                    link: newUrl
                })
            }
        })
    }

    uploadInfo = (info) => {
        // console.log("info: ", info);
        let isValid = info.advTitle && info.advDes && info.advCt && info.advPerson && info.advPersonPhone;
        if (isValid) {
            sendPostRequest({url: '/api/insertAdv', data: info})
                .then((res) => {
                    if (res.success) {
                        message.success("已成功提交信息", 1)
                            .then(() => {
                                window.location.href = '/services';
                            });
                    }
                })

        } else {
            message.error("请正确填写发布信息");
        }
    }

    render() {
        let needTopBG = this.state.needTopBG;
        let needSMenu = this.state.needSMenu;
        let isMobileL = this.state.isMobile;
        let zoomL = this.state.zoomSize;

        // if (isMobileL) {
        //     return (
        //         <>
        //         </>
        //     )
        // } else {
            let isLoaded = this.state.isLoaded;
            let info = this.state.info;
            return (
                <>
                    <div style={{
                        textAlign: "center",
                        background: "rgb(224,224,224)",
                        height: "100vh",
                        position: "fixed",
                        width: "100%",
                        display: isLoaded ? "none" : ""
                    }}>
                        <Spin tip={"页面加载中，请稍等"} style={{color: "black", marginTop: "40vh"}}/>
                    </div>
                    <div className={"upload-info-page-wrap"}>
                        <PageHeader needTopBG={needTopBG} needSMenu={needSMenu}/>
                        <div className={"upload-info-page"}
                             style={{zoom: zoomL, display: isLoaded ? "" : "none"}}
                        >
                            <div className={"info-form"}>
                                <div className={"form-title"}>
                                    {"发布信息"}
                                </div>
                                {this.genInput("标题", "advTitle", null, true, info, 1, 30)}
                                {this.genTextarea("简介", "advDes", true, info, 100)}
                                {this.genTextarea("详细介绍", "advCt", true, info, 200)}
                                {this.genImage("图片介绍", "advCover", false, info)}
                                <div style={{height: "30px"}}/>
                                <div className={"form-title"}>
                                    {"联系方式"}
                                </div>
                                {this.genInput("联系人", "advPerson", "advUser", true, info, 1, 10)}
                                {this.genInput("联系方式", "advPersonPhone", null, true, info, 11, 11)}
                                <div style={{height: "65px"}}/>
                                <div className={"upload-btn"}
                                     onClick={() => {this.uploadInfo(info)}}
                                >
                                    {"提交信息"}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        // }
    }
}

export default UploadInfo;