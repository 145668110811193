import "../index.css";

const TopInfoCard = (props) => {

    const { info, index, switchInfo } = props;

    const title = info.advTitle;

    return (
        <>
            <div className={"top-info-card"}>
                <i>
                    {index + 1}
                </i>
                <div
                    onClick={() => {switchInfo(info)}}
                >
                    {title}
                </div>
            </div>
        </>
    )
}

export default TopInfoCard;