import Icon from '@ant-design/icons';
import React, {Component} from 'react';

class IconConvertor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            svg: "",
            size: "",
        }
    }

    render() {
        return (
            <Icon component={() => <img src={this.props.svg} alt={""} style={{height: this.props.size ? this.props.size : "16px", marginTop: "9px", marginBottom: "12px"}}/>}
                  style={{verticalAlign: "middle", display:"inline-block"}}/>
        );
    }
}

export default IconConvertor;