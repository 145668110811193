import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Bg from './pages/background/Bg';
import Service from "./pages/service/Service";
import Home from './pages/Home'
import News from './pages/news/News'
import Contact from "./pages/Contact";
import 'antd/dist/antd.css';
import './index.css'
import {Content} from "antd/lib/layout/layout";
import UserDevice from "./pages/UserDevice";
import Try from "./pages/Try";
import Putaotao from "./pages/Putaotao";
import PutaoHost from "./pages/PutaoHost";
import Test from "./pages/Test";
import Info from "./pages/info/Info";
import InfoD from "./pages/infoD/infoD";
import Login from "./pages/login";
import UploadInfo from "./pages/uploadInfo";
import Register from "./pages/register";

class PageRoutes extends React.Component {
    render() {
        return (
            <Router>
                <Content>
                    {/*跳转时不重复显示主页exact*/}
                    <Route exact={true} path="/" component={Home}/>
                    <Route exact={true} path="/pro/background" component={Bg}/>
                    <Route exact={true} path="/contact" component={Contact}/>
                    {/*<Route exact={true} path="/services" component={Service}/>*/}
                    {/*// toDo ICP */}
                    <Route exact={true} path="/login" component={Login}/>
                    <Route exact={true} path="/register" component={Register}/>
                    <Route exact={true} path="/services" component={Info}/>
                    <Route exact={true} path="/info" component={InfoD}/>
                    <Route exact={true} path="/upload/info" component={UploadInfo}/>
                    {/*//*/}
                    <Route exact={true} path="/news" component={News}/>
                    <Route exact={true} path="/navi/user" component={UserDevice}/>
                    <Route exact={true} path="/try" component={Try}/>
                    <Route exact={true} path="/putao" component={Putaotao}/>
                    <Route exact={true} path="/putaohost1231231" component={PutaoHost}/>
                    <Route exact={true} path="/test" component={Test}/>
                </Content>
            </Router>
        );
    }
}

export default PageRoutes