import React from 'react';
import {Carousel} from "antd";
import '../image.css'
import BK from "../../picture/home-banner-one.jpg";
import UFO from "../../picture/UFO.png";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
// import ADV from "../../picture/Adv.png";
import ADV from "../../picture/AdvTmp.png";
import LIGHT from "../../picture/Light.png";
import AdvT from "../../picture/AdvTM.png";
import AdvC from "../../picture/AdvCM.png";
import SP from "../../picture/Split.png";
import SoftH from "../../picture/SoftH.png";
import SoftHB from "../../picture/SoftHB.png";
import WebH from "../../picture/WebH.png";
import WebHB from "../../picture/WebHB.png";
import AppH from "../../picture/AppH.png";
import AppHB from "../../picture/AppHB.png";
import WeChatH from "../../picture/WeChatH.png";
import WeChatHB from "../../picture/WechatHB.png";
import DataH from "../../picture/DataH.png";
import DataHB from "../../picture/DataHB.png";
import PointLt from "../../picture/PointLt.png";
import PointRt from "../../picture/PointRt.png";
import {preLoadImages} from "../../util/RequestHelper";

class HomeCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homeImages: "",
            loadedNum: 0,
            label: 0,
        }
    }

    imgInfos = [
        {name: "BK", url: BK},
        {name: "UFO", url: UFO},
        {name: "LIGHT", url: LIGHT},
        {name: "ADV", url: ADV},
        {name: "SP", url: SP},
        {name: "SoftH", url: SoftH},
        {name: "SoftHB", url: SoftHB},
        {name: "WebH", url: WebH},
        {name: "WebHB", url: WebHB},
        {name: "AppH", url: AppH},
        {name: "AppHB", url: AppHB},
        {name: "WeChatH", url: WeChatH},
        {name: "WeChatHB", url: WeChatHB},
        {name: "DataH", url: DataH},
        {name: "DataHB", url: DataHB},
        {name: "SLOGAN", url: this.props.slogan},
        {name: "PointLt", url: PointLt},
        {name: "PointRt", url: PointRt},
    ];

    getServiceBanner = (label, itemLabel, imgStyle, itemStyle, levelDir, delay, img, imgB, url, pointer) => {
        return (
            <div className={"service-building-level"}
                 style={{textAlign: levelDir}}>
                <QueueAnim animConfig={{translateY: [0, -50]}}
                           duration={300} delay={delay}>
                    <div key={"banner-soft"} style={itemStyle}
                         onClick={() => {
                             window.location.href = url
                         }}
                         onMouseOver={() => this.setState({label: itemLabel})}
                         onMouseOut={() => this.setState({label: 0})}>
                        <img src={label === itemLabel ? imgB : img} className={"banner-ser-img"}
                             style={imgStyle} alt={""}/>
                    </div>
                </QueueAnim>
                {label === itemLabel ? <img className={"pointer-arrow-dir"} src={pointer} alt={""}
                                            style={levelDir === "right" ? {right: "48%"} : {left: "48%"}}/> : null}
            </div>
        );
    }

    loadServerBanner = (images) => {
        let label = this.state.label;
        let isRight = label % 2 === 0;
        let rightP = images.get("PointRt").src;
        let leftP = images.get("PointLt").src;
        console.log(isRight)
        return (
            <QueueAnim type={"right"} animConfig={{opacity: [1, 0], translateX: [0, 2000]}}
                       duration={4000} delay={300}>
                <div key={"ser-start"} className={"banner-service-glass"}>
                    <div className={"banner-service"}>
                        <img className={"banner-split"} src={images.get("SP").src} alt={""}/>
                        <div style={{height: "16%", width: "100%", background: "transparent"}}/>
                        <div className={"banner-service-table"}>
                            {this.getServiceBanner(label, 1, {paddingRight: "12%"}, {width: "50%"},
                                "right", 2300, images.get("SoftH").src, images.get("SoftHB").src, "/services#cores", leftP)}
                            {this.getServiceBanner(label, 2, {paddingLeft: "12%"}, {width: "50%", float: "right"},
                                "left", 2700, images.get("WebH").src, images.get("WebHB").src, "/services#service-web-info", rightP)}
                            {this.getServiceBanner(label, 3, {paddingRight: "12%"}, {width: "50%"},
                                "right", 3100, images.get("AppH").src, images.get("AppHB").src, "/services#service-app-info", leftP)}
                            {this.getServiceBanner(label, 4, {paddingLeft: "12%"}, {width: "50%", float: "right"},
                                "left", 3500, images.get("WeChatH").src, images.get("WeChatHB").src, "/services#service-wechat-info", rightP)}
                            {this.getServiceBanner(label, 5, {paddingRight: "12%"}, {width: "50%"},
                                "right", 3900, images.get("DataH").src, images.get("DataHB").src, "/services#service-machine-info", leftP)}
                        </div>
                        <div style={{height: "10%", width: "100%", background: "transparent"}}/>
                        <QueueAnim type={"top"} animConfig={{translateY: ["0vh", "-39vh"]}}
                                   duration={3800} delay={2200}>
                            <div key={"start-pointer-move"} style={{width: "100%"}}>
                                {isRight || label === 0 ? <div style={{width: "50%", float: "left"}}>
                                    <img className={"pointer-arrow"} src={leftP} alt={""}
                                         style={{right: "48%"}}/>
                                </div> : null}

                                {!isRight || label === 0 ? <div style={{width: "50%", float: "left"}}>
                                    <img className={"pointer-arrow"} src={rightP} alt={""}
                                         style={{left: "48%"}}/>
                                </div> : null}
                            </div>
                        </QueueAnim>
                    </div>
                </div>
            </QueueAnim>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isMobileL && prevState.loadedNum !== this.state.loadedNum && this.state.loadedNum === 18) {
            this.props.parent.setLoaded(this.state.homeImages.get("SLOGAN").src);
        }
    }

    componentDidMount() {
        if (!this.props.isMobileL) {
            preLoadImages(this.imgInfos, this);
        }
    }

    render() {
        let isMobileL = this.props.isMobileL;
        let images = this.state.homeImages;

        if (!isMobileL && this.props.parent.state.isLoaded) {
            return (
                <div className="carousel-div-home">
                    {this.loadServerBanner(images)}
                    <Carousel className="slider-show" dots={"true"} autoplay={"true"}
                              speed={500}
                              infinite={"true"} slidesToScroll={1} slidesToShow={1}>
                        {/*{this.loadingCarouselList(isMobileL)}*/}
                        <div className={"h-banner-image-level-one"} onClick={() => {
                            window.location.href = "/pro/background"
                        }}>
                            <h1><img className="slider-show-image" src={images.get("BK").src} alt={""}/></h1>
                            <ScrollOverPack playScale={0.1}>

                                <QueueAnim type={"right"} animConfig={{opacity: [1, 0], translateX: [0, 2000]}}
                                           duration={3000} delay={300}>
                                    <img key={"home-banner-anim-item"} className={"banner-image-item"}
                                         src={images.get("UFO").src}
                                         alt={""}/>
                                    <QueueAnim type={"top"} animConfig={{opacity: [1, 0]}}
                                               duration={500} delay={2800}>
                                        <img key={"home-banner-anim-light"} className={"banner-image-light"}
                                             src={images.get("LIGHT").src}
                                             alt={""}/>
                                        <QueueAnim type={"top"} animConfig={{opacity: [1, 0], translateY: [0, -50]}}
                                                   duration={500} delay={3000}>
                                            <img key={"home-banner-anim-adv"} className={"banner-image-adv"}
                                                 src={images.get("ADV").src}
                                                 alt={""}/>
                                        </QueueAnim>
                                    </QueueAnim>
                                </QueueAnim>
                            </ScrollOverPack>
                        </div>
                    </Carousel>
                </div>
            );
        } else {
            return (
                <div className="mobile-home-page-banner">
                    <div className="mobile-home-banner">
                        <div className="mobile-banner-image-content">
                            <img className={"banner-image-adv-mb-title"} src={AdvT}
                                 alt={""}/>
                            <img className={"banner-image-adv-mb-content"} src={AdvC}
                                 alt={""}/>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default HomeCarousel;