import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import './static/page.less'

class About extends React.Component {
    render() {
        let isMobileL = this.props.isMobileL;

        if (!isMobileL) {
            return (
                <div className="About">
                    {/*<div className="one"/>*/}
                    {/*<div className="background-fixed-img">*/}
                    {/*    <div className="background-fixed-one"/>*/}
                    {/*</div>*/}
                    <div className="two">
                        <ScrollOverPack playScale={0.2} id="" className="content-wrapper page">
                            <TweenOne
                                key="image"
                                className="image1 image-wrapper"
                                animation={{x: 0, opacity: 1, ease: 'easeInOutCubic'}}
                            />
                            <QueueAnim
                                key="text"
                                className="text-wrapper"
                                leaveReverse
                            >
                                <h2 key="h2">公司简介</h2>
                                <span className="line" key="text-line"/>
                                <p key="text-p">
                                    <p>
                                        巨罟大数据科技有限公司成立于2021年
                                    </p>
                                    <p>
                                        专注于网站建设，App开发，数据分析，新媒体运营等个性化定制服务
                                    </p>
                                    <p>
                                        打通前后端壁垒，结合数字化服务，提供一站式互联网体系建设
                                    </p>
                                    <p>
                                        集结各领域先驱人才，为客户打造尖刀产品，赢得上甘岭
                                    </p>
                                </p>
                            </QueueAnim>
                        </ScrollOverPack>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mobile-about">
                    <div className="mobile-one"/>
                    <div className="mobile-two">
                        <div className="content-wrapper page">
                            <div key="text" className="text-wrapper">
                                <h2 key="h2">公司简介</h2>
                                <span className="line" key="line"/>
                                <p>
                                    巨罟大数据科技有限公司成立于2021年
                                </p>
                                <p>
                                    专注于网站建设制，App开发，数据分析，新媒体运营等，为客户提供个性化定制开发项目
                                </p>
                                <p>
                                    打通前后端壁垒，结合数字化运营，提供一站式服务
                                </p>
                                <p>
                                    集结各领域先驱人才，为客户打造尖刀产品，赢得上甘岭
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

// About.propTypes = {
//     isMobile: PropTypes.bool,
// };

export default About;