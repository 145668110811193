import React from 'react';

import SoftwareCore from "../../picture/SoftwareCoreTmp.png"
import SoftWareBK from "../../picture/SoftwareCoreBKTmp.png"
import DigCoreBK from "../../picture/DigCoreBKTmp.png"
import DigCore from "../../picture/DigCoreTmp.png"
import DesignCore from "../../picture/DesignCoreTmp.png"
import DesignCoreBK from "../../picture/DesignCoreBKTmp.png"
import WeChartCore from "../../picture/WeChartCoreTmp.png"
import WeChartCoreBK from "../../picture/WeChartCoreBKTmp.png"
import '../cards.css'
import {CSSTransition} from "react-transition-group";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
// TODO ICP
// import LeadLogo from "../../picture/LeadLogo.svg"
// import SoftwareCore from "../../picture/SoftwareCore.jpg"
// import SoftWareBK from "../../picture/SoftwareCoreBK.jpg"
// import DigCoreBK from "../../picture/DigCoreBK.jpg"
// import DigCore from "../../picture/DigCore.jpg"
// import DesignCore from "../../picture/DesignCore.jpg"
// import DesignCoreBK from "../../picture/DesignCoreBK.jpg"
// import WeChartCore from "../../picture/WeChartCore.jpg"
// import WeChartCoreBK from "../../picture/WeChartCoreBK.jpg"
//

import LeadLogo from "../../picture/LeadLogoTmp.svg"

const pictures = [SoftWareBK, SoftwareCore, DigCore, DigCoreBK, DesignCore, DesignCoreBK, WeChartCore, WeChartCoreBK]

class CoreTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {tagLabel: 0}
    }

    MouseEnter(label) {
        setTimeout(() => {
            this.setState({
                tagLabel: label
            })
        }, 100)
    }

    MouseLeave(label) {
        setTimeout(() => {
            this.setState({
                tagLabel: label
            })
        }, 100)
    }

    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }

    componentDidMount() {
        pictures.forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });
    }

    render() {
        let isMobileL = this.props.isMobileL;
        const tagLabel = this.state.tagLabel;

        if (isMobileL) {
            return (
                <div className={"mobile-level-one"}>
                    <div className="mobile-level-title">
                        <img className="mobile-title-logo-core" src={LeadLogo} alt=""/>
                        <div className="mobile-title-content" id={"home-core-title"}>
                            {/*构建智能化一体式服务，塑造企业核心竞争力*/}
                            {/*// toDo ICP*/}
                            点击巨罟信息网站，等您发布信息，查询信息！
                            {/**/}
                        </div>
                    </div>
                    <div className={"mobile-core-layer"}>
                        <div className={"mobile-core"} id={"mobile-software-core"} onClick={() => {
                            window.location.href = "/services#mobile-core-select-tab?curIdx=0"
                        }}/>
                        <div className={"mobile-core"} id={"mobile-dig-core"} onClick={() => {
                            window.location.href = "/services#mobile-core-select-tab?curIdx=1"
                        }}/>
                        <div className={"mobile-core"} id={"mobile-design-core"} onClick={() => {
                            window.location.href = "/services#mobile-core-select-tab?curIdx=2"
                        }}/>
                        <div className={"mobile-core"} id={"mobile-we-chart-core"} onClick={() => {
                            window.location.href = "/services#mobile-core-select-tab?curIdx=3"
                        }}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="level-one">
                    <div className="level-title">
                        <img className="title-logo-core" src={LeadLogo} alt=""/>
                        <div className="title-content" id={"home-core-title"}>
                            {/*构建智能化一体式服务，塑造企业核心竞争力*/}
                            {/*// toDo ICP*/}
                            点击巨罟信息网站，等您发布信息，查询信息！
                            {/**/}
                        </div>
                    </div>


                    <ScrollOverPack playScale={0.05}>
                        <QueueAnim type={"bottom"} duration={1000} delay={50}>
                            <div key={"service-core-anim"} className="level-one-content">
                                <div className="part-one"
                                     onMouseEnter={this.MouseEnter.bind(this, 1)}
                                     onMouseLeave={this.MouseLeave.bind(this, 0)}>
                                    {/*<CSSTransition in={tagLabel === 1}*/}
                                    {/*               classNames="core-bk"*/}
                                    {/*               timeout={4000}*/}
                                    {/*               appear>*/}
                                        <img className={"software-core"}
                                             // src={tagLabel === 1 ? SoftWareBK : SoftwareCore}
                                             src={SoftwareCore}
                                             onClick={() => {
                                                 // window.location.href = "/services#cores"
                                                 // toDo ICP
                                                 window.location.href = "/services"
                                                 //
                                             }}
                                             alt=""/>
                                    {/*</CSSTransition>*/}
                                </div>
                                <div className="part-two">
                                    <div className={"dig-level"}
                                         onMouseEnter={this.MouseEnter.bind(this, 2)}
                                         onMouseLeave={this.MouseLeave.bind(this, 0)}>
                                        {/*<CSSTransition in={tagLabel === 2}*/}
                                        {/*               classNames="core-bk"*/}
                                        {/*               timeout={4000}*/}
                                        {/*               appear>*/}
                                            {/*<div></div>*/}
                                            <img className={"dig-core"}
                                                 // src={tagLabel === 2 ? DigCoreBK : DigCore}
                                                 src={DigCore}
                                                 onClick={() => {
                                                     // window.location.href = "/services#cores"
                                                     // toDo ICP
                                                     window.location.href = "/services"
                                                     //
                                                 }} alt={""}/>
                                        {/*</CSSTransition>*/}
                                    </div>
                                    <div id={"add-service"}>
                                        <div className={"design-level"}>
                                            {/*<CSSTransition in={tagLabel === 3}*/}
                                            {/*               classNames="core-bk"*/}
                                            {/*               timeout={4000}*/}
                                            {/*               appear>*/}
                                                <img className={"design-core"}
                                                     // src={tagLabel === 3 ? DesignCoreBK : DesignCore}
                                                     src={DesignCore}
                                                     onClick={() => {
                                                         // window.location.href = "/services#cores"
                                                         // toDo ICP
                                                         window.location.href = "/services"
                                                         //
                                                     }}
                                                     alt={""}
                                                     onMouseEnter={this.MouseEnter.bind(this, 3)}
                                                     onMouseLeave={this.MouseLeave.bind(this, 0)}/>
                                            {/*</CSSTransition>*/}
                                        </div>
                                        <div className={"we-chart-level"}>
                                            {/*<CSSTransition in={tagLabel === 4}*/}
                                            {/*               classNames="core-bk"*/}
                                            {/*               timeout={4000}*/}
                                            {/*               appear>*/}
                                                <img className={"we-chart-core"}
                                                     // src={tagLabel === 4 ? WeChartCoreBK : WeChartCore}
                                                     src={WeChartCore}
                                                     onClick={() => {
                                                         // window.location.href = "/services#cores"
                                                         // toDo ICP
                                                         window.location.href = "/services"
                                                         //
                                                     }}
                                                     alt={""}
                                                     onMouseEnter={this.MouseEnter.bind(this, 4)}
                                                     onMouseLeave={this.MouseLeave.bind(this, 0)}/>
                                            {/*</CSSTransition>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            );
        }
    }
}

export default CoreTag;